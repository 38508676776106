import type { SmartSortProduct } from '@iheartjane/dm-sdk';
import { useMemo } from 'react';

import { useSearchContext } from '@jane/search/providers';
import type { AlgoliaProduct } from '@jane/search/types';
import { MenuProductGrid } from '@jane/shared-ecomm/components';
import type { ProductCardWithTrackingProps } from '@jane/shared-ecomm/components';
import type { UseMyHighResponse } from '@jane/shared-ecomm/hooks';
import { useMenu } from '@jane/shared-ecomm/providers';
import { scrollToTop } from '@jane/shared-ecomm/util';
import { ZoneStoreMenu } from '@jane/shared/models';
import { Box, Flex, Link, useMobileMediaQuery } from '@jane/shared/reefer';
import type { Breadcrumb, MenuProduct } from '@jane/shared/types';
import { getAppliedWeightFilter, getGridPosition } from '@jane/shared/util';

import { MenuEmptyResults } from '../MenuEmptyResults/MenuEmptyResults';
import { MenuProductCard } from '../MenuProductCards/MenuProductCard/MenuProductCard';

export interface MenuProductsGridProps
  extends Pick<
    UseMyHighResponse<AlgoliaProduct>,
    | 'fetchNextPage'
    | 'hasNextPage'
    | 'indexName'
    | 'isLoading'
    | 'products'
    | 'numHits'
    | 'numColumns'
  > {
  additionalProductFilter?: (
    products: SmartSortProduct<AlgoliaProduct>[]
  ) => SmartSortProduct<AlgoliaProduct>[];
  breadcrumbs?: Breadcrumb[];
  maxWeightOption?: number | null;
  pageTitle: string;
  showOnlyWeights?: ProductCardWithTrackingProps['showOnlyWeights'];
  weightFilter?: (
    weight: string,
    product: MenuProduct | AlgoliaProduct
  ) => boolean;
  withoutDrawerOnAddToCart?: boolean;
}

export const MenuProductsGrid = ({
  additionalProductFilter,
  breadcrumbs,
  fetchNextPage,
  hasNextPage,
  indexName,
  isLoading,
  maxWeightOption,
  numColumns,
  numHits,
  pageTitle,
  products,
  showOnlyWeights,
  weightFilter,
  withoutDrawerOnAddToCart,
}: MenuProductsGridProps) => {
  const isMobile = useMobileMediaQuery({});
  const { searchState } = useSearchContext();
  const { listView } = useMenu();

  const productsToRender = useMemo(() => {
    return additionalProductFilter
      ? additionalProductFilter(products)
      : products;
  }, [products, products.length, additionalProductFilter]);

  return (
    <Box id="results">
      <MenuProductGrid
        listView={listView}
        hasMore={hasNextPage}
        onViewMoreClick={fetchNextPage}
        hideTotal={!!additionalProductFilter}
        isLoading={isLoading}
        isLoadingMore={isLoading}
        totalResults={numHits}
        px={isMobile ? 24 : 64}
      >
        {productsToRender.length > 0 &&
          productsToRender.map((product, index) => {
            const { row, column } = getGridPosition(
              index,
              numColumns ?? undefined
            );

            const productKey = `${product?.isSponsored ? 'inline-ad-' : 'mp-'}${
              product.objectId
            }`;

            return (
              <MenuProductCard
                key={productKey}
                appliedWeightFilter={getAppliedWeightFilter(searchState)}
                algoliaIndexName={indexName}
                breadcrumbs={breadcrumbs}
                columnPosition={column}
                maxWeightOption={maxWeightOption}
                menuRowName={pageTitle}
                product={product}
                productLocation={pageTitle}
                numColumns={numColumns}
                rowPosition={row}
                zone={ZoneStoreMenu}
                showOnlyWeights={showOnlyWeights}
                weightFilter={weightFilter}
                withoutDrawerOnAddToCart={withoutDrawerOnAddToCart}
              />
            );
          })}
      </MenuProductGrid>
      {!isLoading && products.length === 0 ? (
        <MenuEmptyResults />
      ) : (
        <Flex
          flexDirection="column"
          alignItems="center"
          pt={24}
          px={isMobile ? 24 : 48}
        >
          <Link onClick={scrollToTop}>Back to top</Link>
        </Flex>
      )}
    </Box>
  );
};
