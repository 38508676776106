import { useState } from 'react';

import type {
  AlgoliaProduct,
  JaneSearchState,
  SelectFilterGroup,
} from '@jane/search/types';
import { LoadingWrapper } from '@jane/shared/components';
import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import {
  Box,
  Button,
  FiltersIcon,
  useMobileMediaQuery,
} from '@jane/shared/reefer';

import { ActiveFilterProvider } from '../activeFilterProvider';
import { FilterBarGroup } from '../filterBarGroup';
import { FilterModal } from '../filterModal/filterModal';
import { FilterSearch } from '../filterSearch/filterSearch';
import { FilterSort } from '../filterSort/filterSort';
import { ActiveFilters } from './activeFilters';
import {
  StyledFilterWrapper,
  StyledProductFilterBar,
  StyledSortWrapper,
} from './productFilterBar.styles';
import type { ProductFilterBarProps } from './productFilterBar.types';
import { ProductFilterRadius } from './productFilterRadius';
import { ProductFilterView } from './productFilterView';

export const ProductFilterBar = ({
  activeFilters,
  currentSort,
  hideCounts = false,
  isLoading,
  isLoadingFacets,
  filters,
  listView,
  maxRadius,
  modalSearchState,
  onChange,
  onDeselect,
  onQuery,
  onRadius,
  onSort,
  onView,
  radius,
  setModalSearchState,
  sortOptions,
  searchId,
  totalResults,
  variant = 'product',
}: ProductFilterBarProps) => {
  const isMobile = useMobileMediaQuery({});

  const [open, setOpen] = useState(false);
  /* NOTE product search filters do not have the same styling and onView is only passed for store menus */
  const myHighMenu = useFlag(FLAGS.myHighMenu) && Boolean(onView);

  const renderedBarFilters = filters?.map((filterGroup) => {
    return (
      <FilterBarGroup
        key={filterGroup.key}
        filterGroup={filterGroup as SelectFilterGroup}
        hideCounts={hideCounts}
        isLoading={isLoading}
        onChange={onChange}
        onDeselect={onDeselect}
        totalResults={totalResults}
        variant={variant}
      />
    );
  });

  return (
    <ActiveFilterProvider activeFilters={activeFilters}>
      <Box id="results-filter">
        {isLoadingFacets && (
          <LoadingWrapper
            variant="filters"
            isLoading
            pb={8}
            pt={myHighMenu ? 0 : 24}
            pl={myHighMenu ? (isMobile ? 24 : 64) : 24}
            pr={!isMobile ? (myHighMenu ? 64 : 24) : 0}
          />
        )}
        {!isLoadingFacets && (
          <StyledProductFilterBar myHighMenu={myHighMenu}>
            <StyledFilterWrapper>
              <FilterSearch onSearch={onQuery} searchId={searchId} />

              <div>
                <Button.Icon
                  icon={<FiltersIcon />}
                  onClick={() => setOpen(true)}
                  variant="tertiary"
                  ariaLabel={`Open ${variant} filters`}
                  disabled={!filters.length}
                />
              </div>
              {renderedBarFilters}
            </StyledFilterWrapper>

            <StyledSortWrapper>
              <FilterSort
                currentSort={currentSort}
                onSort={onSort}
                sortOptions={sortOptions}
                variant="product"
              />
              {onView && (
                <ProductFilterView listView={listView} onView={onView} />
              )}
              {onRadius && (
                <ProductFilterRadius
                  maxRadius={maxRadius}
                  onRadius={onRadius}
                  radius={radius}
                />
              )}
            </StyledSortWrapper>
          </StyledProductFilterBar>
        )}
        <ActiveFilters
          activeFilters={activeFilters}
          onChange={onChange}
          onDeselect={onDeselect}
          myHighMenu={myHighMenu}
        />
      </Box>
      <FilterModal<JaneSearchState<AlgoliaProduct>>
        filters={filters}
        hideCounts={hideCounts}
        isLoading={isLoading}
        onChange={onChange}
        onDeselect={onDeselect}
        searchState={modalSearchState}
        open={open}
        setOpen={setOpen}
        setSearchState={setModalSearchState}
        totalResults={totalResults}
        variant={variant}
      />
    </ActiveFilterProvider>
  );
};
