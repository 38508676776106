import { useTheme } from '@emotion/react';
import type { Config } from 'final-form';
import { useState } from 'react';
import { Form } from 'react-final-form';

import { OptInDescription } from '@jane/shared/components';
import type { Id } from '@jane/shared/models';
import { useEcommApp } from '@jane/shared/providers';
import type { ReeferTheme } from '@jane/shared/reefer';
import {
  Box,
  CheckboxField,
  Flex,
  Link,
  Typography,
} from '@jane/shared/reefer';

import { TextInputField } from '../../component-library/text-input/text-input';
import { useCustomerSelector } from '../../customer/selectors';
import useDisplayGTITerms from '../../hooks/useDisplayGTITerms';
import { paths } from '../../lib/routes';
import { get } from '../../redux-util/selectors';
import { FinePrint } from '../finePrint';
import type { ShowLoginProps } from '../login/common';
import {
  CustomFieldError,
  PrimaryLoginButton,
  SUBMIT_ERROR_TEXT,
  SubmitErrorMessage,
} from '../login/common';
import { PhoneInputField } from '../phoneInputField';

const GTI_STORE_IDS: Id[] = [
  2, 252, 253, 254, 255, 391, 392, 461, 596, 615, 616, 646, 725, 730, 756, 761,
  771, 773, 790, 834, 836, 874, 886, 887, 888, 1055, 1178, 1180, 1181, 1182,
  1236, 1253, 1263, 1317, 1338, 1340, 1341, 1342, 1343, 1344, 1474, 1475, 1497,
  1501, 1512, 1531, 1544, 1545, 1547, 1548, 1549, 1550, 1551, 1552, 1575, 1589,
  1718, 1812, 1867, 1885, 1961, 2040, 2252, 2265, 2266, 2350, 2351, 2446, 2517,
  2525, 2531, 2607, 2817, 2863, 2901, 3230, 3404, 3405, 3406, 3416, 3417, 3422,
  3466, 3467, 3468, 3469, 3542, 3543, 3579, 3717, 3936, 4018, 4049, 4050, 4052,
  4053, 4061, 4098, 4439, 4440, 4631, 4632, 4633, 4634, 4635, 4636, 4637, 4638,
  4970, 5077, 5102, 5202, 5205, 5233, 5266, 5267, 5268, 5269, 5270, 5383, 5417,
  5418, 5419, 5420, 5421, 5422, 5423, 5424, 5425, 5426, 5427, 5428, 5429, 5476,
  5477, 5478, 5486, 5550, 5624, 5653, 5800, 5907, 5960, 5964, 6011, 6012, 6013,
  6014, 6015, 6056, 6057, 6086, 6115, 6121, 6128,
];

export const isGTIStore = (storeId: Id) => GTI_STORE_IDS.includes(storeId);

export interface RegistrationFormProps {
  embeddedMode?: boolean;
  onSubmit: Config<RegistrationFormValues>['onSubmit'];
  setShowLogin: (arg: boolean) => void;
  theme?: ReeferTheme;
}

export const FinePrintContainer = ({ children }) => (
  <Typography
    as="div"
    color="grays-mid"
    mb={20}
    mt={16}
    textAlign="left"
    variant="mini"
  >
    {children}
  </Typography>
);

export const RegistrationFinePrint = () => {
  const { store } = useCustomerSelector(get('store'));

  const displayGTITerms = useDisplayGTITerms();
  const storeInOhio = store['state'] === 'Ohio';

  const gtiTermsAndConditions = storeInOhio
    ? 'https://oh.risecannabis.com/terms/'
    : 'https://risecannabis.com/terms/';

  const gtiPrivacyPolicy = storeInOhio
    ? 'https://oh.risecannabis.com/privacy-policy/'
    : 'https://risecannabis.com/privacy-policy/';

  const gtiNoticeOfPrivacyPractices = storeInOhio
    ? 'https://oh.risecannabis.com/notice-of-privacy-practices/'
    : 'https://risecannabis.com/notice-of-privacy-practices/';

  return (
    <FinePrintContainer>
      <FinePrint>By continuing, I agree to Jane&apos;s</FinePrint>{' '}
      <Link color="grays-black" href={paths.termsOfUse()}>
        Terms of Service
      </Link>
      <FinePrint>&nbsp;&amp;&nbsp;</FinePrint>
      <Link color="grays-black" href={paths.privacyPolicy()}>
        Privacy Policy
      </Link>
      {displayGTITerms && (
        <>
          <FinePrint>&nbsp;&amp; Green Thumb&apos;s&nbsp;</FinePrint>
          <Link color="grays-black" href={gtiTermsAndConditions}>
            Terms and Conditions
          </Link>
          <FinePrint>,&nbsp;</FinePrint>
          <Link color="grays-black" href={gtiPrivacyPolicy}>
            Privacy Policy
          </Link>
          <FinePrint>&nbsp;&amp;&nbsp;</FinePrint>
          <Link color="grays-black" href={gtiNoticeOfPrivacyPractices}>
            Notice of Privacy Practices
          </Link>
        </>
      )}
      .
    </FinePrintContainer>
  );
};

type Props = {
  onSubmit: Config<RegistrationFormValues>['onSubmit'];
  theme?: ReeferTheme;
} & ShowLoginProps;

interface Errors {
  confirmPassword?: string;
  email?: string;
  nickname?: string;
  password?: string;
  phone?: string;
}

const validate: Config<RegistrationFormValues>['validate'] = (values) => {
  const errors: Errors = {};
  if (!values.email) {
    errors.email = 'Required';
  }
  if (!values.phone) {
    errors.phone = 'Required';
  }
  if (values.password && values.password.length < 10) {
    errors.password = 'Must be at least 10 characters';
  }
  return errors;
};

interface RegistrationFormValues {
  email: string;
  nickname?: string;
  password: string;
  phone: string;
  promotionsOptIn: boolean;
}

const RegistrationForm = ({ onSubmit, setShowLogin }: Props) => {
  const theme = useTheme();
  const loginState = useCustomerSelector(get('login'));
  const { appMode } = useEcommApp();
  const isMarketplace = appMode === 'default';

  // Store initial values in state so if loginState changes during submission
  // error, the form isn't reset and lose displaying server errors.
  const [initialValues] = useState<RegistrationFormValues>({
    nickname: '',
    email: loginState?.user?.email || '',
    phone: loginState?.user?.phone || '',
    password: '',
    promotionsOptIn: isMarketplace ? true : undefined,
  });

  const renderEmailError = () => {
    return (
      <CustomFieldError>
        This email is already taken.{' '}
        <button
          css={{ color: theme.colors.brand.purple.main }}
          onClick={() => setShowLogin && setShowLogin(true)}
        >
          Sign in
        </button>
        ?
      </CustomFieldError>
    );
  };

  return (
    <Form<RegistrationFormValues>
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
      render={({
        handleSubmit,
        submitting,
        hasValidationErrors,
        hasSubmitErrors,
        submitFailed,
      }) => (
        <form onSubmit={handleSubmit}>
          <TextInputField
            name="email"
            type="email"
            label="Email address"
            id="email"
            autoComplete="email"
            renderSubmitError={renderEmailError}
          />
          <PhoneInputField
            name="phone"
            label="Phone number"
            id="phone"
            helperText="Used only for reservation notices"
            autoComplete="tel"
          />
          <TextInputField
            name="password"
            type="password"
            label="Password"
            id="password"
            autoComplete="new-password"
          />
          {isMarketplace && (
            <Flex mb={24} flexDirection="row">
              <CheckboxField
                disableMobileInputStyling
                name="promotionsOptIn"
                label="Promotional emails"
                labelHidden
              />
              <OptInDescription />
            </Flex>
          )}
          <Flex flexDirection="column" mt={32}>
            <PrimaryLoginButton
              onClick={handleSubmit}
              type="submit"
              data-testid="login-submit-btn"
              disabled={submitting}
              label="Sign up"
              loading={submitting}
            />
          </Flex>
          {(hasSubmitErrors || (submitFailed && hasValidationErrors)) && (
            <SubmitErrorMessage>{SUBMIT_ERROR_TEXT}</SubmitErrorMessage>
          )}
        </form>
      )}
    />
  );
};

export const RegistrationFormExtra = ({ setShowLogin }: ShowLoginProps) => (
  <>
    <Box mt={16} my={0} mx="auto">
      <Link
        color="grays-black"
        onClick={() => setShowLogin && setShowLogin(true)}
      >
        Already registered? Sign In.
      </Link>
    </Box>
    <RegistrationFinePrint />
  </>
);

export default RegistrationForm;
