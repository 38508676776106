import type { NavigateFunction } from 'react-router-dom';
import type { Reducer } from 'redux';

import { removeJwt } from '@jane/shared/auth';
import type { DeepReadonly } from '@jane/shared/models';

import type { CustomerThunkAction } from '../../customer/redux';
import { isEmbeddedModeSelector } from '../../customer/selectors';
import { paths } from '../../lib/routes';
import {
  createSimpleAction,
  createStandardAction,
} from '../../redux-util/redux-util';
import { closeModal, openModal } from './application';
import type { CommonActions } from './types';

export const HANDLE_INVALID_LOGIN_TOKEN = 'customer/handle-invalid-login-token';
export const handleInvalidLoginToken =
  (redirectToLogin = false, navigate?: NavigateFunction): CustomerThunkAction =>
  (dispatch, getState) => {
    const isEmbedded = isEmbeddedModeSelector(getState());
    const showLoginModal = !isEmbedded && !redirectToLogin;
    removeJwt();
    if (showLoginModal) {
      dispatch(closeModal());
    }
    dispatch({ type: HANDLE_INVALID_LOGIN_TOKEN });

    if (showLoginModal) {
      dispatch(openModal({ name: 'login' }));
    } else if (isEmbedded) {
      navigate && navigate(paths.embeddedLogin());
    }
  };

export const RESET_LOGIN = 'customer/reset-login';
export const resetLogin = createSimpleAction(RESET_LOGIN);

export const SET_LOGIN_VALUE = 'customer/set-login-value';
export const setLoginValue = createStandardAction(SET_LOGIN_VALUE)<{
  name: string;
  value: string;
}>();

export type LoginActions =
  | ReturnType<typeof setLoginValue>
  | ReturnType<typeof resetLogin>
  | { type: typeof HANDLE_INVALID_LOGIN_TOKEN };

export type LoginState = DeepReadonly<{
  user: {
    email: string;
    password: string;
    phone?: string;
  };
}>;

const getInitialState = (): LoginState => ({
  user: {
    email: '',
    password: '',
    phone: '',
  },
});

export const loginReducer: Reducer<LoginState, CommonActions> = (
  state = getInitialState(),
  action
) => {
  switch (action.type) {
    case SET_LOGIN_VALUE: {
      const { name, value } = action.payload;
      return { user: { ...state.user, [name]: value } };
    }
    case RESET_LOGIN:
      return getInitialState();
  }

  return state;
};
