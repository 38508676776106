import { useLocation } from 'react-router-dom';

import { useJaneUser, useUserBrandDiscounts } from '@jane/shared/data-access';
import { useShouldShowGold } from '@jane/shared/hooks';
import type { Store } from '@jane/shared/models';
import type { PaddingProperties } from '@jane/shared/reefer';
import {
  Box,
  Flex,
  Link,
  Typography,
  useMobileMediaQuery,
} from '@jane/shared/reefer';
import type { EcommPaths } from '@jane/shared/types';

import { BankLinkingModalWithPrompt } from '../bankLinkingModalWithPrompt/bankLinkingModalWithPrompt';
import { HeaderSignInPrompt } from '../headerSignInPrompt/headerSignInPrompt';
import { PageLayout } from '../pageLayout/pageLayout';
import { BagIcon } from './bagIcon';
import { UserIcon } from './userIcon';

interface MenuHeaderEmbedProps extends PaddingProperties {
  disableAuthFeatures: boolean;
  inProgressReservations?: number;
  onCartClick: () => void;
  paths: EcommPaths;
  productsInCart?: number;
  store: Store;
}

//TODO (Zack): Subdomain header? PoweredByJane?

export const MenuHeaderEmbed = ({
  disableAuthFeatures,
  inProgressReservations = 0,
  onCartClick,
  paths,
  productsInCart = 0,
  store,
  ...paddingProps
}: MenuHeaderEmbedProps) => {
  const showJaneGold = useShouldShowGold();
  const isMobile = useMobileMediaQuery({});

  const { pathname } = useLocation();
  const { data: janeUser, isFetched } = useJaneUser();
  const janeUserId = janeUser?.user?.id;

  const { data: brandDiscounts } = useUserBrandDiscounts({
    enabled: showJaneGold && !!janeUserId,
    janeUserId: janeUserId,
    status: 'awaiting_registration',
  });

  const hideHeader =
    pathname.includes('/checkout') || pathname.includes('/guest_checkout');

  if (hideHeader) return null;

  return (
    <Box background="grays-ultralight">
      <PageLayout px={isMobile ? 24 : 64} overflowX="visible">
        <Flex
          alignItems="center"
          justifyContent="space-between"
          {...paddingProps}
        >
          <Link variant="minimal" to={paths.menu()}>
            <Typography variant="body-bold" color="primary">
              {store.name}
            </Typography>
          </Link>

          <Flex>
            {!janeUserId && isFetched && !disableAuthFeatures && (
              <Box m={12}>
                <HeaderSignInPrompt />
              </Box>
            )}

            {!!janeUserId && !disableAuthFeatures && (
              <Link
                to={paths.profile()}
                variant="minimal"
                ariaLabel="Go to profile page"
              >
                <UserIcon
                  pendingCashBack={brandDiscounts?.total || 0}
                  inProgressReservations={inProgressReservations}
                />
              </Link>
            )}

            <Link
              {...(onCartClick && { onClick: onCartClick })}
              variant="minimal"
              ariaLabel="Open the cart"
            >
              <BagIcon productCount={productsInCart} />
            </Link>
          </Flex>
        </Flex>
      </PageLayout>
      <BankLinkingModalWithPrompt
        customerId={janeUserId || null}
        store={store as Store}
      />
    </Box>
  );
};
