import { useState } from 'react';

import { useCheckout } from '@jane/shared-ecomm/providers';
import { EventNames, track } from '@jane/shared-ecomm/tracking';
import { useMfa } from '@jane/shared/providers';
import { AeropayLogoWhite } from '@jane/shared/reefer';
import { trackError } from '@jane/shared/util';

import { SharedButton } from '../../CheckoutButton/SharedButton';
import { CheckoutErrorModal } from '../../CheckoutErrorModal/CheckoutErrorModal';
import { useHasNewPhone } from '../../util/hooks/useHasNewPhone';
import { useCheckoutPayments } from '../CheckoutPaymentsProvider';
import { loadAeropayWidget } from './loadAeropayWidget';

export const AeropayButton = () => {
  const [hasError, setHasError] = useState(false);
  const { hasNewPhone, newPhone } = useHasNewPhone();
  const { launch, requiresMfa } = useMfa();
  const { updatePrepayments } = useCheckoutPayments();

  const {
    cartLineItems,
    customer: { authenticated },
    store: { aeropay_integration },
  } = useCheckout();

  const handleSuccess = (id: string) => {
    updatePrepayments({ aeropayPreauthorizationId: id });
  };

  const handleLoadWidget = () => {
    try {
      track({
        event: EventNames.ClickedProceedWithAeropay,
      });
      loadAeropayWidget({
        aeropayIntegration: aeropay_integration,
        cartTotal: Number(cartLineItems.total),
        onSuccess: handleSuccess,
      });
    } catch (e) {
      setHasError(true);
      trackError(new Error('Unable to launch Aeropay widget.'), { error: e });
    }
  };

  const handleClick = () => {
    const shouldMfa = requiresMfa() || hasNewPhone;

    if (authenticated && shouldMfa) {
      launch({ onSuccess: handleLoadWidget, newPhone });
    } else {
      handleLoadWidget();
    }
  };

  const buttonLabel =
    aeropay_integration?.custom_payment_method_label || 'Pay now with';

  return (
    <>
      <SharedButton
        label={buttonLabel}
        onClick={handleClick}
        icon={
          aeropay_integration?.custom_payment_method_label
            ? undefined
            : () => <AeropayLogoWhite height={20} />
        }
      />

      <CheckoutErrorModal
        handleClose={() => setHasError(false)}
        open={hasError}
      />
    </>
  );
};
