import { useSmartMagicRow } from '@jane/dm/internal';
import { mapAppModes } from '@jane/search/util';
import { MenuProductCarousel } from '@jane/shared-ecomm/components';
import { useMenu } from '@jane/shared-ecomm/providers';
import { CardCarousel } from '@jane/shared/components';
import { config } from '@jane/shared/config';
import { ZoneMagicRow } from '@jane/shared/models';

import { MenuProductCard } from '../../MenuComponents/MenuProductCards/MenuProductCard/MenuProductCard';
import type { MenuCarouselProps } from './MenuBuyAgainCarousel';

export const MenuMagicRowCarousel = ({
  placementIndex,
  mode = 'ideal-width',
}: MenuCarouselProps) => {
  const {
    appInfo: { appMode, janeDeviceId },
    listView,
    paths,
    store,
  } = useMenu();

  const { isLoading, instance } = useSmartMagicRow({
    appMode: mapAppModes(appMode),
    jdid: janeDeviceId,
    searchAttributes: ['*'],
    storeId: Number(store.id),
  });

  const title = instance?.title ?? 'For you';

  if (!isLoading && instance?.products.length === 0) return null;

  const nextBreadcrumbs = [
    {
      title: 'Featured',
      url: paths.menuCategory('featured'),
    },
  ];

  return (
    <MenuProductCarousel
      isLoading={isLoading}
      mode={mode}
      listView={listView}
      name={title}
      variant="magic-row"
    >
      {instance?.products.map((product, index) => (
        <CardCarousel.Card
          key={`magic-row-${product.objectId}`}
          width="100%"
          widthMobile="100%"
        >
          <MenuProductCard
            algoliaIndexName={`menu-products-${config.algoliaEnv}`}
            appliedWeightFilter=""
            breadcrumbs={nextBreadcrumbs}
            carouselView={true}
            columnPosition={index}
            menuRowName={title}
            product={product}
            productLocation={`featured/${title}`}
            rowPosition={placementIndex}
            zone={ZoneMagicRow}
          />
        </CardCarousel.Card>
      ))}
    </MenuProductCarousel>
  );
};
