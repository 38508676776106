import styled from '@emotion/styled';
import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useSmartCartTopperRow } from '@jane/dm/internal';
import { FilterSortId } from '@jane/search/types';
import { mapAppModes } from '@jane/search/util';
import { ErrorBoundary } from '@jane/shared-ecomm/components';
import { useGetStore } from '@jane/shared/data-access';
import type { StoreSpecial } from '@jane/shared/models';
import { ZoneCart } from '@jane/shared/models';
import { useEcommApp } from '@jane/shared/providers';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Button,
  Flex,
  Typography,
  useDesktopMediaQuery,
} from '@jane/shared/reefer';
import { spacing } from '@jane/shared/reefer-emotion';

import { useCustomerSelector } from '../../../customer/selectors';
import { MENU_PRODUCTS_BY_PRICE_ASC } from '../../../lib/algoliaIndices';
import { get } from '../../../redux-util/selectors';
import ItemsCarousel from '../../itemsCarousel';
import { ITEM_MARGIN_AMOUNT } from '../../itemsCarousel/carouselHelper';
import HitProductCard from './hitProductCard';

const Container = styled(Flex)(({ theme }) => ({
  ...spacing({ mt: 16, px: 24 }),
  backgroundColor: theme.colors.grays.white,
}));

interface Props {
  specials?: StoreSpecial[];
}
export const CartToppers = ({ specials }: Props) => {
  const { appMode } = useEcommApp();
  const { janeDeviceId } = useCustomerSelector(get('application'));
  const { cart } = useCustomerSelector(get('cart'));
  const storeId = cart?.store.id;
  const { data: store } = useGetStore(storeId);
  const isDesktop = useDesktopMediaQuery({});
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [cartProductIds, setCartProductIds] = useState<number[]>([]);

  const showArrows = isDesktop || ['bloom', 'headless'].includes(appMode);
  const [rightArrowDisabled, setRightArrowDisabled] = useState(true);
  const onShowRightArrow = useCallback(
    (showRightArrow: boolean) => setRightArrowDisabled(!showRightArrow),
    []
  );
  /**
   * Javascript does shallow comparisons in these `useMemo` or `useCallback` functions
   * so in order to avoid unnecessary re-renders and re-fetches we need to compare the raw product ids
   * with a stateful variable to ensure that refetching is not done when the cart products
   * are the same
   *
   * Unfortunately, that means that `rawProductIds` will be re-calculated frequently
   */
  const rawProductIds = useMemo(
    () => cart.products.map((product) => product.id),
    [cart.products]
  );

  /**
   * In order to continue to use the `cartProductIds` as a dependency in the `useSmartCartTopperRow`
   * we need to ensure that it doesn't change too frequently and cause unnecessary re-fetches of the cart toppers
   *
   * What we are doing here is comparing the `cartProductIds` with the `rawProductIds` and only updating state if they are different
   * The outcome of this is that cart toppers will only be re-fetched if the cart products have changed
   */
  useEffect(() => {
    if (!isEqual(cartProductIds, rawProductIds)) {
      setCartProductIds(rawProductIds);
    }
  }, [cartProductIds, rawProductIds]);

  const { instance, isLoading } = useSmartCartTopperRow({
    appMode: mapAppModes(appMode),
    cartProductIds,
    jdid: janeDeviceId,
    searchAttributes: ['*'],
    storeId: Number(storeId),
  });

  if (!store || (!isLoading && !!instance && !instance?.products.length))
    return null;

  return (
    <ErrorBoundary withFallbackRender={false}>
      <Container flexDirection="column" pb={20}>
        <Flex
          alignItems="center"
          flexDirection="row"
          justifyContent="space-between"
          mt={40}
          mb={24}
        >
          <Typography branded variant="header-bold">
            Add to your order
          </Typography>
          {showArrows && (
            <Flex gap={16}>
              <Button.Icon
                variant="tertiary"
                icon={<ArrowLeftIcon />}
                disabled={carouselIndex === 0}
                onClick={() => setCarouselIndex(carouselIndex - 1)}
              />
              <Button.Icon
                variant="tertiary"
                icon={<ArrowRightIcon />}
                disabled={rightArrowDisabled}
                onClick={() => setCarouselIndex(carouselIndex + 1)}
              />
            </Flex>
          )}
        </Flex>
        <ItemsCarousel
          controlledIndex={{
            currentIndex: showArrows ? carouselIndex : null,
            onShowRightArrow,
          }}
          items={instance?.products ?? []}
          itemMargin={ITEM_MARGIN_AMOUNT}
          itemRenderer={({ item: cartTopper, index }) => (
            <HitProductCard
              algoliaIndexName={MENU_PRODUCTS_BY_PRICE_ASC}
              appliedWeightFilter={''}
              bucketName="cart-toppers"
              carouselView={true}
              columnPosition={index}
              productInstance={cartTopper}
              hit={cartTopper.attributes}
              key={`cart-topper-${cartTopper.objectId}`}
              listView={false}
              productLocation="Cart Toppers"
              searchState={{
                currentSort: {
                  id: FilterSortId.PriceAsc,
                  suffix: 'by-price-asc',
                },
              }}
              specials={specials}
              store={store}
              zone={ZoneCart}
            />
          )}
        />
      </Container>
    </ErrorBoundary>
  );
};
