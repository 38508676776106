import { useEffect } from 'react';

import { useCheckout } from '@jane/shared-ecomm/providers';
import { Box } from '@jane/shared/reefer';
import { Form, useFormContext } from '@jane/shared/reefer-hook-form';

import { PaymentMethods } from '../../util/hooks/usePaymentOptions';
import { useCheckoutPayments } from '../CheckoutPaymentsProvider';

// This form is rendered if CanPay remote pay is disabled.
// If remote pay is enabled, then a button that launches a widget is rendered instead.
export const CanPayPayment = () => {
  const {
    store: { canpay_v2remotepay_enabled },
  } = useCheckout();
  const { selected } = useCheckoutPayments();
  const { trigger } = useFormContext();

  const shouldValidate =
    selected === PaymentMethods.canpay && !canpay_v2remotepay_enabled;

  useEffect(() => {
    trigger('paymentOptions.canPayToken');
  }, [shouldValidate]);

  return (
    <Box>
      <Form.TextField
        name="paymentOptions.canPayToken"
        label="Enter your CanPay Payment PIN"
        errorOnBlur
        disableMobileInputStyling
        validate={(value: string) =>
          !shouldValidate ||
          !!value.match(/^[0-9A-Z]{7,9}$/) ||
          'Please enter a valid CanPay PIN'
        }
      />
    </Box>
  );
};
