import { useEffect } from 'react';

import { useCheckout } from '@jane/shared-ecomm/providers';
import { OptInDescription } from '@jane/shared/components';
import {
  Box,
  Button,
  Flex,
  Grid,
  Link,
  Typography,
  useDesktopMediaQuery,
} from '@jane/shared/reefer';
import { Form, useFormContext } from '@jane/shared/reefer-hook-form';

import { CheckoutAccordionItem } from '../CheckoutAccordion/CheckoutAccordionItem';
import { StyledGridContainer } from '../checkout.styles';
import { useFulfillmentSchedule } from '../util/hooks/useFulfillmentSchedule';
import { AgreementLabel } from './AgreementLabel';
import { DeliverySummary } from './DeliverySummary';
import FormattedAddress from './FormattedAddress';
import { useCheckoutOptionsForm } from './useCheckoutOptionsForm';

export const CheckoutDeliveryOptions = () => {
  const isDesktop = useDesktopMediaQuery({ size: 'sm', width: 'min' });

  const { consentItems, consentsValid, handleContinue, isValid } =
    useCheckoutOptionsForm();

  const { trigger } = useFormContext();

  const {
    appInfo: { appMode, bloomUserExperience },
    cart: { reservation_mode },
    deliveryAddress,
    deliveryValidation: { data: deliveryValidationData },
    dispatches: { openCart },
  } = useCheckout();

  const shouldValidate = reservation_mode === 'delivery';

  const { deliverySlots, isLoadingDelivery } = useFulfillmentSchedule();

  const slotOptions =
    deliverySlots?.map((slot) => ({
      label: slot.display_name,
      value: `${slot.from}>${slot.to}`,
    })) || [];

  useEffect(() => {
    // When switching to delivery, we re-validate pickup fields
    // as they are no longer required
    trigger('pickupOptions');
  }, []);

  return (
    <CheckoutAccordionItem
      id="options"
      header="Confirm your reservation details"
      summary={<DeliverySummary />}
      mt={bloomUserExperience && !isDesktop ? 16 : 0}
    >
      <Box>
        {/* Set to height: 'auto' */}
        <StyledGridContainer spacing={24}>
          {deliveryAddress?.street ? (
            <Grid.Item xs={12}>
              <Typography mb={12} variant="body-bold">
                Address
              </Typography>
              <Flex justifyContent="space-between" alignItems="center">
                <Box>
                  <FormattedAddress address={deliveryAddress} />
                </Box>
                <Link onClick={() => openCart()}>Change</Link>
              </Flex>

              {deliveryValidationData.can_deliver === false && (
                <Typography mt={12} color="error">
                  This store is unable to delivery to this address.
                </Typography>
              )}
            </Grid.Item>
          ) : (
            <Grid.Item xs={12}>
              <Link onClick={() => openCart()}>Add delivery address</Link>
            </Grid.Item>
          )}

          <Grid.Item xs={12} lg={6}>
            <Form.SelectField
              borderRadius="xs"
              disableMobileInputStyling
              label="Delivery time"
              name="deliveryOptions.fulfillmentTime"
              loading={isLoadingDelivery}
              options={slotOptions}
              placeholder={
                !deliveryAddress?.street
                  ? 'Add delivery address'
                  : slotOptions.length
                  ? 'Select a reservation slot'
                  : 'No reservation times available'
              }
              validate={(value) =>
                !shouldValidate || !!value || 'Please select a delivery time'
              }
              shouldUnregister={false}
            />
          </Grid.Item>
        </StyledGridContainer>

        <Form.TextField
          mt={24}
          disableMobileInputStyling
          borderRadius="xs"
          label="Delivery instructions"
          name="deliveryOptions.message"
          placeholder="e.g. Gate code"
          maxLength={300}
          shouldUnregister={false}
        />

        {!!consentItems.length && (
          <Box mt={24}>
            {consentItems.map((item) => (
              <Box key={item.id}>
                <Form.CheckboxField
                  mt={12}
                  label={
                    <AgreementLabel
                      required={item.required}
                      label={item.label}
                    />
                  }
                  name={`deliveryOptions.${item.name}`}
                  validate={(value) =>
                    !shouldValidate ||
                    !!value ||
                    !item.required ||
                    'This is a required agreement.'
                  }
                  shouldUnregister={false}
                />
                {item.external_link && (
                  <Link mt={24} href={item.external_link}>
                    View Agreement
                  </Link>
                )}
              </Box>
            ))}

            {!consentsValid && (
              <Typography color="error" mt={12}>
                Please agree to the store's required terms.
              </Typography>
            )}

            {appMode === 'default' && (
              <Flex mt={12} flexDirection="row">
                <Form.CheckboxField
                  disableMobileInputStyling
                  name="deliveryOptions.promotionsOptIn"
                  label="Promotional emails"
                  labelHidden
                  defaultChecked
                />
                <OptInDescription />
              </Flex>
            )}
          </Box>
        )}

        <Button
          mt={24}
          full
          label="Continue"
          onClick={handleContinue}
          disabled={!deliveryValidationData.can_deliver || !isValid}
        />
      </Box>
    </CheckoutAccordionItem>
  );
};
