import { useEffect, useState } from 'react';

import { useAeropayUser } from '@jane/shared-ecomm/data-access';
import { PROMPTS, usePrompt } from '@jane/shared-ecomm/hooks';
import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import { useShouldShowGold } from '@jane/shared/hooks';
import type { Id, Store } from '@jane/shared/models';

import { BankLinkingModal } from '../bankLinkingModal/bankLinkingModal';

// 14 days
const DISMISS_MILLISECONDS = 24 * 14 * 60 * 60 * 1000;

export const BankLinkingModalWithPrompt = ({
  customerId,
  store,
}: {
  customerId: Id | null;
  store: Store | null;
}) => {
  const [showLinkBankModal, setShowLinkBankModal] = useState(false);

  const { data: aeropayUser, isLoading: isLoadingAeropayUser = true } =
    useAeropayUser({
      janeUserId: customerId,
    });
  const isJaneGoldMember =
    !!aeropayUser && aeropayUser.bank_accounts.length > 0;

  const myHigh = useFlag(FLAGS.myHighMenu);
  const janeGold = useShouldShowGold({ store: store as Store });

  const [showPrompt, dismissPrompt] = usePrompt({
    prompt: PROMPTS.linkBank,
    dismissible: true,
    dismissTime: DISMISS_MILLISECONDS,
  });

  const hideJanePayBranding = store
    ? Boolean(store?.jane_pay_integration?.white_label) ||
      Boolean(!store?.jane_pay_integration?.enabled)
    : false;

  const isNotJaneGoldMember =
    !isJaneGoldMember && janeGold && myHigh && showPrompt;

  // Note: the JaneGoldPrompt should only be shown to users immediately after they have signed in (and if they meet the criteria)
  useEffect(() => {
    if (!!customerId && !isLoadingAeropayUser && isNotJaneGoldMember) {
      setShowLinkBankModal(true);
    }
  }, [customerId, isLoadingAeropayUser]);

  if (isLoadingAeropayUser) {
    return <div data-testid="loading" />;
  }

  if (!showLinkBankModal) {
    return null;
  }

  return (
    <BankLinkingModal
      hideJanePayBranding={hideJanePayBranding}
      showPrompt
      open={showLinkBankModal}
      onRequestClose={() => {
        dismissPrompt();
        setShowLinkBankModal(false);
      }}
      location="header"
      storePhoto={store?.photo || undefined}
    />
  );
};
