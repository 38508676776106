import { Box, Flex, PartnerLogo, Typography } from '@jane/shared/reefer';
import { Form } from '@jane/shared/reefer-hook-form';
import type { ReservationReview } from '@jane/shared/types';

import { ReviewForm } from './reviewForm';

export type FormValues = Partial<ReservationReview>;

export interface StoreReviewFormProps {
  /* Short description displayed under the store address. For reviewing an order, this would be the fulfillment method and date **/
  description?: string;

  /* The star rating input will render with this rating pre-selected (or none) **/
  initialRating?: number;

  onCancel: () => void;

  onSubmit: (values: FormValues) => void;

  storeAddress: string;

  storeName: string;

  storePhoto: string | null;
}

export const StoreReviewForm = ({
  description,
  initialRating,
  onCancel,
  onSubmit,
  storeAddress,
  storeName,
  storePhoto,
}: StoreReviewFormProps) => {
  return (
    <Box width="100%">
      <Typography variant="header-bold" branded textAlign="center" mb={24}>
        Review store
      </Typography>

      <Flex alignItems="center">
        <PartnerLogo
          image={storePhoto}
          variant="store"
          name={`${storeName} logo`}
        />

        <Box ml={16}>
          <Typography variant="body-bold">{storeName}</Typography>
          <Typography>{storeAddress}</Typography>
          {description && (
            <Typography color="grays-mid">{description}</Typography>
          )}
        </Box>
      </Flex>

      <Form
        name="store-review-form"
        onSubmit={(data: FormValues) => onSubmit(data)}
      >
        <ReviewForm onCancel={onCancel} initialRating={initialRating} />
      </Form>
    </Box>
  );
};
