import type { CustomMenuLabels, FilterSelectItem } from '@jane/search/types';

import type { FilterIcons, IconColors } from './constants';
import { PRODUCT_ROOT_TYPES } from './constants';
import { formatFilterLabel } from './formatFilterLabel';
import { getWeightLabel } from './getWeightLabel';

type FilterGroupLabel =
  | 'Activities'
  | 'Brands'
  | 'Categories'
  | 'Feelings'
  | 'Lineage'
  | 'Price'
  | 'Rating'
  | 'Specials'
  | 'Subcategories'
  | 'Weights'
  | string;
interface FacetToFilterOptions {
  branded?: boolean;
  customLabels?:
    | CustomMenuLabels['category']
    | CustomMenuLabels['root_types']
    | { [label: string]: string };
  filterGroup?: FilterGroupLabel;
  iconColor?: IconColors;
  icons?: FilterIcons;
  storeId?: string | number;
}

export const facetToFilter = (
  facets: { [label: string]: number },
  options?: FacetToFilterOptions,
  state?: string | null
): FilterSelectItem[] => {
  const {
    branded = true,
    customLabels,
    filterGroup,
    iconColor,
    icons,
    storeId,
  } = options || {};

  const itemLabelToRender = (labelValue: string) => {
    if (filterGroup === 'Brand') {
      return labelValue;
    }

    if (filterGroup === 'Weight') {
      return getWeightLabel(labelValue);
    }

    return formatFilterLabel(labelValue, customLabels, storeId);
  };

  const allFacets = Object.entries(facets);
  const topLevelFacets =
    filterGroup === 'Brand'
      ? allFacets
      : allFacets.filter(([key]) => !key.includes(':'));
  const subFacets = allFacets.filter(([key]) => key.includes(':'));

  const determineFacets = () => {
    let facets = topLevelFacets;

    // if no store, we limit categories to PRODUCT_ROOT_TYPES
    if (!storeId && filterGroup === 'Category') {
      facets = topLevelFacets.filter(([key]) =>
        PRODUCT_ROOT_TYPES.includes(key)
      );
    }

    if (filterGroup === 'Subcategory') {
      facets = subFacets;
    }

    return facets;
  };

  const facetsToRender = determineFacets();

  const subItems = (topLevelFacet: string) =>
    subFacets
      .filter(([key]) => key.startsWith(`${topLevelFacet}:`))
      .map(([value, count]) => ({
        value,
        label: itemLabelToRender(value),
        count: count,
      }));

  return facetsToRender.map(([value, count]) => {
    const Icon =
      icons &&
      (state === 'Ohio' && value === 'flower'
        ? icons['default']
        : icons[value]);

    const color = iconColor && iconColor[value];

    return {
      count,
      icon: Icon ? (
        <Icon
          color={color ? color : branded ? undefined : 'primary'}
          size={filterGroup === 'Jane Gold' ? 'sm' : undefined}
        />
      ) : undefined,
      label: itemLabelToRender(value),
      value,
      subItems: subItems(value),
    };
  });
};
