import { memo, useEffect } from 'react';

import { Box, Typography, useDesktopMediaQuery } from '@jane/shared/reefer';
import { Storage } from '@jane/shared/util';

import CannabisBrochure from './CA_Brochure_BW.jpg';

export const CA_BROCHURE_KEY = 'californiaBrochureSeen';

interface Props {
  cartUuid: string;
  state: string | null;
}

export const CaliforniaBrochure = memo(({ cartUuid, state }: Props) => {
  const isDesktop = useDesktopMediaQuery({ size: 'sm', width: 'min' });
  const sawBrochureCartUuid = Storage.get(CA_BROCHURE_KEY);

  useEffect(() => {
    if (!sawBrochureCartUuid && state === 'California') {
      Storage.set(CA_BROCHURE_KEY, cartUuid);
    }
  }, [sawBrochureCartUuid, cartUuid]);

  // If the user is not in california, or has seen the brochure when
  // checking out previously (i.e. with a different cart UUID), don't show
  if (
    state !== 'California' ||
    (!!sawBrochureCartUuid && sawBrochureCartUuid !== cartUuid)
  )
    return null;

  return (
    <Box my={16} mx={isDesktop ? 'auto' : 16} overflow="scroll">
      <Typography variant="header-bold" ml={12} my={16} width="100%">
        CA Cannabis Use Warning:
      </Typography>
      <img
        src={CannabisBrochure}
        alt="Effects of cannabis use"
        height="800px"
        width="auto"
      />
    </Box>
  );
});
