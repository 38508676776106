import pluralise from 'pluralise';
import { useState } from 'react';

import type {
  CrmDetails,
  CrmIntegration,
  CrmReward,
} from '@jane/shared/models';
import {
  Banner,
  Box,
  Button,
  Flex,
  InfoIcon,
  Typography,
} from '@jane/shared/reefer';

import { initiateCrmAuth } from '../../../../../../lib/sources/crmIntegration';
import { ApplyableDiscount } from './applyableDiscount';
import { StoreRedeemableListItem } from './customerLoyaltyPoints.styles';
import { UnlockWalletForm } from './unlockWalletForm/unlockWalletForm';

export const CustomerLoyaltyPointsContent = ({
  cartUuid,
  crmIntegration: { signed_up, wallet_state, automatic_redemption },
  error,
  handleApplyDiscount,
  headless,
  loyaltyPointsStacking = true,
  maxRedemptionCountExceeded,
  noRewards,
  onUnlockWalletSubmit,
  crmDetails,
  redeemableInStore,
  redeemableOnline,
  reservationMode,
  storeLoyaltyPointsLabel,
  storeName,
  unlockLoading,
}: {
  cartUuid: string;
  crmDetails: CrmDetails;
  crmIntegration: CrmIntegration;
  error?: string;
  handleApplyDiscount: (reward: CrmReward) => void;
  headless?: boolean;
  loyaltyPointsStacking: boolean;
  maxRedemptionCountExceeded: boolean;
  noRewards: boolean;
  onUnlockWalletSubmit: (code: string) => void;
  redeemableInStore: CrmReward[];
  redeemableOnline: CrmReward[];
  reservationMode: string;
  storeLoyaltyPointsLabel?: string | null;
  storeName: string;
  unlockLoading: boolean;
}) => {
  const [acknowledgedStacking, setAcknowledgedStacking] = useState(false);

  if (error) {
    return (
      <Typography pl={40}>Unable to get your store reward points</Typography>
    );
  }

  if (!signed_up) {
    return (
      <Typography pl={40}>{`Ask about becoming a ${
        storeLoyaltyPointsLabel ? storeLoyaltyPointsLabel : 'loyalty points'
      } member at ${storeName.trim()}!`}</Typography>
    );
  }

  if (signed_up && wallet_state === 'locked') {
    return (
      <UnlockWalletForm
        onSubmit={onUnlockWalletSubmit}
        crmDetails={crmDetails}
        onSendCode={() => initiateCrmAuth(cartUuid)}
        unlockLoading={unlockLoading}
      />
    );
  }

  if (signed_up && noRewards) {
    return (
      <Typography pl={40}>
        You do not qualify for{' '}
        {maxRedemptionCountExceeded ? 'additional' : 'any'} rewards at{' '}
        {storeName}.
      </Typography>
    );
  }

  if (signed_up && !loyaltyPointsStacking && !acknowledgedStacking) {
    return (
      <Box pl={40}>
        <Typography pb={24}>
          This store doesn’t allow loyalty points to be redeemed when there are
          existing discounts applied to your bag.
        </Typography>
        <Button
          full
          onClick={() => setAcknowledgedStacking(true)}
          variant="tertiary"
          label="Use rewards instead"
        />
      </Box>
    );
  }

  return (
    <Box pl={40}>
      {!automatic_redemption && (
        <Banner
          icon={<InfoIcon />}
          label={`Please present your reward account at the time of ${reservationMode}`}
          full
        />
      )}
      {redeemableOnline.length > 0 && (
        <div>
          <Typography mt={16} variant="body-bold">
            Redeemable online
          </Typography>
          <Typography mt={8}>
            <ul>
              {redeemableOnline.map((reward: CrmReward) => {
                return (
                  <ApplyableDiscount
                    key={reward.crm_reward_id}
                    applied={false}
                    enabled
                    onClick={() => handleApplyDiscount(reward)}
                    text={`${reward.reward_name} `}
                  >
                    <Typography>
                      {`Use ${reward.point_value}`}{' '}
                      {pluralise(reward.point_value, 'pt', 'pts')}
                    </Typography>
                  </ApplyableDiscount>
                );
              })}
            </ul>
          </Typography>
        </div>
      )}

      {redeemableInStore.length > 0 && (
        <Box mt={16}>
          <Typography variant="body-bold">Redeemable in-store</Typography>
          <Flex mt={8}>
            <ul>
              {redeemableInStore.map((reward: CrmReward) => (
                <StoreRedeemableListItem key={reward.crm_reward_id}>
                  <Typography variant="body">
                    {reward.reward_name} ({reward.point_value}{' '}
                    {pluralise(reward.point_value, 'pt', 'pts')})
                  </Typography>
                </StoreRedeemableListItem>
              ))}
            </ul>
          </Flex>
        </Box>
      )}
    </Box>
  );
};
