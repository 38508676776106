import type { Reducer } from 'redux';

import type { DeepReadonly } from '@jane/shared/models';

import { SortType } from '../../lib/storeSort';
import { createStandardAction } from '../../redux-util/redux-util';
import type { CustomerAction } from './types';

export const SELECT_SORT = 'selected-store-sort/select-sort';
export const selectSort = createStandardAction(SELECT_SORT)<SortType>();

export type SelectedStoreSortActions = ReturnType<typeof selectSort>;

export type SelectedStoreSortState = DeepReadonly<{
  selectedSort: SortType;
}>;

const getInitialState = (): SelectedStoreSortState => ({
  selectedSort: SortType.proximity,
});

export const selectedStoreSortReducer: Reducer<
  SelectedStoreSortState,
  CustomerAction
> = (state = getInitialState(), action) => {
  switch (action.type) {
    case SELECT_SORT:
      return { selectedSort: action.payload };
  }

  return state;
};
