import type { SmartSort, SmartSortProduct } from '@iheartjane/dm-sdk';

import {
  useIsRecommendedSortEnabled,
  useRecommendedSort,
  useSmartMenuRow,
} from '@jane/dm/internal';
import { useSearchContext } from '@jane/search/providers';
import type { AlgoliaProduct } from '@jane/search/types';
import {
  buildIndex,
  buildOptionalFilters,
  buildSearchFilter,
  getShouldShowRecommendedSort,
  mapAppModes,
  mapCurrentSortToSearchSort,
  maybeGetDefaultStoreMenuSort,
} from '@jane/search/util';
import { useMenu } from '@jane/shared-ecomm/providers';
import {
  getMenuSearchEventDetails,
  trackUserSessionSearchedMenuProducts,
  useTrackSearch,
} from '@jane/shared-ecomm/tracking';
import { calculateGridColumns } from '@jane/shared-ecomm/util';
import { config } from '@jane/shared/config';

interface UseMyHighProps {
  disableAds?: boolean;
  enabled?: boolean;
  isCarousel?: boolean;
  maxProducts?: number;
  preventTracking?: boolean;
  productsPerPage: number;
  staticFilters?: string;
}

export interface UseMyHighResponse<
  ProductShape extends object = AlgoliaProduct
> {
  fetchNextPage: () => void;
  hasNextPage: boolean;
  indexName: string;
  isLoading: boolean;
  numColumns: number | null;
  numHits: number;
  products: SmartSortProduct<ProductShape>[];
  searchResultFacets: SmartSort<ProductShape>['searchFacets'];
}

export const useMyHigh = ({
  enabled = true,
  disableAds = false,
  isCarousel = false,
  maxProducts,
  preventTracking = false,
  productsPerPage,
  staticFilters,
}: UseMyHighProps): UseMyHighResponse => {
  const { searchState } = useSearchContext();

  const {
    appInfo: { janeDeviceId: initJdid, appMode, brandPartner },
    listView,
    store,
  } = useMenu();

  const janeDeviceId = initJdid ?? '';

  const searchFilter = buildSearchFilter({
    searchState,
    staticFilters,
  });

  const searchOptionalFilters = buildOptionalFilters({ brandPartner, store });

  const isRecommendedSortEnabled = useIsRecommendedSortEnabled(store, appMode);

  const productGrid = (
    document.getElementById('shadow-host')?.shadowRoot || document
  ).getElementById('empty-menu-product-grid');

  const numColumns = calculateGridColumns(
    productGrid?.getBoundingClientRect().width
  );

  const columnsForQuery = listView ? 1 : numColumns;

  const shouldShowRecommendedSort = getShouldShowRecommendedSort({
    isRecommendedSortEnabled,
    isTable: true,
  });

  const defaultSort = maybeGetDefaultStoreMenuSort({
    currentSort: searchState.currentSort,
    shouldShowRecommendedSort,
  });

  const searchSort = mapCurrentSortToSearchSort(
    defaultSort ?? searchState.currentSort
  );

  const recommendedSortResponse = useRecommendedSort({
    appMode: mapAppModes(appMode),
    disableAds,
    enabled: enabled && !isCarousel,
    jdid: janeDeviceId,
    maxProducts,
    numColumns: columnsForQuery ?? undefined,
    pageSize: productsPerPage,
    searchAttributes: ['*'],
    searchFilter,
    searchOptionalFilters,
    searchQuery: searchState?.searchText ?? undefined,
    searchSort,
    storeId: Number(store.id),
  });

  const menuRowResponse = useSmartMenuRow({
    appMode: mapAppModes(appMode),
    disableAds,
    enabled: enabled && isCarousel,
    jdid: janeDeviceId,
    numColumns: columnsForQuery ?? undefined,
    searchAttributes: ['*'],
    searchFilter,
    searchOptionalFilters,
    searchQuery: searchState?.searchText ?? undefined,
    searchSort,
    storeId: Number(store.id),
  });

  const searchResponse = isCarousel ? menuRowResponse : recommendedSortResponse;

  const {
    products,
    numHits,
    hasNextPage,
    isLoading,
    fetchNextPage,
    searchResultFacets,
  } = searchResponse;

  const searchIndex = buildIndex(
    config.algoliaEnv,
    defaultSort ?? searchState.currentSort
  );

  useTrackSearch({
    event: getMenuSearchEventDetails({
      store,
      view: listView ? 'list' : 'grid',
    }),
    preventTracking,
    indexName: searchIndex,
    numHits,
    searchState,
    searching: isLoading,
  });

  trackUserSessionSearchedMenuProducts({
    searchState,
  });

  return {
    fetchNextPage,
    hasNextPage,
    indexName: searchIndex,
    isLoading,
    numColumns,
    numHits,
    products,
    searchResultFacets,
  };
};
