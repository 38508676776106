import {
  Box,
  Button,
  Flex,
  Skeleton,
  Typography,
  useMobileMediaQuery,
} from '@jane/shared/reefer';
import { pluralize } from '@jane/shared/util';

import { StyledGrid } from './menuProductGrid.styles';
import type { MenuProductGridProps } from './menuProductGrid.types';

export const MenuProductGrid = ({
  children,
  hasMore,
  hideTotal = false,
  isLoading,
  isLoadingMore,
  listView = false,
  onViewMoreClick,
  totalResults,
  ...props
}: MenuProductGridProps) => {
  const isMobile = useMobileMediaQuery({});
  const resultsCount = totalResults || 0;
  const resultsLabel = pluralize({
    kilo: true,
    noun: 'product',
    number: resultsCount,
  });

  return (
    <Flex flexDirection="column" width="100%" alignItems="center" {...props}>
      {!hideTotal && isLoading && (
        <Box mr="auto">
          <Skeleton.Bone mb={24} width={150} />
        </Box>
      )}
      {!hideTotal && !isLoading && (
        <Typography color="text-light" textAlign="left" width="100%" mb={24}>
          {resultsLabel}
        </Typography>
      )}
      <StyledGrid
        listView={listView}
        minHeight={!isLoading && resultsCount === 0 ? 0 : '375px'}
      >
        {isLoading
          ? Array.from({ length: listView ? 8 : 20 }, (_, i) => (
              <Skeleton.Bone
                height={!listView ? (isMobile ? 425 : 475) : 145}
                width="100%"
                key={i}
              />
            ))
          : children}
      </StyledGrid>
      {!isLoading && hasMore && (
        <Button
          label="View more"
          onClick={onViewMoreClick}
          full={isMobile}
          mt={isMobile ? 24 : 48}
          loading={isLoadingMore}
        />
      )}
    </Flex>
  );
};
