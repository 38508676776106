import { useEffect, useState } from 'react';

import { BankLinkingModal } from '@jane/shared-ecomm/components';
import { useAeropayUser } from '@jane/shared-ecomm/data-access';
import { useCheckout } from '@jane/shared-ecomm/providers';
import { EventNames, linkBankAccountEvent } from '@jane/shared-ecomm/tracking';
import { useJaneUser } from '@jane/shared/data-access';
import { Button } from '@jane/shared/reefer';
import { postMessageToIframeParent } from '@jane/shared/util';

import { PaymentMethods } from '../../util/hooks/usePaymentOptions';
import { useCheckoutPayments } from '../CheckoutPaymentsProvider';

export const JanePayButton = () => {
  const {
    appInfo: { externalAuth, bloomUserExperience },
    customer: { authenticated, email, nickname },
    guestCheckout: { preAuthSelection, setShowLoginModal, setPreAuthSelection },
    store: { jane_pay_integration, photo },
  } = useCheckout();

  const [modalOpen, setModalOpen] = useState(
    authenticated && preAuthSelection === PaymentMethods.janePay
  );

  const { data: janeUserData } = useJaneUser();
  const { data: aeropayUserData, isFetched } = useAeropayUser({
    janeUserId: janeUserData?.user?.id || null,
  });

  const { updatePrepayments } = useCheckoutPayments();

  useEffect(() => {
    if (aeropayUserData?.bank_accounts.length) {
      const defaultBank =
        aeropayUserData.bank_accounts.find((bank) => bank.is_selected) ||
        aeropayUserData.bank_accounts[0];
      updatePrepayments({ aeropayBankId: defaultBank.bank_account_id });
    }
  }, [aeropayUserData]);

  const handleClick = () => {
    linkBankAccountEvent({
      email: email || '',
      event: EventNames.LinkBankAccountTap,
      isJanePay: true,
      source: 'checkout',
      username: nickname || '',
    });

    if (authenticated) {
      setModalOpen(true);
      return;
    }

    setPreAuthSelection(PaymentMethods.janePay);
    if (externalAuth && bloomUserExperience) {
      postMessageToIframeParent({
        messageType: 'authEvent',
        payload: {
          name: 'initiateExternalAuth',
          source: 'checkout',
        },
      });
    } else {
      setShowLoginModal(true);
    }
  };

  const showButton = !aeropayUserData?.bank_accounts.length || !authenticated;

  const hideBranding = Boolean(jane_pay_integration?.white_label);

  return (
    <>
      {showButton && (
        <Button
          mt={12}
          mb={24}
          full
          label={hideBranding ? 'Link your bank account' : 'Set up Jane Pay'}
          onClick={handleClick}
          loading={(modalOpen || !isFetched) && authenticated}
        />
      )}

      {modalOpen && (
        <BankLinkingModal
          open={modalOpen}
          onRequestClose={() => setModalOpen(false)}
          isJanePay
          hideJanePayBranding={hideBranding}
          location="checkout"
          storePhoto={photo || undefined}
        />
      )}
    </>
  );
};
