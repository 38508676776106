import { ClassNames } from '@emotion/react';
import VerificationInput from 'react-verification-input';

import { config } from '@jane/shared/config';
import { useJaneUser } from '@jane/shared/data-access';
import { useMfa } from '@jane/shared/providers';
import { Button, Flex, Link, Typography } from '@jane/shared/reefer';
import { Form, FormFieldWrapper } from '@jane/shared/reefer-hook-form';

interface MultifactorAuthenticationFormProps {
  codeResent: boolean;
  errorVerifyingCode: string | null;
  newPhone?: string;
  onCancel?: () => void;
  onSubmit: (values: { code: string }) => void;
  resendCode: () => void;
}

export const MultifactorAuthenticationForm = ({
  codeResent,
  errorVerifyingCode,
  newPhone,
  onCancel,
  onSubmit,
  resendCode,
}: MultifactorAuthenticationFormProps) => {
  const { canResendCode, mfaCancel } = useMfa();
  const { data: userData } = useJaneUser();
  const phone = newPhone || userData?.user?.phone;

  const phoneLastFour = phone?.slice(-4);
  const usesEmail =
    config.useEmailOverSMS === '1' && config.mfaForceSMS !== '1';

  const showResendButton = canResendCode ? canResendCode() : !codeResent;

  const handleCancel = () => {
    onCancel && onCancel();
    mfaCancel && mfaCancel();
  };

  return (
    <Form onSubmit={onSubmit} name="multifactor-authentication">
      <Flex
        width="100%"
        height="100%"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Typography textAlign="center">
          Enter the code sent to {usesEmail ? 'your email' : 'phone number'}
        </Typography>
        <Typography textAlign="center" mb={24}>
          {usesEmail
            ? `${userData?.user?.email}`
            : `( ••• ) ••• - ${phoneLastFour}.`}
        </Typography>
        <Flex mb={24} width="100%" justifyContent="center">
          <FormFieldWrapper
            defaultValue=""
            name="code"
            required
            minLength={6}
            render={({ field }) => (
              <ClassNames>
                {({ css, theme }) => (
                  <VerificationInput
                    autoFocus
                    value={field.value}
                    onChange={(value: string) => field.onChange(value)}
                    placeholder=""
                    validChars="0-9"
                    inputProps={{ inputMode: 'numeric' }}
                    classNames={{
                      character: css({
                        fontSize: '24px',
                        fontFamily:
                          theme.components.Typography.fonts.branded.fontFamily,
                        color: theme.colors.grays.black,
                        backgroundColor: theme.colors.grays.white,
                        borderRadius: theme.borderRadius.sm,
                        border: '0 none',
                        outline: errorVerifyingCode
                          ? `1px solid ${theme.colors.system.negative.main}`
                          : `1px solid ${theme.colors.grays.light}`,
                      }),
                      characterSelected: css({
                        outline: errorVerifyingCode
                          ? `2px solid ${theme.colors.system.negative.main}`
                          : `2px solid ${theme.colors.primary.main}`,
                      }),
                    }}
                  />
                )}
              </ClassNames>
            )}
          />
        </Flex>

        {errorVerifyingCode && (
          <Typography color="error" textAlign="center" mb={16}>
            {errorVerifyingCode}
          </Typography>
        )}

        <Flex flexDirection="column" width="fit-content" mx="auto">
          <Flex>
            <Button
              mx={8}
              label="Cancel"
              onClick={handleCancel}
              variant="secondary"
            />
            <Form.SubmitButton label="Verify" mx={8} />
          </Flex>

          {showResendButton && (
            <Link mt={16} onClick={resendCode} mx="auto">
              Resend Code
            </Link>
          )}

          {!showResendButton && (
            <Flex alignItems="center" flexDirection="column" mt={16}>
              <Typography textAlign="center">Didn't receive a code?</Typography>
              <Link href="https://customer-support.iheartjane.com/s/contactsupport">
                Contact support
              </Link>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Form>
  );
};
