import styled from '@emotion/styled';
import isEmpty from 'lodash/isEmpty';
import pluralise from 'pluralise';

import type { Cart, Store } from '@jane/shared/models';
import { Box, Button, Flex, Typography } from '@jane/shared/reefer';

import type { NoStore } from '../../customer/redux/store';
import { PRICE_ID_TO_GRAMS } from '../../lib/cartLimitPolicy';
import { sentenceify } from '../../lib/words';
import { useCartAlertBrandSpecialChanges } from './cartAlertBrandSpecialChangesProvider';

const RemovedRewardListItem = styled(Box)({
  listStyleType: 'none',
  textAlign: 'left',
  '&::before': {
    content: '"•"',
    position: 'absolute',
    left: -12,
  },
});

const unavailableProductsText = (cart: Cart, store: Store | NoStore) => {
  const { unavailable_products } = cart;
  const { name } = store as Store;
  const storeName = name ? ` at ${name}` : '';

  const withoutDuplicates = unavailable_products.reduce(
    (prev, curr) =>
      prev.some(
        (product) =>
          product.product_id === curr.product_id &&
          product.price_id === curr.price_id
      )
        ? prev
        : [...prev, curr],
    []
  );

  if (!cart.products.length) {
    return `All of your selected products have sold out or are no longer available${storeName}.
        Please start a new bag.`;
  } else {
    const productNames = sentenceify(
      withoutDuplicates.map((p) =>
        p.price_id === 'each'
          ? `${p.name}`
          : `${PRICE_ID_TO_GRAMS[p.price_id]}g ${p.name}`
      )
    );
    const verb = withoutDuplicates.length === 1 ? 'is' : 'are';
    return (
      <span>
        <em>{productNames}</em> {verb} no longer available{storeName}.
      </span>
    );
  }
};

interface Props {
  cart: Cart;
  isFixingUnavailableCheckout?: boolean;
  noButton?: boolean;
  noTitle?: boolean;
  onContinueCheckout: () => void;
  store: Store | NoStore;
}

export default function CartAlertsForm({
  cart,
  isFixingUnavailableCheckout,
  noButton,
  noTitle,
  store,
  onContinueCheckout,
}: Props) {
  const { addedBrandSpecialIds, removedBrandSpecialIds } =
    useCartAlertBrandSpecialChanges();

  const {
    brand_discounts_total,
    brand_special_has_changed,
    products,
    special_has_changed,
    removed_crm_redemptions,
    unavailable_products,
  } = cart;

  const getTitle = () => {
    switch (true) {
      case noTitle:
        return '';
      case brand_special_has_changed:
        return 'Bag updated';
      case isFixingUnavailableCheckout:
        return 'Your bag has changed';
      default:
        return 'Bag may have changed';
    }
  };

  const janeGoldAlert = (() => {
    if (!brand_special_has_changed || removedBrandSpecialIds === undefined) {
      return null;
    }

    if (brand_discounts_total !== 0 && removedBrandSpecialIds.length > 0) {
      return 'You are no longer eligible for some of your Jane Gold cash back rewards.';
    }

    if (brand_discounts_total !== 0 && addedBrandSpecialIds.length === 1) {
      return 'You are now eligible for a new Jane Gold cash back reward.';
    }

    if (brand_discounts_total !== 0 && addedBrandSpecialIds.length > 1) {
      return 'You are now eligible for new Jane Gold rewards.';
    }

    if (removedBrandSpecialIds.length > 0) {
      return 'You are no longer eligible for your Jane Gold cash back rewards.';
    }

    return 'Your Jane Gold cash back rewards have been updated.';
  })();

  return (
    <div data-testid="cart-alert-form">
      <Flex mb={24} flexDirection="column">
        {!noTitle && (
          <Typography branded variant="header" mt={8}>
            {getTitle()}
          </Typography>
        )}
        <div className="alerts-modal__alerts">
          {(!products.length || !!unavailable_products.length) && (
            <Box mt={24}>
              <Typography>{unavailableProductsText(cart, store)}</Typography>
            </Box>
          )}

          {janeGoldAlert && (
            <Typography textAlign="center">
              {janeGoldAlert} View your bag for more information.
            </Typography>
          )}

          {special_has_changed && (
            <Box mt={24}>
              <Typography>
                Specials applied to one or more of your selected products have
                changed.
              </Typography>
            </Box>
          )}

          {removed_crm_redemptions && removed_crm_redemptions.length > 0 && (
            <Box mt={24} mb={24}>
              <Typography>
                The following rewards have been removed from your bag:
              </Typography>
              <ul>
                {removed_crm_redemptions.map((redemption) => (
                  <RemovedRewardListItem
                    as="li"
                    key={redemption.id}
                    mb={8}
                    position="relative"
                    left={12}
                  >{`${redemption.reward_name} (${
                    redemption.point_value
                  } ${pluralise(
                    redemption.point_value,
                    'pt',
                    'pts'
                  )})`}</RemovedRewardListItem>
                ))}
              </ul>
            </Box>
          )}

          {isEmpty(products) && !isEmpty(unavailable_products) && (
            <Box mt={24} mb={24}>
              <Typography>{`Please review your ${
                isFixingUnavailableCheckout ? 'order' : 'bag'
              } for more details.`}</Typography>
            </Box>
          )}
        </div>
      </Flex>

      {!noButton && (
        <div className="modal-buttons modal-buttons--small alerts-modal__buttons">
          <Button
            data-testid="dismiss-cart-alert-button"
            onClick={onContinueCheckout}
            label="Got it!"
          />
        </div>
      )}
    </div>
  );
}
