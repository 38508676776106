import styled from '@emotion/styled';

import { ImagePreview } from '@jane/shared/util';

import { commonConnect } from '../../common/redux';
import Modal from '../modal';
import { PinchView } from './react-pinch-zoom-pan';

interface Props {
  modalOptions: {
    image: string;
    orientation?: number;
  };
}

const StyledModal = styled(Modal)(() => ({
  width: '580px',
}));

const ZoomInModal = ({ modalOptions }: Props) => {
  const { image, orientation } = modalOptions;
  const rotation = ImagePreview.rotationForOrientation(orientation);

  const dummyImage = new Image();
  dummyImage.src = image;

  return (
    <StyledModal contentLabel="Zoom in">
      {() => (
        <div className="modal-image">
          <PinchView
            backgroundColor="#444"
            maxScale={3}
            containerRatio={(dummyImage.height / dummyImage.width) * 100}
          >
            <div
              className="uploader-preview__image"
              style={{
                backgroundImage: `url(${image})`,
                transform: `rotate(${rotation}deg)`,
              }}
            />
          </PinchView>
        </div>
      )}
    </StyledModal>
  );
};

export default commonConnect((state) => ({
  modalOptions: state.application.modalOptions,
}))(ZoomInModal);
