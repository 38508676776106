import { useState } from 'react';

import { useHandleIframeModal } from '@jane/shared-ecomm/hooks';
import { useMenu, useSpecials } from '@jane/shared-ecomm/providers';
import { trackOpenedAccordionItem } from '@jane/shared-ecomm/tracking';
import {
  Box,
  Button,
  Flex,
  Modal,
  TagFilledIcon,
  Typography,
  useMobileMediaQuery,
} from '@jane/shared/reefer';
import { formatCurrency, titleCase } from '@jane/shared/util';

import { MenuBanner } from '../MenuBanner';
import { Specials } from './Specials';
import type { SpecialsProps } from './Specials';
import {
  StyledLink,
  StyledModalContent,
  TruncatedTypography,
} from './menuSpecialsBanner.styles';

const insertIf = (condition: boolean, value: SpecialsProps) =>
  condition ? [value] : [];

export const MenuSpecialsBanner = () => {
  const isMobile = useMobileMediaQuery({});
  const [showModal, setShowModal] = useState(false);
  useHandleIframeModal(showModal);

  const {
    appInfo: { appMode },
    store,
  } = useMenu();
  const { specials = [], loadingSpecials } = useSpecials();

  const {
    free_delivery_configs = [],
    reservation_mode_labels,
    store_compliance_language_settings: compliance,
  } = store;

  const handleShowModal = () => {
    trackOpenedAccordionItem('specialsBanner');
    setShowModal(true);
  };

  const customLabel = compliance?.['specials'];

  const userGroupSpecials = specials.filter(
    (special) => special.special_type === 'qualified_group'
  );

  const freeDelivery = free_delivery_configs.find(
    (config) => config?.cart_minimum === 0
  );

  const deliverySpecials = free_delivery_configs.map(
    ({ id, cart_minimum }) => ({
      id,
      description: `Spend ${formatCurrency(cart_minimum)}`,
      title: '',
    })
  );

  const deliveryLabel = reservation_mode_labels?.['delivery'] || 'delivery';

  const allSpecials = [
    ...insertIf(!!userGroupSpecials.length, {
      specials: userGroupSpecials,
      title: `Group ${customLabel || 'discounts'}`,
    }),
    ...insertIf(!!deliverySpecials.length && appMode !== 'kiosk', {
      specials: freeDelivery
        ? [
            {
              id: freeDelivery.id,
              description: `Free ${deliveryLabel}`,
              title: '',
            },
          ]
        : deliverySpecials,
      title: deliveryLabel,
    }),
  ];

  if (!loadingSpecials && !allSpecials.length) return null;

  return (
    <MenuBanner isLoading={Boolean(loadingSpecials)}>
      <Flex
        {...(isMobile && {
          ariaLabel: 'view-more',
          onClick: handleShowModal,
        })}
        alignItems="center"
        justifyContent="space-between"
      >
        <TruncatedTypography as="div">
          {allSpecials.map(({ specials, title }, i) => (
            <Specials key={title + i} specials={specials} title={title} />
          ))}
        </TruncatedTypography>

        {!isMobile && (
          <StyledLink
            ariaLabel="view-more"
            branded
            onClick={handleShowModal}
            ml={16}
          >
            View more
          </StyledLink>
        )}
      </Flex>

      <Modal
        onRequestClose={() => setShowModal(false)}
        open={showModal}
        variant="dialogue"
        overlayClose
      >
        <StyledModalContent>
          <Typography textAlign="center" variant="header-bold">
            {customLabel || 'Discounts'}
          </Typography>

          {allSpecials.map(({ specials, title }, i) => (
            <Box key={title + i} py={8}>
              <Typography variant="body-bold">{titleCase(title)}</Typography>
              {specials.map(({ description, id, title }, i) => (
                <Flex key={id + i} alignItems="center">
                  <TagFilledIcon color="info" size="sm" />
                  <Typography color="info" ml={4}>
                    {description?.trim() ? description : title}
                  </Typography>
                </Flex>
              ))}
            </Box>
          ))}

          <Button
            full
            mt={16}
            onClick={() => setShowModal(false)}
            label="Close"
          />
        </StyledModalContent>
      </Modal>
    </MenuBanner>
  );
};
