import capitalize from 'lodash/capitalize';
import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';

import { LogInModal } from '@jane/shared-ecomm/components';
import type { ReservationMode } from '@jane/shared/models';
import { Box, Link } from '@jane/shared/reefer';
import { postMessageToIframeParent } from '@jane/shared/util';

import { useCustomerDispatch } from '../../../../customer/dispatch';
import { getIdentification } from '../../../../customer/redux/identification';
import { useCustomerSelector } from '../../../../customer/selectors';
import { useAuth } from '../../../../hooks/useAuth/useAuth';
import { get } from '../../../../redux-util/selectors';

interface Props {
  children?: ReactElement;
  isRewards?: boolean;
  reservationMode: ReservationMode;
  rewardsLabel?: string | null;
  specialLabel?: string | null;
}

export const LoginProtected = ({
  children,
  isRewards = false,
  reservationMode,
  rewardsLabel,
  specialLabel,
}: Props): ReactElement => {
  const { options } = useCustomerSelector(get('headlessApp'));
  const [openModal, setOpenModal] = useState(false);
  const { authenticated } = useCustomerSelector(get('customer'));
  const dispatch = useCustomerDispatch();

  const {
    defaultLogin,
    errors,
    onAppleClick,
    onGoogleClick,
    register,
    resetError,
    resetPassword,
    showAppleLogin,
    showGoogleLogin,
  } = useAuth();

  const renderedSpecialLabel = `Sign in to see ${capitalize(
    specialLabel || 'discounts'
  )}`;

  const renderedRewardsLabel = `Sign in to see ${rewardsLabel || 'rewards'}`;

  // load/unload the user's document:
  useEffect(() => {
    if (authenticated) {
      dispatch(getIdentification());
    }
  }, [authenticated]);

  const handleClick = () => {
    if (options.externalAuth && options.bloomUserExperience) {
      postMessageToIframeParent({
        messageType: 'authEvent',
        payload: {
          name: 'initiateExternalAuth',
          source: 'bag',
        },
      });
    } else {
      setOpenModal(true);
    }
  };

  if (authenticated && children) return children;

  return (
    <Box pt={8} pb={24} pl={2}>
      <Link onClick={() => handleClick()}>
        {isRewards ? renderedRewardsLabel : renderedSpecialLabel}
      </Link>
      {openModal && (
        <LogInModal
          errors={errors}
          location="cart"
          open={true}
          onAppleClick={onAppleClick}
          onGoogleClick={onGoogleClick}
          onRequestClose={() => {
            resetError();
            setOpenModal(false);
          }}
          onSignInSubmit={defaultLogin}
          onCreateAccountSubmit={register}
          onForgotPasswordSubmit={resetPassword}
          showAppleLogin={showAppleLogin}
          showGoogleLogin={showGoogleLogin}
        />
      )}
    </Box>
  );
};
