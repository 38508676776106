import intersection from 'lodash/intersection';

import type { Coordinates, GeocodingResult } from '@jane/shared/models';

import { getGoogleMaps } from './googleMaps';

// These strings represent address component types returned in a
// Google geocoding response.
// https://developers.google.com/maps/documentation/geocoding/intro#Types
const LOCALITY = 'locality';

const SUBLOCALITY = 'sublocality';
const ADMIN_1 = 'administrative_area_level_1';
const ADMIN_3 = 'administrative_area_level_3';
const NEIGHBORHOOD = 'neighborhood';
const POSTAL_CODE = 'postal_code';
const STREET_NUMBER = 'street_number';
const ROUTE = 'route';
const COUNTRY = 'country';

type GeocoderAddressComponent = google.maps.GeocoderAddressComponent;

type GeocoderRequest = google.maps.GeocoderRequest;
type GeocoderResult = google.maps.GeocoderResult;
type GeocoderStatus = google.maps.GeocoderStatus;

export const LocationDetectorError = {
  ZeroResults: 'ZERO_RESULTS',
};

export type Geocoder = {
  geocode: (
    request: GeocoderRequest,
    callback: (results: GeocoderResult[], status: GeocoderStatus) => void
  ) => void;
};

let instance: LocationDetector;

export class LocationDetector {
  geolocator: Geolocation;
  getGeocoder: Promise<Geocoder>;

  static getInstance() {
    if (instance) {
      return instance;
    }

    const getGeocoder = getGoogleMaps
      .load()
      .then(({ maps }) => new maps.Geocoder());
    const geolocator = window.navigator.geolocation;

    instance = new LocationDetector(
      getGeocoder as Promise<Geocoder>,
      geolocator
    );
    return instance;
  }

  constructor(getGeocoder: Promise<Geocoder>, geolocator: Geolocation) {
    this.getGeocoder = getGeocoder;
    this.geolocator = geolocator;
  }

  geocode(address: string | undefined): Promise<GeocodingResult> {
    return new Promise((resolve, reject) => {
      this.getGeocoder
        .then((geocoder: Geocoder) =>
          geocoder.geocode({ address }, (results, status) => {
            if (status !== 'OK') {
              return reject(new Error(status));
            }

            const result = results[0];
            resolve(generateResponse(result));
          })
        )
        .catch((status) => {
          return reject(new Error(status));
        });
    });
  }

  reverseGeocode(lat: number, lng: number): Promise<GeocodingResult> {
    return new Promise((resolve, reject) => {
      const latLong = { lat, lng };

      this.getGeocoder.then((geocoder) =>
        geocoder.geocode({ location: latLong }, (results, status) => {
          if (status !== 'OK') {
            return reject();
          }

          const result = results[0];
          resolve(generateResponse(result));
        })
      );
    });
  }

  currentLocation(): Promise<GeocodingResult> {
    return new Promise((resolve, reject) => {
      if (!this.geolocator) {
        return reject();
      }

      this.geolocator.getCurrentPosition((position: GeolocationPosition) => {
        const { coords } = position;
        this.reverseGeocode(coords.latitude, coords.longitude).then(
          resolve,
          reject
        );
      }, reject);
    });
  }
}

function generateResponse(result: GeocoderResult): GeocodingResult {
  const {
    geometry: { location },
    address_components,
    place_id: google_place_id,
  } = result;

  const countryCode = countryOf(address_components);
  const city = cityOf(address_components);
  const state = stateOf(address_components);
  const cityState = city && state ? `${city}, ${state}` : 'Unknown';

  const zipcode = zipcodeOf(address_components);
  const street = streetOf(address_components);
  const coordinates: Coordinates = {
    lat: location.lat(),
    long: location.lng(),
  };
  const hasResetLocation = false;
  const street2 = undefined;
  return {
    countryCode,
    coordinates,
    cityState,
    street,
    street2,
    city,
    state,
    zipcode,
    google_place_id,
    hasResetLocation,
  };
}

type PlaceTypeKeys =
  | typeof LOCALITY
  | typeof SUBLOCALITY
  | typeof ADMIN_1
  | typeof ADMIN_3
  | typeof NEIGHBORHOOD
  | typeof POSTAL_CODE
  | typeof STREET_NUMBER
  | typeof ROUTE;

type PlaceTypes = {
  [key in PlaceTypeKeys]?: GeocoderAddressComponent;
};

type GenericPlaceType = {
  [key: string]: GeocoderAddressComponent;
};

type CityPlaces = Pick<
  PlaceTypes,
  typeof LOCALITY | typeof SUBLOCALITY | typeof ADMIN_3 | typeof NEIGHBORHOOD
>;

export function getPreferredCityComponent(cityContenders: CityPlaces) {
  const locality = cityContenders[LOCALITY];
  const sublocality = cityContenders[SUBLOCALITY];
  const admin3 = cityContenders[ADMIN_3];
  const neighborhood = cityContenders[NEIGHBORHOOD];

  // Prefer placeTypes in this order: locality, sublocality, administrative_area_level_3, neighborhood
  return locality || sublocality || admin3 || neighborhood;
}

function countryOf(addressComponents: GeocoderAddressComponent[]) {
  const countryComponent = addressComponents.find((component) =>
    component.types.includes(COUNTRY)
  );

  return countryComponent && countryComponent['short_name'];
}

function cityOf(addressComponents: GeocoderAddressComponent[]) {
  const acceptedCityTypes = [LOCALITY, SUBLOCALITY, ADMIN_3, NEIGHBORHOOD];

  const cityContenders = addressComponents.reduce(
    (acc: GenericPlaceType, addressComponent) => {
      const { types } = addressComponent;
      const cityTypes = intersection(acceptedCityTypes, types);
      if (!cityTypes.length) return acc;
      cityTypes.forEach((cityType) => {
        acc[cityType] = addressComponent;
      });

      return acc;
    },
    {}
  );
  const cityComponent = getPreferredCityComponent(cityContenders);
  return cityComponent?.long_name;
}

function stateOf(addressComponents: GeocoderAddressComponent[]) {
  const stateComponent = addressComponents.find((component) =>
    component.types.includes(ADMIN_1)
  );

  return stateComponent && stateComponent['short_name'];
}

function zipcodeOf(addressComponents: GeocoderAddressComponent[]) {
  const zipcodeComponent = addressComponents.find((component) =>
    component.types.includes(POSTAL_CODE)
  );
  return zipcodeComponent && zipcodeComponent.short_name;
}

function streetOf(addressComponents: GeocoderAddressComponent[]) {
  let street_number, street;

  addressComponents
    .filter(
      (component) =>
        component.types.includes(STREET_NUMBER) ||
        component.types.includes(ROUTE)
    )
    .forEach((component) => {
      if (component.types.includes(STREET_NUMBER)) {
        street_number = component.short_name;
      } else {
        street = component.short_name;
      }
    });

  return street && street_number ? `${street_number} ${street}` : street;
}
