import { AppMode } from '@iheartjane/dm-sdk';

import type { AppMode as InternalAppMode } from '@jane/shared/models';

export const mapAppModes = (internalAppMode: InternalAppMode): AppMode => {
  switch (internalAppMode) {
    case 'bloom':
      return AppMode.Bloom;
    case 'brandEmbed':
      return AppMode.BrandEmbed;
    case 'embedded':
      return AppMode.Embedded;
    case 'framelessEmbed':
      return AppMode.FramelessEmbed;
    case 'headless':
      return AppMode.Headless;
    case 'kiosk':
      return AppMode.Kiosk;
    case 'operatorEmbed':
      return AppMode.OperatorEmbed;
    case 'whiteLabel':
      return AppMode.WhiteLabel;
    default:
      return AppMode.Default;
  }
};
