import { useSearchContext } from '@jane/search/providers';
import { PRODUCTS_PER_PAGE_CAROUSEL } from '@jane/search/util';
import { MenuProductCarousel } from '@jane/shared-ecomm/components';
import { useMyHigh } from '@jane/shared-ecomm/hooks';
import { useMenu } from '@jane/shared-ecomm/providers';
import {
  EventNames,
  track,
  trackUserSessionClickedSeeAll,
} from '@jane/shared-ecomm/tracking';
import { CardCarousel } from '@jane/shared/components';
import { ZoneStoreMenu } from '@jane/shared/models';
import { getAppliedWeightFilter } from '@jane/shared/util';

import { MenuProductCard } from '../../MenuComponents/MenuProductCards/MenuProductCard/MenuProductCard';
import { useComposeMenuFilters } from '../../hooks/useComposeMenuFilters';
import type { MenuCarouselProps } from './MenuBuyAgainCarousel';

interface MenuFeaturedCategoryCarouselProps extends MenuCarouselProps {
  disableAds: boolean;
  featuredCategoryFilter: string;
  title: string;
}

export const MenuFeaturedCategoryCarousel = ({
  disableAds,
  featuredCategoryFilter,
  placementIndex,
  mode = 'ideal-width',
  title,
}: MenuFeaturedCategoryCarouselProps) => {
  const { searchState } = useSearchContext();
  const { listView, paths, store } = useMenu();
  const staticFilters = useComposeMenuFilters([featuredCategoryFilter]);

  const { products, indexName, isLoading, numColumns, numHits } = useMyHigh({
    disableAds,
    isCarousel: true,
    preventTracking: true,
    productsPerPage: PRODUCTS_PER_PAGE_CAROUSEL,
    staticFilters,
  });

  if (!isLoading && products.length === 0) return null;

  const nextBreadcrumbs = [
    {
      title: 'Featured',
      url: paths.menuCategory('featured'),
    },
  ];

  return (
    <MenuProductCarousel
      isLoading={isLoading}
      listView={listView}
      mode={mode}
      name={title}
      variant="default"
      viewAllTo={paths.menuFeaturedCategory(title)}
      viewAllOnClick={() => {
        track({
          category: title,
          event: EventNames.ClickedSeeAll,
          linkLocation: 'see all link',
          numberOfItems: numHits,
          placementIndex: placementIndex || 0,
          storeId: `${store.id}`,
        });
        trackUserSessionClickedSeeAll({
          category: title,
          storeId: store.id,
        });
      }}
    >
      {products.map((product, index) => {
        const productKey = `${product?.isSponsored ? 'inline-ad-' : 'mp-'}${
          product.objectId
        }`;

        return (
          <CardCarousel.Card key={productKey} width="100%" widthMobile="100%">
            <MenuProductCard
              appliedWeightFilter={getAppliedWeightFilter(searchState)}
              algoliaIndexName={indexName}
              breadcrumbs={nextBreadcrumbs}
              columnPosition={index}
              menuRowName={title}
              product={product}
              productLocation={`featured/${title}`}
              numColumns={numColumns}
              zone={ZoneStoreMenu}
            />
          </CardCarousel.Card>
        );
      })}
    </MenuProductCarousel>
  );
};
