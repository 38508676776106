import type { SmartSortProduct } from '@iheartjane/dm-sdk';
import { useParams } from 'react-router-dom';

import { useSearchContext } from '@jane/search/providers';
import type { AlgoliaProduct } from '@jane/search/types';
import {
  DESKTOP_PRODUCTS_PER_PAGE_GRID,
  MOBILE_PRODUCTS_PER_PAGE_GRID,
} from '@jane/search/util';
import type { ProductCardWithTrackingProps } from '@jane/shared-ecomm/components';
import { useMyHigh } from '@jane/shared-ecomm/hooks';
import type { CardCarouselProps } from '@jane/shared/components';
import { useJaneUserId } from '@jane/shared/data-access';
import { Flex, useMobileMediaQuery } from '@jane/shared/reefer';
import type {
  Breadcrumb,
  MenuCategoryPath,
  MenuProduct,
} from '@jane/shared/types';

import { MenuEmptyResults } from '../MenuEmptyResults/MenuEmptyResults';
import { MenuFilters } from '../MenuFilters/MenuFilters';
import { MenuNavButtons } from '../MenuNavButtons/MenuNavButtons';
import { MenuProductsGrid } from '../MenuProductsGrid/MenuProductsGrid';
import { MenuSponsoredCarousel } from '../MenuSponsoredCarousel/MenuSponsoredCarousel';

interface MenuFiltersAndProductsGridProps {
  /* If the returned products need an additional filter applied */
  additionalProductFilter?: (
    products: SmartSortProduct<AlgoliaProduct>[]
  ) => SmartSortProduct<AlgoliaProduct>[];
  /* The current page url and title that will be used on the next page */
  breadcrumbs: Breadcrumb[];
  /* We disable ads for featured/custom category product pages */
  disableAds?: boolean;
  /* Used to determine if Buy Again page should be displayed */
  hasPurchasedProducts?: boolean;
  /* Used to remove weights from the edit view over a certain amount */
  maxWeightOption?: number | null;
  /** Used to determine which page we're on */
  menuCategory: MenuCategoryPath;
  /* Set to ideal-width by default but needs to be fixed to render in tests */
  mode?: CardCarouselProps['mode'];
  /* Used for product tap tracking */
  pageTitle: string;
  /* Show only these price ids (for weight-based specials) **/
  showOnlyWeights?: ProductCardWithTrackingProps['showOnlyWeights'];
  /** Static filters for the page, ex flower page uses flower filter by default */
  staticFilters: string;
  /** This function is passed to the product card edit view to remove unwanted weights */
  weightFilter?: (
    weight: string,
    product: MenuProduct | AlgoliaProduct
  ) => boolean;
  /** Boolean to not open the cart drawer when adding to cart */
  withoutDrawerOnAddToCart?: boolean;
}

export const MenuFiltersAndProductsGrid = ({
  additionalProductFilter,
  breadcrumbs,
  disableAds = false,
  hasPurchasedProducts = false,
  maxWeightOption,
  menuCategory,
  mode = 'ideal-width',
  pageTitle,
  showOnlyWeights,
  staticFilters,
  weightFilter,
  withoutDrawerOnAddToCart,
}: MenuFiltersAndProductsGridProps) => {
  const isMobile = useMobileMediaQuery({});
  const {
    products,
    indexName,
    isLoading,
    hasNextPage,
    numColumns,
    numHits,
    fetchNextPage,
    searchResultFacets,
  } = useMyHigh({
    disableAds,
    productsPerPage: isMobile
      ? MOBILE_PRODUCTS_PER_PAGE_GRID
      : DESKTOP_PRODUCTS_PER_PAGE_GRID,
    staticFilters,
  });

  const { searchState } = useSearchContext();

  const isSpecialsPage = menuCategory === 'specials';

  const isFilteringProducts =
    Object.values(searchState).filter(Boolean).length > 0;

  const userId = useJaneUserId();
  const { featured_category } = useParams<{
    featured_category: string;
  }>();

  if (!isFilteringProducts && numHits === 0 && isSpecialsPage && !isLoading) {
    return <MenuNavButtons isMobile={isMobile} />;
  }

  if (featured_category === 'buy-again' && (!userId || !hasPurchasedProducts)) {
    return <MenuEmptyResults />;
  }

  return (
    <Flex flexDirection="column">
      <MenuFilters
        isLoadingProducts={isLoading}
        menuCategory={menuCategory}
        searchResultFacets={searchResultFacets}
        totalResults={numHits}
        showOnlyWeights={showOnlyWeights}
        staticFilters={staticFilters}
      />
      {!isSpecialsPage && !disableAds && (
        <MenuSponsoredCarousel mode={mode} menuCategory={menuCategory} />
      )}
      <MenuProductsGrid
        additionalProductFilter={additionalProductFilter}
        breadcrumbs={breadcrumbs}
        pageTitle={pageTitle}
        products={products}
        indexName={indexName}
        isLoading={isLoading}
        hasNextPage={hasNextPage}
        maxWeightOption={maxWeightOption}
        numColumns={numColumns}
        numHits={numHits}
        fetchNextPage={fetchNextPage}
        showOnlyWeights={showOnlyWeights}
        weightFilter={weightFilter}
        withoutDrawerOnAddToCart={withoutDrawerOnAddToCart}
      />
    </Flex>
  );
};
