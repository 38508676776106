import type { SVGProps } from 'react';

import { Icon } from '../../icon/icon';
import type { LogoProps } from '../logo.types';

const Usaa = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 96 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_8796_17236)">
      <path
        d="M72.15 58.725C70.6875 58.725 69.525 59.8125 69.525 61.1625C69.525 62.5125 70.6875 63.6 72.15 63.6C73.6125 63.6 74.775 62.5125 74.775 61.1625C74.8125 59.8125 73.6125 58.725 72.15 58.725ZM72.15 63.15C70.95 63.15 69.975 62.25 69.975 61.1625C69.975 60.0375 70.95 59.175 72.15 59.175C73.35 59.175 74.325 60.075 74.325 61.1625C74.325 62.25 73.35 63.15 72.15 63.15ZM27.3375 59.025V66.8625C27.3375 71.25 30.075 71.9625 32.3625 71.9625C35.7375 71.9625 37.3125 69.75 37.3125 66.7875V59.025H35.0625V67.125C35.0625 68.1375 34.725 70.05 32.3625 70.0125C30.225 69.975 29.8125 68.025 29.8125 66.7875V59.025H27.3375ZM46.65 59.55C46.65 59.55 45.225 58.875 43.6125 58.875C41.5875 58.875 39.0375 59.8875 39.0375 62.6625C39.0375 64.575 40.5 65.5125 41.475 65.9625C42 66.225 42.6375 66.4875 43.1625 66.7875C43.425 66.9375 44.5125 67.275 44.5125 68.4375C44.5125 69.675 43.1625 70.0875 42.525 70.0875C40.35 70.0875 38.85 69.1125 38.85 69.1125L39.075 71.55C39.075 71.55 39.975 72 42.6375 72C45.1875 72 47.025 70.3875 47.025 68.175C47.025 66.4875 45.8625 65.475 44.85 64.9875C44.0625 64.6125 43.6125 64.3875 42.9 64.0875C42.225 63.75 41.5125 63.225 41.5125 62.3625C41.5125 61.4625 42.375 60.7875 43.6125 60.7875C45.45 60.7875 46.2 61.6125 46.65 61.875V59.55ZM64.8375 59.025H62.175L62.5875 60.075L58.425 70.9875L54.1125 59.025H51.45L51.8625 60.075L47.4375 71.7H49.875L50.6625 69.4875H55.2375L56.025 71.7H60.6L61.3875 69.4875H65.9625L66.75 71.7H69.45L64.8375 59.025ZM51.225 67.7625L53.025 62.775L54.6 67.7625H51.225ZM61.95 67.7625L63.7125 62.775L65.325 67.7625H61.95Z"
        fill="#1A3258"
      />
      <path
        d="M72.675 61.2375C72.675 61.2375 73.2 61.05 73.2 60.6C73.2 60.1125 72.7125 59.85 72.1125 59.85C71.5125 59.85 71.175 60 71.175 60V62.325H71.8875V61.4625H72.1125L72.8625 62.325H73.7625L72.675 61.2375ZM72.225 61.05H71.8875V60.2625C71.8875 60.2625 72.45 60.225 72.45 60.6C72.4875 61.0125 72.225 61.05 72.225 61.05ZM27.1875 44.7375L27.3 48.7125C27.3 48.7125 28.275 48 29.4 48C30.525 48 31.7625 48.7875 31.7625 48.7875L41.7 54.4875L42.525 50.8875L31.8 44.7375C31.8 44.7375 30.5625 43.95 29.4375 43.95C28.3125 43.95 27.1875 44.775 27.1875 44.7375Z"
        fill="#1A3258"
      />
      <path
        d="M27.1875 39.5625L27.3 43.5375C27.3 43.5375 28.275 42.825 29.4 42.825C30.525 42.825 31.7625 43.6125 31.7625 43.6125L42.7875 49.95L43.6125 46.35L31.8 39.525C31.8 39.525 30.5625 38.7375 29.4375 38.7375C28.3125 38.7375 27.1875 39.5625 27.1875 39.5625Z"
        fill="#1A3258"
      />
      <path
        d="M27.1875 34.3875L27.3 38.3625C27.3 38.3625 28.275 37.65 29.4 37.65C30.525 37.65 31.7625 38.4375 31.7625 38.4375L43.875 45.3375L44.7 41.7375L31.8 34.35C31.8 34.35 30.5625 33.5625 29.4375 33.5625C28.3125 33.5625 27.1875 34.3875 27.1875 34.3875Z"
        fill="#1A3258"
      />
      <path
        d="M47.925 28.05L49.05 32.7375L64.2 41.4375C64.2 41.4375 65.4 42.225 66.5625 42.225C67.725 42.225 68.775 41.4 68.775 41.4L68.625 37.425C68.625 37.425 67.6875 38.1375 66.525 38.1375C65.3625 38.1375 64.1625 37.35 64.1625 37.35L52.4625 30.6375L51.6375 27.6375C51.6375 27.525 51.75 27.45 51.7875 27.45L53.775 26.85C54.0375 26.85 54.1875 27.075 54.1875 27.2625L54.3 27.4875C54.375 27.525 54.675 27.375 54.675 27.375V26.1375C54.675 25.3125 54 24.6375 53.175 24.6375C53.175 24.6375 53.175 24.6375 53.1375 24.6375H51.15C51.15 24.6375 50.85 23.9625 50.025 23.9625H46.275C45.3 23.9625 45.0375 24.9 45.0375 24.9L42.4125 35.2875L31.8 29.175C31.8 29.175 30.6375 28.3875 29.4375 28.3875C28.2375 28.3875 27.225 29.2125 27.225 29.2125L27.3375 33.1875C27.3375 33.1875 28.2375 32.475 29.4375 32.475C30.6375 32.475 31.8 33.2625 31.8 33.2625L44.9625 40.8L47.925 28.05Z"
        fill="#1A3258"
      />
      <path
        d="M49.35 34.05L50.475 38.7375L64.2 46.6125C64.2 46.6125 65.4375 47.4 66.5625 47.4C67.6875 47.4 68.775 46.575 68.775 46.575L68.6625 42.6C68.6625 42.6 67.8 43.3125 66.5625 43.3125C65.325 43.3125 64.2 42.525 64.2 42.525L49.35 34.05Z"
        fill="#1A3258"
      />
      <path
        d="M50.7375 40.0125L51.8625 44.7L64.2 51.75C64.2 51.75 65.4375 52.5375 66.5625 52.5375C67.6875 52.5375 68.775 51.7125 68.775 51.7125L68.625 47.8125C68.625 47.8125 67.7625 48.525 66.525 48.525C65.2875 48.525 64.1625 47.7375 64.1625 47.7375L50.7375 40.0125Z"
        fill="#1A3258"
      />
      <path
        d="M52.1625 46.0125L53.25 50.7375L64.1625 57C64.1625 57 65.4 57.7875 66.525 57.7875C67.65 57.7875 68.7375 56.9625 68.7375 56.9625L68.625 52.9875C68.625 52.9875 67.7625 53.7 66.525 53.7C65.2875 53.7 64.1625 52.9125 64.1625 52.9125L52.1625 46.0125Z"
        fill="#1A3258"
      />
    </g>
    <defs>
      <clipPath id="clip0_8796_17236">
        <rect
          width={48}
          height={48}
          fill="white"
          transform="translate(27 24)"
        />
      </clipPath>
    </defs>
  </svg>
);
export const UsaaLogo = ({
  height = 48,
  width = 'auto',
  ...props
}: LogoProps) => {
  return (
    <Icon
      height={height}
      width={width}
      {...props}
      icon={Usaa}
      color={undefined}
      rotate={undefined}
      size={undefined}
    />
  );
};
