import { useCallback } from 'react';

import { inIframe } from '@jane/shared-ecomm/util';
import { postMessageToIframeParent } from '@jane/shared/util';

import { HEADER_HEIGHT } from '../types/headerConstants';

export const useScrollToElement = (elementId: string) => {
  const isInIframe = inIframe();

  const headerOffset = HEADER_HEIGHT;

  return useCallback(() => {
    const shadowHost = document.getElementById('shadow-host')?.shadowRoot;
    let element = shadowHost?.getElementById(elementId);
    element = element || document.getElementById(elementId);

    const elementY = element?.getBoundingClientRect().top;
    const elementMarginString = element
      ? window.getComputedStyle(element).marginTop
      : '0';
    const elementMargin = parseInt(elementMarginString, 10);

    if (!elementY) return;

    if (isInIframe) {
      const payload = elementY - headerOffset - elementMargin;
      postMessageToIframeParent({
        messageType: 'scrollTo',
        payload: payload,
      });
    } else {
      const scrollTop = document.documentElement.scrollTop;

      window.scrollTo({
        top: elementY - headerOffset - elementMargin + scrollTop,
        behavior: 'smooth',
      });
    }
  }, [elementId, isInIframe]);
};
