import { useJaneGoldData } from '@jane/shared-ecomm/hooks';
import { LoadingWrapper } from '@jane/shared/components';
import {
  Box,
  Button,
  CheckIcon,
  Flex,
  JaneGoldIcon,
  Typography,
  useMobileMediaQuery,
} from '@jane/shared/reefer';

import type { JaneGoldPdpCalloutProps } from '../janeGoldCallout.types';
import { JaneGoldTermsLink } from '../janeGoldTermsLink/janeGoldTermsLink';
import {
  StyledPdpContainer,
  StyledPdpDescriptionWrapper,
  StyledPdpInnerContainer,
  StyledPdpTitleDescriptionWrapper,
} from './janeGoldPdpCallout.styles';

/**
 * PDP variant of the Jane Gold callout action component. If the user doesn't have a linked bank, it will display a description, `Learn more` link and `Link bank account` button. If the user has a linked bank, it will only display the title.
 */
export const JaneGoldPdpCallout = ({
  buttonLabel,
  description,
  onLinkAccountClick,
  title,
  onMenu = false,
}: JaneGoldPdpCalloutProps) => {
  const { userHasLinkedBank, isLoading } = useJaneGoldData();
  const isMobile = useMobileMediaQuery({});

  return (
    <Box p={24} borderRadius="lg" border="grays-light">
      <LoadingWrapper variant="block" height={24} isLoading={isLoading}>
        <StyledPdpContainer alignItems="center" onMenu={onMenu}>
          <StyledPdpInnerContainer alignItems="center">
            <StyledPdpTitleDescriptionWrapper flexDirection="column">
              <Flex
                flexDirection="row"
                alignItems="center"
                gap={8}
                mb={!userHasLinkedBank ? 8 : 0}
                width="100%"
              >
                <Box>
                  <JaneGoldIcon altText="jane-gold-icon" size="md" />
                </Box>
                <Typography variant="body-bold">{title}</Typography>
                {userHasLinkedBank && (
                  <Box ml="auto">
                    <CheckIcon altText="check-icon" size="md" />
                  </Box>
                )}
              </Flex>
              {!userHasLinkedBank && (
                <StyledPdpDescriptionWrapper>
                  <Typography as="span" variant="body">
                    {description}
                    &nbsp;
                    <JaneGoldTermsLink />
                  </Typography>
                </StyledPdpDescriptionWrapper>
              )}
            </StyledPdpTitleDescriptionWrapper>
          </StyledPdpInnerContainer>

          {!userHasLinkedBank && (
            <Button
              onClick={onLinkAccountClick}
              full={isMobile}
              variant="secondary"
              label={buttonLabel}
            />
          )}
        </StyledPdpContainer>
      </LoadingWrapper>
    </Box>
  );
};
