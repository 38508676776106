import { Flex, useMobileMediaQuery } from '@jane/shared/reefer';

import { StyledGrid } from '../menuProductGrid/menuProductGrid.styles';
import type { MenuProductGridProps } from '../menuProductGrid/menuProductGrid.types';

export const EmptyMenuProductGrid = ({
  children,
  hasMore,
  hideTotal = false,
  isLoading,
  isLoadingMore,
  listView = false,
  onViewMoreClick,
  totalResults,
  ...props
}: Partial<MenuProductGridProps>) => {
  const isMobile = useMobileMediaQuery({});
  return (
    <Flex
      flexDirection="column"
      width="100%"
      alignItems="center"
      {...props}
      height={0}
      px={isMobile ? 24 : 64}
    >
      <StyledGrid
        listView={listView}
        id="empty-menu-product-grid" // used to determine # of grid columns for smart sort fetch
        height={0}
      />
    </Flex>
  );
};
