import { config } from '@jane/shared/config';
import { getRuntimeConfig } from '@jane/shared/runtime-config';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare let __webpack_public_path__: string;

export const maybeSetPublicPath = (deployPublicPathSuffix = '') => {
  const runtimeConfig = getRuntimeConfig();

  // eslint-disable-next-line no-extra-boolean-cast
  if (!!runtimeConfig.partnerHostedStoreId) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    __webpack_public_path__ = `${config.deployPublicPath}${deployPublicPathSuffix}`;
  }
};
