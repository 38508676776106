import omit from 'lodash/omit';
import type { ChangeEvent } from 'react';
import { Field } from 'react-final-form';
import { PatternFormat } from 'react-number-format';

import TextInput from '../component-library/text-input/text-input';
import type { TextInputFieldProps } from '../component-library/text-input/text-input';

const formatPhoneNumber = (value: string) => value.replace(/[^0-9]/g, '');

const PHONE_FORMAT = '###-###-####';

export const PhoneInputField = (props: TextInputFieldProps) => (
  <Field label={props.label} name={props.name} parse={formatPhoneNumber}>
    {({ meta, input }) => {
      const hasError = (meta.error || meta.submitError) && meta.touched;
      // Show error or helper text
      const helperText = hasError
        ? meta.error || meta.submitError
        : props.helperText;

      return (
        <PatternFormat
          customInput={TextInput}
          // @ts-ignore
          defaultValue={input.value ? formatPhoneNumber(input.value) : ''}
          format={PHONE_FORMAT}
          mask={'_'}
          {...props}
          {...omit(input, 'value')}
          type="tel"
          helperText={helperText}
          error={hasError}
          onBlur={input.onBlur}
          onFocus={input.onFocus}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            input.onChange(formatPhoneNumber(event.target.value))
          }
          onValueChange={({ formattedValue }) => input.onChange(formattedValue)}
        />
      );
    }}
  </Field>
);
