import { PRODUCTS_PER_PAGE_CAROUSEL } from '@jane/search/util';
import { MenuProductCarousel } from '@jane/shared-ecomm/components';
import { useMyHigh } from '@jane/shared-ecomm/hooks';
import { useMenu } from '@jane/shared-ecomm/providers';
import {
  EventNames,
  track,
  trackUserSessionClickedSeeAll,
} from '@jane/shared-ecomm/tracking';
import type { CardCarouselProps } from '@jane/shared/components';
import { CardCarousel } from '@jane/shared/components';
import type { MenuCategoryPath } from '@jane/shared/types';

import { MenuQuickAddCard } from '../../MenuComponents/MenuProductCards/MenuQuickAddCard/MenuQuickAddCard';
import { useBuyAgain } from '../../hooks/useBuyAgain';
import { useComposeMenuFilters } from '../../hooks/useComposeMenuFilters';

export interface MenuCarouselProps {
  menuCategory?: MenuCategoryPath;
  mode?: CardCarouselProps['mode'];
  placementIndex?: number;
}

interface MenuBuyAgainCarouselProps extends MenuCarouselProps {
  userId: number;
}

export const MenuBuyAgainCarousel = ({
  mode = 'ideal-width',
  placementIndex,
  userId,
}: MenuBuyAgainCarouselProps) => {
  const { paths, store } = useMenu();
  const { purchasedProducts, purchasedProductFilter } = useBuyAgain({
    userId,
  });
  const slicedPurchasedProducts = purchasedProducts?.slice(
    0,
    PRODUCTS_PER_PAGE_CAROUSEL
  );

  const staticFilters = useComposeMenuFilters([purchasedProductFilter]);

  const { products, indexName, isLoading } = useMyHigh({
    enabled: !!purchasedProductFilter.length,
    isCarousel: true,
    preventTracking: true,
    productsPerPage: PRODUCTS_PER_PAGE_CAROUSEL,
    staticFilters,
  });

  const mergedProducts = slicedPurchasedProducts.map((product) => {
    const productId = product.product_id;
    const priceId = product.price_id;
    const matchingProduct = products.find((product) => {
      return Number(product.attributes['product_id']) === Number(productId);
    });

    return (
      matchingProduct && {
        priceId,
        product: matchingProduct,
      }
    );
  });

  const name = 'Buy again';

  const nextBreadcrumbs = [
    {
      title: 'Featured',
      url: paths.menuCategory('featured'),
    },
  ];

  if (!purchasedProducts.length) return null;

  return (
    <MenuProductCarousel
      isLoading={isLoading || !mergedProducts.filter(Boolean).length}
      listView={false}
      minHeight={326}
      mode={mode}
      name={name}
      variant="default"
      viewAllTo={paths.menuFeaturedCategory(name)}
      viewAllOnClick={() => {
        track({
          category: name,
          event: EventNames.ClickedSeeAll,
          linkLocation: 'see all link',
          numberOfItems: products?.length || 0,
          placementIndex: placementIndex || 0,
          storeId: `${store.id}`,
        });
        trackUserSessionClickedSeeAll({
          category: name,
          storeId: store.id,
        });
      }}
    >
      {mergedProducts.map((item, index) => {
        if (!item) return null;

        const { priceId, product } = item;

        const productKey = `${product?.isSponsored ? 'inline-ad-' : 'mp-'}${
          product.objectId
        }-${priceId}`;

        return (
          <CardCarousel.Card key={productKey} width="100%" widthMobile="100%">
            <MenuQuickAddCard
              algoliaIndexName={indexName}
              breadcrumbs={nextBreadcrumbs}
              index={index}
              priceId={priceId}
              product={product}
            />
          </CardCarousel.Card>
        );
      })}
    </MenuProductCarousel>
  );
};
