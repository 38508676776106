import styled from '@emotion/styled';
import { useState } from 'react';

import { JumboDiscountBadge } from '@jane/shared-ecomm/components';
import { useMenu } from '@jane/shared-ecomm/providers';
import { getReservationModeLabel } from '@jane/shared-ecomm/util';
import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import {
  Box,
  Card,
  Flex,
  Image,
  Link,
  Typography,
  useMobileMediaQuery,
} from '@jane/shared/reefer';
import { shadows } from '@jane/shared/reefer-emotion';
import type { StoreSpecial } from '@jane/shared/types';
import {
  getDiscountLabel,
  getSpecialType,
  hasSingleReservationMode,
} from '@jane/shared/util';

const MobileDetailContainer = styled.div({
  boxShadow: shadows.divider,
});

interface SpecialDetailHeaderProps {
  special: StoreSpecial;
}

export const MenuSpecialDetailHeader = ({
  special,
}: SpecialDetailHeaderProps) => {
  const customBadgeEnabled = useFlag(FLAGS.consumerSpecialsCustomBadge);
  const [showTerms, setShowTerms] = useState(false);
  const isMobile = useMobileMediaQuery({});
  const { store } = useMenu();

  const { title, description, photo, terms } = special;

  const specialPhoto = photo?.urls?.original || null;
  const headerLabel = getSpecialType(special.special_type, store);

  const reservationMode = hasSingleReservationMode(special?.reservation_modes);
  const specialAvailability =
    reservationMode &&
    getReservationModeLabel(store?.reservation_mode_labels, reservationMode);

  if (isMobile) {
    return (
      <MobileDetailContainer>
        <Flex height="200px" justifyContent="center" alignItems="center">
          {!specialPhoto && (
            <JumboDiscountBadge color="info" textColor="text-info">
              {customBadgeEnabled && special.custom_badge
                ? special.custom_badge
                : getDiscountLabel(special, store)}
            </JumboDiscountBadge>
          )}

          {specialPhoto && (
            <Image
              height="100%"
              src={specialPhoto}
              altText={`${special.title} special photo`}
              responsive
              sizes="card"
            />
          )}
        </Flex>

        <Box p={24}>
          <Flex>
            <Typography variant="body-bold" color="info" as="p" mb={8}>
              {headerLabel}
            </Typography>

            {specialAvailability && (
              <Typography color="text-light" variant="mini" ml="auto">
                {specialAvailability} only
              </Typography>
            )}
          </Flex>

          <Typography branded variant="header-bold">
            {title}
          </Typography>

          <Typography>{description}</Typography>
          {terms && (
            <Typography as="span">
              <Link onClick={() => setShowTerms((prevValue) => !prevValue)}>
                Terms and Conditions
              </Link>
            </Typography>
          )}

          {terms && showTerms && <Typography>{terms}</Typography>}
        </Box>
      </MobileDetailContainer>
    );
  }

  return (
    <Card border="grays-light" width="100%" flat>
      <Card.Group direction="row" width="100%">
        {specialPhoto ? (
          <Card.Image
            src={specialPhoto}
            alt={`${special.title} special photo`}
            height="100%"
            width="320px"
            responsive
            sizes="card"
          />
        ) : (
          <Card.Content width="320px">
            <Flex
              alignItems="center"
              height="100%"
              justifyContent="center"
              px={24}
              py={40}
              width="100%"
            >
              <JumboDiscountBadge color="info" textColor="text-info">
                {customBadgeEnabled && special.custom_badge
                  ? special.custom_badge
                  : getDiscountLabel(special, store)}
              </JumboDiscountBadge>
            </Flex>
          </Card.Content>
        )}
        <Card.Content>
          <Box p={24}>
            <Flex>
              <Typography variant="header-bold" color="info">
                {headerLabel}
              </Typography>

              {specialAvailability && (
                <Typography color="text-light" variant="mini" ml="auto">
                  {specialAvailability} only
                </Typography>
              )}
            </Flex>
            <Typography branded variant="title-bold" mb={16}>
              {title}
            </Typography>

            <Typography>{description}</Typography>
            {terms && (
              <Link
                typography="body"
                onClick={() => setShowTerms((prevValue) => !prevValue)}
              >
                Terms and Conditions
              </Link>
            )}

            {terms && showTerms && <Typography>{terms}</Typography>}
          </Box>
        </Card.Content>
      </Card.Group>
    </Card>
  );
};
