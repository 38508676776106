import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';

import { useMenu } from '@jane/shared-ecomm/providers';
import { Flex } from '@jane/shared/reefer';
import type { MenuCategoryPath } from '@jane/shared/types';

import { useGetPage } from '../../hooks/useGetPage';
import { MenuCommsBanner } from './MenuCommsBanner/MenuCommsBanner';
import { MenuReviewBanner } from './MenuReviewBanner/MenuReviewBanner';

const StyledFlex = styled(Flex)({
  '&:empty': {
    padding: 0,
  },
});

export const MenuBanners = ({ tabs }: { tabs: MenuCategoryPath[] }) => {
  const {
    appInfo: { appMode },
  } = useMenu();
  const params = useParams();
  const page = useGetPage();
  const firstTab = tabs[0];

  const showCommsBanner =
    page === 'menu' ||
    page === firstTab ||
    (page === 'category' && params['category'] === firstTab);

  const commsBannerEnabled = [
    'embedded',
    'framelessEmbed',
    'whiteLabel',
    'operatorEmbed',
  ].includes(appMode);

  const reviewBannerEnabled = [
    'default',
    'embedded',
    'framelessEmbed',
    'whiteLabel',
  ].includes(appMode);

  if (!commsBannerEnabled && !reviewBannerEnabled) return null;

  return (
    <StyledFlex gap={24} flexDirection="column" pb={24}>
      {commsBannerEnabled && showCommsBanner && <MenuCommsBanner />}
      {reviewBannerEnabled && <MenuReviewBanner />}
    </StyledFlex>
  );
};
