import type { SVGProps } from 'react';

import { Icon } from '../../icon/icon';
import type { LogoProps } from '../logo.types';

const JaneGoldShadow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 206 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={50.5088}
      y={19.6316}
      width={5.62217}
      height={8.84625}
      fill="#0E0E0E"
    />
    <rect
      x={19.2712}
      y={29.8771}
      width={16.1494}
      height={13.3191}
      fill="#0E0E0E"
    />
    <path
      d="M77.8833 18.5621C77.8833 18.3187 78.0806 18.1214 78.324 18.1214C78.5674 18.1214 78.7647 18.3187 78.7647 18.5621C78.7647 23.7951 83.0068 28.0372 88.2397 28.0372C88.4831 28.0372 88.6804 28.2345 88.6804 28.4779C88.6804 28.7213 88.4831 28.9186 88.2397 28.9186C83.0068 28.9186 78.7647 33.1607 78.7647 38.3937C78.7647 38.6371 78.5674 38.8344 78.324 38.8344C78.0806 38.8344 77.8833 38.6371 77.8833 38.3937C77.8833 33.1607 73.6411 28.9186 68.4082 28.9186C68.1648 28.9186 67.9675 28.7213 67.9675 28.4779C67.9675 28.2345 68.1648 28.0372 68.4082 28.0372C73.6411 28.0372 77.8833 23.7951 77.8833 18.5621Z"
      fill="white"
      stroke="white"
      strokeWidth={0.881402}
      strokeLinejoin="round"
    />
    <rect
      x={50.5088}
      y={19.6316}
      width={5.62217}
      height={8.84625}
      fill="#0E0E0E"
    />
    <rect
      x={19.2712}
      y={29.8771}
      width={16.1494}
      height={13.3191}
      fill="#0E0E0E"
    />
    <g filter="url(#filter0_d_4474_6332)">
      <path
        d="M50.7801 10.8498C50.7801 6.59047 47.3272 3.13757 43.0678 3.13757H27.6433C13.6482 3.13757 2.30298 14.4828 2.30298 28.4779C2.30298 42.4729 13.6482 53.8182 27.6433 53.8182H38.6608C45.3541 53.8182 50.7801 48.3922 50.7801 41.6989V23.5075C50.7801 22.0692 49.6142 20.9033 48.1759 20.9033H29.7779C25.5566 20.9033 22.1345 24.3254 22.1345 28.5467C22.1345 32.7681 25.5566 36.1901 29.7779 36.1901H32.0503C32.6588 36.1901 33.1521 36.6834 33.1521 37.2919C33.1521 37.9004 32.6588 38.3937 32.0503 38.3937H29.8468C24.3705 38.3937 19.931 33.9542 19.931 28.4779C19.931 23.0016 24.3705 18.5621 29.8468 18.5621H43.0678C47.3272 18.5621 50.7801 15.1092 50.7801 10.8498Z"
        fill="url(#paint0_linear_4474_6332)"
      />
      <path
        d="M51.8818 10.8498C51.8818 5.98199 47.9357 2.03582 43.0678 2.03582H27.6433C13.0397 2.03582 1.20123 13.8743 1.20123 28.4779C1.20123 43.0814 13.0398 54.9199 27.6433 54.9199H38.6608C45.9626 54.9199 51.8818 49.0007 51.8818 41.6989V23.5075C51.8818 21.4608 50.2227 19.8016 48.1759 19.8016H29.7779C24.9597 19.8016 21.0515 23.6982 21.0328 28.5121C21.0328 28.5007 21.0328 28.4893 21.0328 28.4779C21.0328 23.61 24.9789 19.6639 29.8468 19.6639H43.0678C47.9357 19.6639 51.8818 15.7177 51.8818 10.8498Z"
        stroke="#0E0E0E"
        strokeWidth={2.20351}
      />
    </g>
    <g filter="url(#filter1_d_4474_6332)">
      <mask
        id="path-8-outside-1_4474_6332"
        maskUnits="userSpaceOnUse"
        x={49.9836}
        y={0.137573}
        width={56}
        height={56}
        fill="black"
      >
        <rect fill="white" x={49.9836} y={0.137573} width={56} height={56} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M103.664 28.4779C103.664 14.4828 92.319 3.13757 78.3239 3.13757C64.3289 3.13757 52.9836 14.4828 52.9836 28.4779C52.9836 42.4729 64.3289 53.8182 78.3239 53.8182C92.319 53.8182 103.664 42.4729 103.664 28.4779Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M103.664 28.4779C103.664 14.4828 92.319 3.13757 78.3239 3.13757C64.3289 3.13757 52.9836 14.4828 52.9836 28.4779C52.9836 42.4729 64.3289 53.8182 78.3239 53.8182C92.319 53.8182 103.664 42.4729 103.664 28.4779Z"
        fill="url(#paint1_linear_4474_6332)"
      />
      <path
        d="M78.3239 56.0217C93.536 56.0217 105.868 43.6899 105.868 28.4779H101.461C101.461 41.256 91.1021 51.6147 78.3239 51.6147V56.0217ZM50.7801 28.4779C50.7801 43.6899 63.1119 56.0217 78.3239 56.0217V51.6147C65.5458 51.6147 55.1871 41.256 55.1871 28.4779H50.7801ZM78.3239 5.34108C91.1021 5.34108 101.461 15.6998 101.461 28.4779H105.868C105.868 13.2659 93.536 0.934068 78.3239 0.934068V5.34108ZM78.3239 0.934068C63.1119 0.934068 50.7801 13.2659 50.7801 28.4779H55.1871C55.1871 15.6998 65.5458 5.34108 78.3239 5.34108V0.934068Z"
        fill="#0E0E0E"
        mask="url(#path-8-outside-1_4474_6332)"
      />
    </g>
    <path
      d="M77.8833 18.5621C77.8833 18.3187 78.0806 18.1214 78.324 18.1214C78.5674 18.1214 78.7647 18.3187 78.7647 18.5621C78.7647 23.7951 83.0068 28.0372 88.2397 28.0372C88.4831 28.0372 88.6804 28.2345 88.6804 28.4779C88.6804 28.7213 88.4831 28.9186 88.2397 28.9186C83.0068 28.9186 78.7647 33.1607 78.7647 38.3937C78.7647 38.6371 78.5674 38.8344 78.324 38.8344C78.0806 38.8344 77.8833 38.6371 77.8833 38.3937C77.8833 33.1607 73.6411 28.9186 68.4082 28.9186C68.1648 28.9186 67.9675 28.7213 67.9675 28.4779C67.9675 28.2345 68.1648 28.0372 68.4082 28.0372C73.6411 28.0372 77.8833 23.7951 77.8833 18.5621Z"
      fill="white"
      stroke="white"
      strokeWidth={0.881402}
      strokeLinejoin="round"
    />
    <g filter="url(#filter2_d_4474_6332)">
      <path
        d="M127.903 14.1551C127.903 8.07029 122.97 3.13757 116.885 3.13757C110.8 3.13757 105.868 8.07028 105.868 14.1551V41.7991C105.868 48.437 111.249 53.8182 117.887 53.8182H138.92C145.005 53.8182 149.938 48.8855 149.938 42.8007C149.938 36.7159 145.005 31.7831 138.92 31.7831H127.903V14.1551Z"
        fill="url(#paint2_linear_4474_6332)"
      />
      <path
        d="M129.004 14.1551C129.004 7.46181 123.578 2.03582 116.885 2.03582C110.192 2.03582 104.766 7.46179 104.766 14.1551V41.7991C104.766 49.0455 110.64 54.9199 117.887 54.9199H138.92C145.614 54.9199 151.04 49.494 151.04 42.8007C151.04 36.1074 145.614 30.6814 138.92 30.6814H129.004V14.1551Z"
        stroke="#0E0E0E"
        strokeWidth={2.20351}
      />
    </g>
    <g filter="url(#filter3_d_4474_6332)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M198.415 28.4779C198.415 14.4828 187.069 3.13757 173.074 3.13757H156.548C154.114 3.13757 152.141 5.11065 152.141 7.54458V49.4112C152.141 51.8451 154.114 53.8182 156.548 53.8182H173.074C187.069 53.8182 198.415 42.4729 198.415 28.4779ZM173.074 38.3937C173.683 38.3937 174.176 37.9004 174.176 37.2919V19.6639C174.176 19.0554 173.683 18.5621 173.074 18.5621C172.466 18.5621 171.973 19.0554 171.973 19.6639V28.4779V37.2919C171.973 37.9004 172.466 38.3937 173.074 38.3937Z"
        fill="url(#paint3_linear_4474_6332)"
      />
      <path
        d="M199.516 28.4779C199.516 13.8743 187.678 2.03582 173.074 2.03582H156.548C153.506 2.03582 151.039 4.50217 151.039 7.54458V49.4112C151.039 52.4536 153.506 54.9199 156.548 54.9199H173.074C187.678 54.9199 199.516 43.0814 199.516 28.4779Z"
        stroke="#0E0E0E"
        strokeWidth={2.20351}
      />
    </g>
    <path
      d="M77.8833 18.5621C77.8833 18.3187 78.0806 18.1214 78.324 18.1214C78.5674 18.1214 78.7647 18.3187 78.7647 18.5621C78.7647 23.7951 83.0068 28.0372 88.2397 28.0372C88.4831 28.0372 88.6804 28.2345 88.6804 28.4779C88.6804 28.7213 88.4831 28.9186 88.2397 28.9186C83.0068 28.9186 78.7647 33.1607 78.7647 38.3937C78.7647 38.6371 78.5674 38.8344 78.324 38.8344C78.0806 38.8344 77.8833 38.6371 77.8833 38.3937C77.8833 33.1607 73.6411 28.9186 68.4082 28.9186C68.1648 28.9186 67.9675 28.7213 67.9675 28.4779C67.9675 28.2345 68.1648 28.0372 68.4082 28.0372C73.6411 28.0372 77.8833 23.7951 77.8833 18.5621Z"
      fill="white"
      stroke="white"
      strokeWidth={0.881402}
      strokeLinejoin="round"
    />
    <defs>
      <filter
        id="filter0_d_4474_6332"
        x={0.0993652}
        y={0.934082}
        width={57.2913}
        height={59.4946}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={4.40701} dy={4.40701} />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.054902 0 0 0 0 0.054902 0 0 0 0 0.054902 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4474_6332"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_4474_6332"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_4474_6332"
        x={50.78}
        y={0.934082}
        width={59.4947}
        height={59.4946}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={4.40701} dy={4.40701} />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.054902 0 0 0 0 0.054902 0 0 0 0 0.054902 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4474_6332"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_4474_6332"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_d_4474_6332"
        x={103.664}
        y={0.934082}
        width={52.8841}
        height={59.4946}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={4.40701} dy={4.40701} />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.054902 0 0 0 0 0.054902 0 0 0 0 0.054902 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4474_6332"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_4474_6332"
          result="shape"
        />
      </filter>
      <filter
        id="filter3_d_4474_6332"
        x={149.938}
        y={0.934082}
        width={55.0877}
        height={59.4946}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={4.40701} dy={4.40701} />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.054902 0 0 0 0 0.054902 0 0 0 0 0.054902 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4474_6332"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_4474_6332"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_4474_6332"
        x1={26.5415}
        y1={3.13757}
        x2={26.5415}
        y2={53.8182}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.291667} stopColor="#FFC220" />
        <stop offset={1} stopColor="#FF9900" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_4474_6332"
        x1={78.324}
        y1={3.13757}
        x2={78.324}
        y2={53.8182}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.291667} stopColor="#FFC220" />
        <stop offset={1} stopColor="#FF9900" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_4474_6332"
        x1={127.903}
        y1={3.13757}
        x2={127.903}
        y2={53.8182}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.291667} stopColor="#FFC220" />
        <stop offset={1} stopColor="#FF9900" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_4474_6332"
        x1={175.278}
        y1={3.13757}
        x2={175.278}
        y2={53.8182}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.291667} stopColor="#FFC220" />
        <stop offset={1} stopColor="#FF9900" />
      </linearGradient>
    </defs>
  </svg>
);
export const JaneGoldShadowLogo = ({
  height = 48,
  width = 'auto',
  ...props
}: LogoProps) => {
  return (
    <Icon
      height={height}
      width={width}
      {...props}
      icon={JaneGoldShadow}
      color={undefined}
      rotate={undefined}
      size={undefined}
    />
  );
};
