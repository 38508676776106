import { useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { useMenuTopRow, useMenuTopRowTimer } from '@jane/dm/internal';
import { useSearchContext } from '@jane/search/providers';
import {
  buildSearchFilter,
  formatFeaturedCategory,
  mapAppModes,
} from '@jane/search/util';
import { MenuProductCarousel } from '@jane/shared-ecomm/components';
import { useMenu } from '@jane/shared-ecomm/providers';
import { CardCarousel } from '@jane/shared/components';
import { config } from '@jane/shared/config';
import type { AppMode, Store } from '@jane/shared/models';
import { ZoneStoreMenu, ZoneStoreMenuInline } from '@jane/shared/models';
import { useMobileMediaQuery } from '@jane/shared/reefer';
import type { MenuCategoryPath } from '@jane/shared/types';
import {
  getSurfaceByAppMode,
  insertIfArr,
  sentenceCase,
} from '@jane/shared/util';

import type { MenuCarouselProps } from '../../MenuPages/MenuFeatured/MenuBuyAgainCarousel';
import { useComposeMenuFilters } from '../../hooks/useComposeMenuFilters';
import { MenuProductCard } from '../MenuProductCards/MenuProductCard/MenuProductCard';

export const useRefreshTime = (store: Store, appMode: AppMode) => {
  const surface = getSurfaceByAppMode(appMode);

  return useMemo(() => {
    const { menu_top_row_refresh_time: refreshTimesBySurface } = store;

    if (!Object.keys(refreshTimesBySurface).length) {
      return 15;
    }

    const refreshTime = refreshTimesBySurface[surface];

    return refreshTime ? store.menu_top_row_refresh_time[surface] / 1000 : 15;
  }, []);
};

export const MenuSponsoredCarousel = ({
  menuCategory,
  mode = 'ideal-width',
}: MenuCarouselProps) => {
  const cardRef = useRef<{ resetScroll: () => void }>(null);
  const isMobile = useMobileMediaQuery({});
  const { featured_category: featuredCategory } = useParams<{
    featured_category: string;
  }>();

  const { searchState } = useSearchContext();
  const {
    appInfo: { appMode, janeDeviceId },
    listView,
    paths,
    store,
  } = useMenu();

  const isFeaturedPage = menuCategory === 'featured';
  const isFilteringProducts =
    Object.values(searchState).filter(Boolean).length > 0;
  const filterByCategory =
    menuCategory && !['all', 'featured'].includes(menuCategory);

  const { featuredTitle } = formatFeaturedCategory(store, featuredCategory);

  const nextBreadcrumbs = [
    ...insertIfArr(isFeaturedPage, {
      title: 'Featured',
      url: paths.menuCategory('featured'),
    }),
    ...insertIfArr(!isFeaturedPage, {
      title:
        menuCategory === 'all'
          ? 'All products'
          : sentenceCase(menuCategory || ''),
      url: paths.menuCategory(menuCategory as MenuCategoryPath),
    }),
    ...insertIfArr(!!featuredCategory, {
      title: featuredTitle,
      url: paths.menuFeaturedCategory(featuredCategory || ''),
    }),
  ];

  const isModalOpen = document.body.classList.contains(
    'ReactModal__Body--open'
  );

  const staticFilters = useComposeMenuFilters(
    filterByCategory
      ? [`kind:"${menuCategory}" OR root_types:"${menuCategory}"`]
      : []
  );

  const filteredSearchState = {
    ...searchState,
    filters: { ...searchState?.filters, brand: [] },
  };

  const searchFilter = buildSearchFilter({
    searchState: filteredSearchState,
    staticFilters,
  });

  const refreshTime = useRefreshTime(store, appMode);

  const { isTimerFinished, pauseTimer, restartTimer, unpauseTimer } =
    useMenuTopRowTimer(refreshTime);

  const { isError, isFetching, rowInstance } = useMenuTopRow({
    appMode: mapAppModes(appMode),
    enableRefetch: !isModalOpen && !searchState?.searchText,
    isTimerFinished,
    jdid: janeDeviceId,
    pageCategory: menuCategory,
    pauseTimer,
    restartTimer,
    searchAttributes: ['*'],
    searchFilter,
    storeId: Number(store.id),
  });

  const rowName = rowInstance?.title ?? '';
  const isInline = !isFeaturedPage || isFilteringProducts || featuredCategory;

  const hideSponsoredCarousel =
    isError || !rowInstance?.products.length || searchState?.searchText;

  useEffect(() => {
    if (!hideSponsoredCarousel) {
      if (cardRef.current) {
        cardRef.current.resetScroll();
      }
    }
  }, [hideSponsoredCarousel]);

  /**
   * This hook is used to trigger the impression event for the products in the sponsored carousel
   * when the carousel is rendered.
   */
  useEffect(() => {
    if (rowInstance) {
      rowInstance.products.forEach((product) => {
        product.impress();
      });
    }
  }, [rowInstance]);

  if (hideSponsoredCarousel) return null;

  return (
    <MenuProductCarousel
      cardRef={cardRef}
      mode={mode}
      listView={listView}
      name={rowName}
      onHover={() => {
        pauseTimer();
      }}
      onLeave={() => {
        unpauseTimer();
      }}
      isLoading={isFetching}
      variant={isInline ? 'sponsored-inline' : 'sponsored'}
      mx={isMobile ? 24 : 64}
      mb={24}
    >
      {!isFetching &&
        rowInstance.products.map((product, index) => (
          <CardCarousel.Card
            key={`row-ad-${product.objectId}`}
            width="100%"
            widthMobile="100%"
          >
            <MenuProductCard
              algoliaIndexName={`menu-products-${config.algoliaEnv}`}
              appliedWeightFilter=""
              breadcrumbs={nextBreadcrumbs}
              carouselView={true}
              columnPosition={index}
              menuRowName={rowName}
              productLocation={`${featuredTitle || menuCategory}/${rowName}`}
              product={product}
              rowPosition={0}
              zone={isInline ? ZoneStoreMenu : ZoneStoreMenuInline}
              isMenuTopRow={true}
            />
          </CardCarousel.Card>
        ))}
    </MenuProductCarousel>
  );
};
