import { useMenu, useSpecials } from '@jane/shared-ecomm/providers';
import { getMenuTabs } from '@jane/shared-ecomm/util';

import { MenuFeatured } from '../MenuFeatured/MenuFeatured';
import { MenuProducts } from '../MenuProducts/MenuProducts';
import { MenuSpecials } from '../MenuSpecials/MenuSpecials';

export const MenuIndex = () => {
  const { facets, store } = useMenu();
  const { specialIds } = useSpecials();

  const specialsPage =
    (store.specials_row_configs?.enabled ||
      store.custom_rows?.includes('sale')) &&
    Boolean(specialIds?.length);

  const menuTabs = getMenuTabs({
    facets,
    menuTabs: store.menu_tabs,
    specialsPage,
  });

  const category = menuTabs ? menuTabs[0] : undefined;

  if (!category || category === 'featured') return <MenuFeatured />;

  if (category === 'specials') return <MenuSpecials />;

  return <MenuProducts initialCategory={category} />;
};
