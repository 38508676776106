import { useEffect, useState } from 'react';

import { useValidatePromoCode } from '@jane/shared/data-access';
import type { Id, ReservationMode } from '@jane/shared/models';
import { Box } from '@jane/shared/reefer';

import { useCustomerDispatch } from '../../../../../customer/dispatch';
import {
  analyzeSpecialsSuccess,
  updateQueryPromoCode,
} from '../../../../../customer/redux/cart';
import { useCustomerSelector } from '../../../../../customer/selectors';
import { get } from '../../../../../redux-util/selectors';
import { SubmittableTextInput } from './SubmittableTextInput';

interface PromoCodesProps {
  headless?: boolean;
  reservationMode: ReservationMode;
  userGroupSpecialId?: Id;
}

export const PromoCodes = ({
  headless,
  reservationMode,
  userGroupSpecialId,
}: PromoCodesProps) => {
  const [promoCode, setPromoCode] = useState<string | undefined>();
  const cartState = useCustomerSelector(get('cart'));
  const dispatch = useCustomerDispatch();

  const { data: promoCodeData } = useValidatePromoCode({
    promoCode,
    reservationMode,
    userGroupSpecialId,
    uuid: cartState.cart.uuid,
    deliveryAddress: cartState.deliveryAddress,
  });

  //dispatches will be updated when cart redux store is removed
  useEffect(() => {
    if (cartState?.queryPromoCode) {
      setPromoCode(cartState.queryPromoCode);
      dispatch(updateQueryPromoCode(null));
    }
  }, []);

  useEffect(() => {
    if (promoCodeData?.validated && promoCodeData?.isValid) {
      dispatch(
        analyzeSpecialsSuccess({
          cart: promoCodeData.cart,
          userGroupSpecialId,
        })
      );
    }
  }, [promoCodeData]);

  return (
    <Box pl={headless ? 0 : 40}>
      <SubmittableTextInput
        name="promo_code"
        placeholder="Enter code here"
        submitLabel="Apply"
        onReset={() => setPromoCode(undefined)}
        onSubmit={(value) => setPromoCode(value)}
        validated={promoCodeData?.validated ?? false}
        hasValidationError={!promoCodeData?.isValid}
        validationMessage={
          promoCodeData?.isValid
            ? 'Success! Code will be applied if cart is eligible.'
            : 'Not Valid'
        }
      />
    </Box>
  );
};
