import { getJwt } from '@jane/shared/auth';
import type { Id } from '@jane/shared/models';
import type { ReservationMode } from '@jane/shared/types';

import { janeApi } from '../api/janeApi';
import { createUseQueryHook } from '../utils/createUseQueryHook';
import {
  reservationSlotsKeys,
  reservationSlotsUrls,
} from './reservationSlots.util';

interface ReservationSlot {
  display_name: string;
  from: number;
  to: number;
}

interface ReservationSlotsResponse {
  reservation_slots: ReservationSlot[];
}

export interface ReservationSlotsProps {
  lat?: number | null;
  lng?: number | null;
  reservationMode: ReservationMode;
  storeId: Id;
  zipcode?: string | null;
}

const fetchReservationSlots = async (props: ReservationSlotsProps) => {
  const url = reservationSlotsUrls.slots(props);

  return await janeApi.get<ReservationSlotsResponse>(url, getJwt());
};

const enableReservationSlotsQuery = ({
  reservationMode,
  storeId,
  lat,
  lng,
  zipcode,
}: ReservationSlotsProps) =>
  reservationMode === 'delivery'
    ? !!storeId && !!lat && !!lng && !!zipcode
    : true;

/**
 * This query returns the available reservation slots for a store and reservation mode.
 * If fetching delivery slots, lat, lng, and zipcode must be passed to the query.
 */
export const useReservationSlots = createUseQueryHook<
  ReservationSlotsProps,
  ReservationSlotsResponse
>(
  fetchReservationSlots,
  reservationSlotsKeys.slots,
  enableReservationSlotsQuery
);
