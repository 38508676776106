import isEmpty from 'lodash/isEmpty';
import type { Reducer } from 'redux';

import type { InitialQuery, InitialQueryParams } from '@jane/search/types';
import type { DeepReadonly } from '@jane/shared/models';

import {
  createSimpleAction,
  createStandardAction,
} from '../../redux-util/redux-util';
import type { CustomerAction } from './types';

export const SET_INITIAL_QUERY = 'initial-query/set-initial-query';
export const setInitialQuery =
  createStandardAction(SET_INITIAL_QUERY)<string>();

export const RESET_INITIAL_QUERY = 'initial-query/reset-initial-query';
export const resetInitialQuery = createSimpleAction(RESET_INITIAL_QUERY);

export type InitialQueryActions =
  | ReturnType<typeof resetInitialQuery>
  | ReturnType<typeof setInitialQuery>;

export type InitialQueryState = DeepReadonly<{
  initialQuery: InitialQuery;
  queryParamsPresent: boolean;
}>;

const getInitialState = (): InitialQueryState => ({
  initialQuery: { canUseSearchState: true },
  queryParamsPresent: false,
});

const PERMISSIBLE_PARAMS: InitialQueryParams[] = [
  'brand',
  'category',
  'root_types',
  'query',
  'store_type',
  'brand_subtype',
  'store_id',
];

const isPermissibleParam = (param: string): param is InitialQueryParams =>
  PERMISSIBLE_PARAMS.includes(param as any);

export const initialQueryReducer: Reducer<InitialQueryState, CustomerAction> = (
  state = getInitialState(),
  action
) => {
  switch (action.type) {
    case SET_INITIAL_QUERY: {
      const query = action.payload;
      const urlParams = new URLSearchParams(query);
      const queryObject: {
        [key in InitialQueryParams]?: {
          hard: boolean;
          value: string[];
        };
      } = {};

      urlParams.forEach((value, key) => {
        if (isPermissibleParam(key)) {
          queryObject[key] = {
            value: decodeURIComponent(value).split(','),
            hard: Boolean(
              urlParams.has('hard') && urlParams.get('hard')?.includes(key)
            ),
          };
        }
      });
      const initialQuery = { ...queryObject, canUseSearchState: false };

      return {
        ...state,
        initialQuery,
        queryParamsPresent: !isEmpty(queryObject),
      };
    }

    case RESET_INITIAL_QUERY: {
      const hardParams = [...Object.entries(state.initialQuery)]
        .filter(([, param]) => typeof param === 'object' && param.hard)
        .reduce(
          (acc, current) => ({
            ...acc,
            [current[0]]: current[1],
          }),
          {}
        );

      const initialQuery =
        Object.keys(hardParams).length > 0
          ? { ...hardParams, canUseSearchState: true }
          : { canUseSearchState: true };

      return { ...state, initialQuery };
    }
  }

  return state;
};
