import type { CSSObject } from '@emotion/react';
import { css } from '@emotion/react';

import AlphabeticalSortIcon from './icons/alphabeticalSort.svg?react';
import AngleDoubleUpIcon from './icons/angle-double-up.svg?react';
import BadgeCheckIcon from './icons/badge-check.svg?react';
import BarsIcon from './icons/bars.svg?react';
import BellSlashIcon from './icons/bell-slash.svg?react';
import BellIcon from './icons/bell.svg?react';
import BipocIcon from './icons/bipoc.svg?react';
import CalendarIcon from './icons/calendar.svg?react';
import CarIcon from './icons/car.svg?react';
import CaretRightIcon from './icons/caret-right.svg?react';
import CaretIcon from './icons/caret.svg?react';
import ChartBarIcon from './icons/chart-bar.svg?react';
import CheckIcon from './icons/check.svg?react';
import ChevronFatIcon from './icons/chevron-fat.svg?react';
import ChevronLightIcon from './icons/chevron-light.svg?react';
import CircleCheckIcon from './icons/circleCheck.svg?react';
import CircleCheckFilledIcon from './icons/circleCheckFilled.svg?react';
import ClockIcon from './icons/clock.svg?react';
import CloseIcon from './icons/close.svg?react';
import CommentAltIcon from './icons/comment-alt.svg?react';
import CopyOutlineIcon from './icons/copy-outline.svg?react';
import CopyIcon from './icons/copy.svg?react';
import CreditCardIcon from './icons/creditCard.svg?react';
import DatabaseIcon from './icons/database.svg?react';
import DollarSignRegularIcon from './icons/dollar-sign-regular.svg?react';
import DollarSignIcon from './icons/dollar-sign.svg?react';
import DollarSortIcon from './icons/dollarSort.svg?react';
import DotIcon from './icons/dot.svg?react';
import EditIcon from './icons/edit.svg?react';
import ExclamationTriangleIcon from './icons/exclamation-triangle.svg?react';
import ExternalLinkIcon from './icons/external-link.svg?react';
import FacebookIcon from './icons/facebook.svg?react';
import FilterIcon from './icons/filter.svg?react';
import FiltersIcon from './icons/filters.svg?react';
import GridViewIcon from './icons/gridView.svg?react';
import GridViewSolidIcon from './icons/gridViewSolid.svg?react';
import HandHoldingHeartIcon from './icons/hand-holding-heart.svg?react';
import HeartIcon from './icons/heart.svg?react';
import HomeIcon from './icons/home.svg?react';
import IdCardIcon from './icons/idCard.svg?react';
import InfoCircleIcon from './icons/info-circle.svg?react';
import InfoSquareIcon from './icons/info-square.svg?react';
import LeafIcon from './icons/leaf.svg?react';
import LgbtqIcon from './icons/lgbtq.svg?react';
import LocationArrowIcon from './icons/location-arrow.svg?react';
import MapMarkerAltIcon from './icons/map-marker-alt.svg?react';
import MapMarkerInvertedIcon from './icons/map-marker-inverted.svg?react';
import MinusIcon from './icons/minus.svg?react';
import MoneyIcon from './icons/money.svg?react';
import PanIcon from './icons/pan.svg?react';
import ParkingCircleIcon from './icons/parking-circle.svg?react';
import PaytenderIcon from './icons/paytender.svg?react';
import PercentageIcon from './icons/percentage.svg?react';
import PlusIcon from './icons/plus.svg?react';
import PolygonIcon from './icons/polygon.svg?react';
import PrideHeartIcon from './icons/pride-heart.svg?react';
import ProductsIcon from './icons/products.svg?react';
import AidMyFurryFriendIcon from './icons/products/aid_my_furry_friend.svg?react';
import ApplyTopicallyIcon from './icons/products/apply_topically.svg?react';
import BlissfulIcon from './icons/products/blissful.svg?react';
import CreativeIcon from './icons/products/creative.svg?react';
import EaseMyMindIcon from './icons/products/ease_my_mind.svg?react';
import EatIcon from './icons/products/eat.svg?react';
import EnergeticIcon from './icons/products/energetic.svg?react';
import GetActiveIcon from './icons/products/get_active.svg?react';
import GetIntimateIcon from './icons/products/get_intimate.svg?react';
import GetReliefIcon from './icons/products/get_relief.svg?react';
import GetSomeSleepIcon from './icons/products/get_some_sleep.svg?react';
import HangWithFriendsIcon from './icons/products/hang_with_friends.svg?react';
import HungryIcon from './icons/products/hungry.svg?react';
import NotHighIcon from './icons/products/not_high.svg?react';
import NotSureIcon from './icons/products/not_sure.svg?react';
import PainFreeIcon from './icons/products/pain_free.svg?react';
import RelaxedIcon from './icons/products/relaxed.svg?react';
import SleepyIcon from './icons/products/sleepy.svg?react';
import SmokeIcon from './icons/products/smoke.svg?react';
import StimulateMyMindIcon from './icons/products/stimulate_my_mind.svg?react';
import VapeIcon from './icons/products/vape.svg?react';
import QuantityMinusIcon from './icons/quantityMinus.svg?react';
import QuantityPlusIcon from './icons/quantityPlus.svg?react';
import SearchBoldIcon from './icons/search-bold.svg?react';
import SearchIcon from './icons/search.svg?react';
import ShieldCheckIcon from './icons/shield-check.svg?react';
import ShoppingCartSolidIcon from './icons/shopping-cart-solid.svg?react';
import ShoppingCartIcon from './icons/shopping-cart.svg?react';
import ShoppingBagIcon from './icons/shopping_bag.svg?react';
import SMSIcon from './icons/sms.svg?react';
import SortAlphaDownIcon from './icons/sort-alpha-down.svg?react';
import SpecialBannerIcon from './icons/special-banner.svg?react';
import SpinnerIcon from './icons/spinner.svg?react';
import StarHalfIcon from './icons/star-half.svg?react';
import StarIcon from './icons/star.svg?react';
import StarEmptyIcon from './icons/starEmpty.svg?react';
import StarFilledIcon from './icons/starFilled.svg?react';
import StoreAltIcon from './icons/store-alt.svg?react';
import StoreIcon from './icons/store.svg?react';
import SyncIcon from './icons/sync.svg?react';
import ThumbsUpFilledIcon from './icons/thumbs-up-filled.svg?react';
import ThumbsUpIcon from './icons/thumbs-up.svg?react';
import TimesIcon from './icons/times.svg?react';
import TimesCircleIcon from './icons/timesCircle.svg?react';
import TrackingLeafIcon from './icons/trackingLeaf.svg?react';
import TrashIcon from './icons/trash.svg?react';
import TwinkleStarIcon from './icons/twinkleStar.svg?react';
import TwitterIcon from './icons/twitter.svg?react';
import UsdSquareIcon from './icons/usd-square.svg?react';
import UsdCircleIcon from './icons/usdCircle.svg?react';
import UserCircleIcon from './icons/user-circle.svg?react';
import UserPlusIcon from './icons/user-plus.svg?react';
import VerticalDragIcon from './icons/vertical-drag.svg?react';
import VeteranIcon from './icons/veteran.svg?react';
import WarningTriangleIcon from './icons/warning-triangle.svg?react';
import WheelChairIcon from './icons/wheelchair.svg?react';
import WomanIcon from './icons/woman.svg?react';

interface Icons {
  [key: string]: any;
  aidMyFurryFriend: any;
  alphabeticalSort: any;
  angleDoubleUp: any;
  applyTopically: any;
  badgeCheck: any;
  bars: any;
  bell: any;
  bellSlash: any;
  bipoc: any;
  blissful: any;
  calendar: any;
  car: any;
  caret: any;
  caretFilled: any;
  chartBar: any;
  checkmark: any;
  chevron: any;
  chevronFat: any;
  circleCheck: any;
  circleCheckFilled: any;
  clock: any;
  close: any;
  comment: any;
  copy: any;
  creative: any;
  creditCard: any;
  database: any;
  dollarSign: any;
  dollarSignRegular: any;
  dollarSort: any;
  dot: any;
  easeMyMind: any;
  eat: any;
  edit: any;
  energetic: any;
  exclamationTriangle: any;
  externalLink: any;
  facebook: any;
  filter: any;
  filters: any;
  getActive: any;
  getIntimate: any;
  getRelief: any;
  getSomeSleep: any;
  gridView: any;
  gridViewSolid: any;
  handHoldingHeart: any;
  hangWithFriends: any;
  heart: any;
  home: any;
  hungry: any;
  iDontKnow: any;
  idCard: any;
  infoCircle: any;
  infoSquare: any;
  leaf: any;
  lgbtq: any;
  locationArrow: any;
  mapMarker: any;
  mapMarkerInverted: any;
  medStorePlus: any;
  minus: any;
  money: any;
  notHigh: any;
  painFree: any;
  pan: any;
  parkingCircle: any;
  paytender: any;
  percentage: any;
  plus: any;
  polygon: any;
  prideHeart: any;
  products: any;
  quantityMinus: any;
  quantityPlus: any;
  relaxed: any;
  search: any;
  searchBold: any;
  shieldCheck: any;
  shoppingBag: any;
  shoppingCart: any;
  shoppingCartSolid: any;
  sleepy: any;
  smoke: any;
  sms: any;
  sortAlphaDown: any;
  specialBanner: any;
  star: any;
  starEmpty: any;
  starFilled: any;
  starHalf: any;
  stimulateMyMind: any;
  store: any;
  storeAlt: any;
  sync: any;
  thumbsUp: any;
  thumbsUpFilled: any;
  times: any;
  timesCircle: any;
  trackingLeaf: any;
  trash: any;
  twinkleStar: any;
  twitter: any;
  usdCircle: any;
  usdSquare: any;
  userCircle: any;
  userPlus: any;
  vape: any;
  verticalDrag: any;
  veteran: any;
  warningTriangle: any;
  wheelchair: any;
  woman: any;
}

const icons: Icons = {
  aidMyFurryFriend: AidMyFurryFriendIcon,
  alphabeticalSort: AlphabeticalSortIcon,
  angleDoubleUp: AngleDoubleUpIcon,
  applyTopically: ApplyTopicallyIcon,
  badgeCheck: BadgeCheckIcon,
  bars: BarsIcon,
  bell: BellIcon,
  bellSlash: BellSlashIcon,
  bipoc: BipocIcon,
  blissful: BlissfulIcon,
  calendar: CalendarIcon,
  car: CarIcon,
  caret: CaretIcon,
  caretFilled: CaretRightIcon,
  chartBar: ChartBarIcon,
  checkmark: CheckIcon,
  chevron: ChevronLightIcon,
  chevronFat: ChevronFatIcon,
  circleCheck: CircleCheckIcon,
  circleCheckFilled: CircleCheckFilledIcon,
  clock: ClockIcon,
  close: CloseIcon,
  comment: CommentAltIcon,
  copy: CopyIcon,
  copyOutline: CopyOutlineIcon,
  creative: CreativeIcon,
  creditCard: CreditCardIcon,
  database: DatabaseIcon,
  dollarSign: DollarSignIcon,
  dollarSignRegular: DollarSignRegularIcon,
  dollarSort: DollarSortIcon,
  dot: DotIcon,
  easeMyMind: EaseMyMindIcon,
  eat: EatIcon,
  edit: EditIcon,
  energetic: EnergeticIcon,
  exclamationTriangle: ExclamationTriangleIcon,
  warningTriangle: WarningTriangleIcon,
  externalLink: ExternalLinkIcon,
  facebook: FacebookIcon,
  filter: FilterIcon,
  filters: FiltersIcon,
  getActive: GetActiveIcon,
  getIntimate: GetIntimateIcon,
  getRelief: GetReliefIcon,
  getSomeSleep: GetSomeSleepIcon,
  gridView: GridViewIcon,
  gridViewSolid: GridViewSolidIcon,
  handHoldingHeart: HandHoldingHeartIcon,
  hangWithFriends: HangWithFriendsIcon,
  heart: HeartIcon,
  home: HomeIcon,
  hungry: HungryIcon,
  idCard: IdCardIcon,
  infoCircle: InfoCircleIcon,
  infoSquare: InfoSquareIcon,
  leaf: LeafIcon,
  lgbtq: LgbtqIcon,
  locationArrow: LocationArrowIcon,
  mapMarker: MapMarkerAltIcon,
  mapMarkerInverted: MapMarkerInvertedIcon,
  medStorePlus: PlusIcon,
  minus: MinusIcon,
  money: MoneyIcon,
  notHigh: NotHighIcon,
  pan: PanIcon,
  polygon: PolygonIcon,
  iDontKnow: NotSureIcon,
  painFree: PainFreeIcon,
  percentage: PercentageIcon,
  parkingCircle: ParkingCircleIcon,
  paytender: PaytenderIcon,
  plus: PlusIcon,
  products: ProductsIcon,
  prideHeart: PrideHeartIcon,
  quantityMinus: QuantityMinusIcon,
  quantityPlus: QuantityPlusIcon,
  relaxed: RelaxedIcon,
  search: SearchIcon,
  searchBold: SearchBoldIcon,
  shieldCheck: ShieldCheckIcon,
  shoppingBag: ShoppingBagIcon,
  shoppingCart: ShoppingCartIcon,
  shoppingCartSolid: ShoppingCartSolidIcon,
  sleepy: SleepyIcon,
  sms: SMSIcon,
  smoke: SmokeIcon,
  sortAlphaDown: SortAlphaDownIcon,
  specialBanner: SpecialBannerIcon,
  spinner: SpinnerIcon,
  star: StarIcon,
  starEmpty: StarEmptyIcon,
  starFilled: StarFilledIcon,
  starHalf: StarHalfIcon,
  stimulateMyMind: StimulateMyMindIcon,
  store: StoreIcon,
  storeAlt: StoreAltIcon,
  sync: SyncIcon,
  thumbsUp: ThumbsUpIcon,
  thumbsUpFilled: ThumbsUpFilledIcon,
  times: TimesIcon,
  timesCircle: TimesCircleIcon,
  trackingLeaf: TrackingLeafIcon,
  trash: TrashIcon,
  twinkleStar: TwinkleStarIcon,
  twitter: TwitterIcon,
  usdCircle: UsdCircleIcon,
  usdSquare: UsdSquareIcon,
  userCircle: UserCircleIcon,
  userPlus: UserPlusIcon,
  vape: VapeIcon,
  verticalDrag: VerticalDragIcon,
  veteran: VeteranIcon,
  woman: WomanIcon,
  wheelchair: WheelChairIcon,
};

type Size = number | { height?: number; width?: number };

interface NudgeProps {
  down?: number;
  left?: number;
  right?: number;
  up?: number;
}

interface Props {
  ariaHidden?: boolean;
  ariaLabel?: string;
  color?: string;
  dim?: boolean;
  display?: string;
  hidden?: boolean;
  icon: keyof Icons;
  nudge?: NudgeProps;
  role?: string;
  rotate?: 'left' | 'right' | '180';
  scale?: number;
  size?: Size;
  styles?: CSSObject;
}

const transform = (
  rotate: Props['rotate'],
  scale: Props['scale'],
  nudge: Props['nudge']
) => {
  const transformValues = [];

  switch (rotate) {
    case 'left':
      transformValues.push('rotate(-90deg)');
      break;
    case 'right':
      transformValues.push('rotate(90deg)');
      break;
    case '180':
      transformValues.push('rotate(-180deg)');
      break;
  }

  if (scale) transformValues.push(`scale(${scale})`);
  if (nudge) {
    const nudgeX = nudge.right
      ? `${nudge.right}px`
      : nudge.left
      ? `-${nudge.left}px`
      : 0;
    const nudgeY = nudge.down
      ? `${nudge.down}px`
      : nudge.up
      ? `-${nudge.up}px`
      : 0;
    transformValues.push(`translate(${nudgeX}, ${nudgeY})`);
  }

  if (transformValues.length === 0) return undefined;
  return transformValues.join(' ');
};

export function SvgIcon({
  color,
  dim = !color,
  icon,
  nudge,
  rotate = undefined,
  size = 18,
  scale,
  hidden,
  display,
  ariaHidden,
  ariaLabel,
  role,
  styles,
}: Props) {
  const Icon = icons[icon];
  if (!Icon) {
    return null;
  }

  return (
    <Icon
      css={css(
        typeof size === 'number' ? { height: size, width: size } : size,
        {
          fill: color || 'currentColor',
          transform: transform(rotate, scale, nudge),
          visibility: hidden ? 'hidden' : undefined,
          opacity: dim ? 0.9 : 1.0,
          display,
          ...styles,
        }
      )}
      aria-hidden={ariaHidden}
      aria-label={ariaLabel}
      role={role}
    />
  );
}
