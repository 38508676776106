import type { AppMode } from '@jane/shared/types';

// Please inform the Ads team when updating this list. Coverage in placement.rb needs to be updated.
const EMBEDDED_APPS = [
  'bloom',
  'embedded',
  'whiteLabel',
  'brandEmbed',
  'operatorEmbed',
  'headless',
  'framelessEmbed',
];

export const isEmbeddedMode = (appMode: AppMode) => {
  return EMBEDDED_APPS.includes(appMode);
};
