import type { AlgoliaProduct } from '@jane/search/types';
import type { MenuProduct, Store } from '@jane/shared/models';
import type {
  PriceId,
  ProductWeightSpecial,
  MenuProduct as zMenuProduct,
  Store as zStore,
} from '@jane/shared/types';

const WEIGHT_LABELS: { [key in PriceId]: string } = {
  each: 'Each',
  half_gram: '1/2 G',
  gram: '1 G',
  two_gram: '2 G',
  eighth_ounce: '1/8 OZ',
  quarter_ounce: '1/4 OZ',
  half_ounce: '1/2 OZ',
  ounce: '1 OZ',
};

const formatDiscountAmount = (
  discount: ProductWeightSpecial,
  priceId: PriceId
) => {
  const {
    discount_type,
    discount_amount,
    discount_percent,
    discount_price,
    price,
  } = discount;

  const formatPrice = (price = '0') => {
    const floatPrice = parseFloat(price);
    return floatPrice % 1 === 0 ? floatPrice.toFixed(0) : floatPrice.toFixed(2);
  };

  switch (discount_type) {
    case 'percent':
      return discount_percent && parseFloat(discount_percent) !== 0
        ? `${parseFloat(discount_percent).toString()}% OFF ${
            WEIGHT_LABELS[priceId]
          }`
        : 'SALE';
    case 'dollar_amount':
      return discount_amount && parseFloat(discount_amount) !== 0
        ? `$${formatPrice(discount_amount)} OFF ${WEIGHT_LABELS[priceId]}`
        : 'SALE';
    case 'target_price':
      return discount_price && parseFloat(discount_price) !== price
        ? `$${formatPrice(discount_price)} ${WEIGHT_LABELS[priceId]}`
        : 'SALE';
    default:
      return 'SALE';
  }
};

interface GetSpecialTagLabel {
  defaultWeight?: PriceId | null;
  product: MenuProduct | zMenuProduct | AlgoliaProduct;
  shortenLabel?: boolean;
  store: Store | zStore;
  useCustomBadge?: boolean;
}

export const getSpecialTagLabel = ({
  defaultWeight,
  product,
  shortenLabel,
  store,
  useCustomBadge,
}: GetSpecialTagLabel) => {
  const typedProduct = product as MenuProduct;

  const defaultWeightSpecial =
    defaultWeight && typedProduct[`special_price_${defaultWeight}`];

  const weightSpecialMatchesProductSpecial =
    defaultWeightSpecial?.special_id === product.special_id;

  const weightSpecialAmount =
    defaultWeightSpecial &&
    formatDiscountAmount(defaultWeightSpecial, defaultWeight);

  /** Priority for badge:
    1. If FF is on, and default weight special, use defaultWeight custom badge
    2. If FF is on, use product.special_custom_badge
    3. If default weight special, use weightSpecialAmount
    4. Default to product.special_amount
  */
  const specialAmount =
    useCustomBadge &&
    defaultWeightSpecial &&
    defaultWeightSpecial.special_custom_badge
      ? defaultWeightSpecial.special_custom_badge
      : useCustomBadge && product?.special_custom_badge
      ? product.special_custom_badge
      : defaultWeightSpecial && !weightSpecialMatchesProductSpecial
      ? weightSpecialAmount
      : product?.special_amount;

  const complianceLabel =
    store?.store_compliance_language_settings?.['specials_badge'];

  const specialLabel =
    specialAmount && specialAmount !== 'sale' ? specialAmount : 'SALE';

  const useComplianceLanguage =
    specialLabel === 'SALE' ||
    (shortenLabel && specialLabel.split(' ').length > 2);

  const labelToRender =
    complianceLabel && useComplianceLanguage ? complianceLabel : specialLabel;

  return shortenLabel && labelToRender.length > 7
    ? `${labelToRender.substring(0, 7)}...`
    : labelToRender;
};
