import { useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { useIframeScroll, useIsInIframe } from '@jane/shared-ecomm/hooks';
import type { CalloutVariants } from '@jane/shared-ecomm/types';
import type { AeropayBankAccount } from '@jane/shared/models';
import { useEcommApp } from '@jane/shared/providers';
import { Box, Loading, Modal } from '@jane/shared/reefer';

import {
  AeropayScreen,
  WIDGET_CONTAINER_ID,
} from './aeropayScreen/aeropayScreen';
import { aerosyncScript } from './aeropayScreen/aerosyncScript';
import { useAerosync } from './aeropayScreen/useAerosync';
import { AlreadyEnrolledScreen } from './alreadyEnrolledScreen/alreadyEnrolledScreen';
import { DeleteBankAccountScreen } from './deleteBankAccountScreen/deleteBankAccountScreen';
import { FailureScreen } from './failureScreen/failureScreen';
import { JaneGoldPromptScreen } from './janeGoldPromptScreen/janeGoldPromptScreen';
import { LinkBankScreen } from './linkBankScreen/linkBankScreen';
import { MoreInfoScreen } from './moreInfoScreen/moreInfoScreen';
import { NewAccountLinkedScreen } from './newAccountLinkedScreen/newAccountLinkedScreen';
import { SuccessScreen } from './successScreen/successScreen';
import {
  SCREEN_NAMES,
  useBankLinkingModalData,
} from './useBankLinkingModalData';
import { WelcomeBackScreen } from './welcomeBackScreen/welcomeBackScreen';

export interface BankLinkingModalProps {
  /** Flag is true when user is adding an additional bank account */
  addNewAccount?: boolean;

  /** Bank account selected for deletion */
  deleteBankAccount?: AeropayBankAccount | null;

  /** When true, hides Jane Pay logos and branding. */
  hideJanePayBranding?: boolean;

  /** Optional boolean to specify when we're targeting Jane Pay over Jane Gold */
  isJanePay?: boolean;

  /** Flag used for displaying a different message in Delete Bank Account modal */
  isLastBankAccount?: boolean;

  /** Location where the modal was opened, used for tracking */
  location?: CalloutVariants;

  /** Function called when modal is closed */
  onRequestClose(): void;

  /** Boolean for whether or not the modal is open */
  open: boolean;

  /** Flag is true when modal should start with JaneGoldPromptScreen */
  showPrompt?: boolean;

  /** For white labelling Jane Pay */
  storePhoto?: string;
}

export const BankLinkingModal = ({
  addNewAccount = false,
  deleteBankAccount = null,
  hideJanePayBranding = false,
  isJanePay = false,
  isLastBankAccount = false,
  location,
  onRequestClose,
  open,
  showPrompt = false,
  storePhoto,
}: BankLinkingModalProps) => {
  const [error, setError] = useState<unknown | null>(null);
  const isInIframe = useIsInIframe();
  const { appMode } = useEcommApp();

  const {
    handleClose,
    handleCloseWithOptOut,
    handleDeleteBankAccount,
    handleSubmitInfo,
    needsMoreFlag,
    screen,
    setScreen,
  } = useBankLinkingModalData({
    addNewAccount,
    deleteBankAccount,
    isJanePay,
    onRequestClose,
    location,
    showPrompt,
    setError,
  });

  const handleError = (err: unknown) => {
    setError(err);
    setScreen(SCREEN_NAMES.error);
  };

  const {
    isLoading: isLoadingWidget,
    linkingBankAccount,
    widgetRef,
  } = useAerosync({
    onClose: handleClose,
    onError: handleError,
    onSuccess: () => {
      setScreen(
        addNewAccount ? SCREEN_NAMES.newAccountSuccess : SCREEN_NAMES.success
      );
    },
    widgetContainerId: WIDGET_CONTAINER_ID,
  });

  function renderSwitch() {
    const {
      aeropay,
      alreadyEnrolled,
      deleteBankAccountScreen,
      error: errorScreen,
      linkBank,
      loading,
      moreInfo,
      moreInfoAeropay,
      newAccountSuccess,
      promptLinkBank,
      success,
      welcomeBack,
    } = SCREEN_NAMES;

    switch (screen) {
      case loading:
        return (
          <Box py={48} position="relative">
            <Loading color="black" size="lg" />
          </Box>
        );
      case welcomeBack:
        return <WelcomeBackScreen onDoneClick={handleClose} />;
      case alreadyEnrolled:
        return (
          <AlreadyEnrolledScreen
            hideJanePayBranding={hideJanePayBranding}
            isJanePay={isJanePay}
            onDoneClick={handleClose}
            storePhoto={storePhoto}
          />
        );
      case moreInfo:
      case moreInfoAeropay:
        return (
          <MoreInfoScreen
            onDoneClick={handleClose}
            onSubmit={handleSubmitInfo}
          />
        );
      case promptLinkBank:
        return (
          <JaneGoldPromptScreen
            hideJanePayBranding={hideJanePayBranding}
            onContinue={() =>
              needsMoreFlag ? setScreen(moreInfoAeropay) : setScreen(linkBank)
            }
            onRequestClose={handleClose}
          />
        );
      case linkBank:
        return (
          <LinkBankScreen
            hideJanePayBranding={hideJanePayBranding}
            isJanePay={isJanePay}
            onContinueClick={() => {
              setScreen(aeropay);
            }}
            onDoneClick={handleClose}
          />
        );
      case deleteBankAccountScreen:
        return (
          <DeleteBankAccountScreen
            lastBankAccount={isLastBankAccount}
            onRemoveClick={handleDeleteBankAccount}
            onCancelClick={handleClose}
          />
        );
      case aeropay:
        return (
          <AeropayScreen
            isLinkingBankAccount={linkingBankAccount}
            isLoadingWidget={isLoadingWidget}
            onCancel={handleClose}
            widgetRef={widgetRef}
          />
        );
      case success:
        return (
          <SuccessScreen
            hideJanePayBranding={hideJanePayBranding}
            storePhoto={storePhoto}
            isJanePay={isJanePay}
            isMarketplace={appMode === 'default'}
            location={location}
            onDoneClick={handleCloseWithOptOut}
          />
        );
      case newAccountSuccess:
        return <NewAccountLinkedScreen onDoneClick={handleClose} />;
      case errorScreen:
        return (
          <FailureScreen
            error={error}
            onDoneClick={handleClose}
            onTryAgainClick={() => setScreen(aeropay)}
          />
        );
    }
    return;
  }

  const useScroll =
    isInIframe &&
    open &&
    (location === 'cart' || location === 'checkout' || location === 'activity');

  useIframeScroll(useScroll);

  return (
    <>
      <Helmet>
        <script type="text/javascript">{aerosyncScript}</script>
      </Helmet>
      <Modal
        variant="dialogue"
        open={open}
        onRequestClose={onRequestClose}
        overlayClose
        topOverride={isInIframe ? '160px' : undefined}
      >
        <Modal.Content>
          <Box maxWidth={screen === SCREEN_NAMES.aeropay ? 'auto' : '279px'}>
            {renderSwitch()}
          </Box>
        </Modal.Content>
      </Modal>
    </>
  );
};
