import type { SVGProps } from 'react';

import { Icon } from '../../icon/icon';
import type { LogoProps } from '../logo.types';

const AeropaySecondary = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 71 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.1692 12.9092V12.5456L22.3964 12.2274L22.4419 12.091C22.351 12.1819 22.2146 12.3183 22.1237 12.4092C21.5783 12.8183 20.851 13.0456 19.9874 13.0456C19.4875 13.0456 18.9875 12.9546 18.5329 12.7274C18.0784 12.5001 17.6239 12.2729 17.3057 11.9092C16.9421 11.5456 16.6694 11.1365 16.4876 10.682C16.3058 10.2275 16.2148 9.72749 16.2148 9.22751C16.2148 8.72752 16.3058 8.22754 16.4876 7.72756C16.6694 7.22758 16.9875 6.8185 17.3512 6.45488C17.6693 6.09126 18.1239 5.77308 18.5784 5.59127C19.0329 5.40946 19.5329 5.2731 20.0329 5.31856C20.9419 5.31856 21.6692 5.54582 22.2146 6.00035C22.3055 6.09126 22.4419 6.18216 22.4873 6.22761L22.4419 6.13671L22.2146 5.81854V5.36401H24.26V12.9092H22.1692ZM20.1238 7.13667C19.8511 7.13667 19.5784 7.18213 19.3511 7.27303C19.1238 7.36394 18.8966 7.54575 18.7148 7.72756C18.3511 8.13664 18.1693 8.63662 18.1693 9.18205C18.1693 9.72749 18.3966 10.2275 18.7148 10.6365C18.8966 10.8184 19.1238 10.9547 19.3511 11.0911C19.5784 11.182 19.8511 11.2274 20.1238 11.2274C20.3965 11.2274 20.6692 11.182 20.8965 11.0911C21.1238 11.0002 21.3965 10.8638 21.5783 10.6365C21.7601 10.4547 21.8965 10.2275 22.0328 9.95475C22.1237 9.72749 22.1692 9.45477 22.1692 9.18205C22.1692 8.90934 22.1237 8.63662 22.0328 8.40935C21.9419 8.18209 21.8056 7.95482 21.5783 7.72756C21.3965 7.54575 21.1692 7.40939 20.8965 7.27303C20.6692 7.18213 20.3965 7.13667 20.1238 7.13667Z"
      fill="black"
    />
    <path
      d="M32.4866 11.2729C31.7139 12.4547 30.4867 13.0455 28.8958 13.0455C27.7141 13.0455 26.7596 12.6819 25.9869 11.9092C25.2596 11.1365 24.896 10.1366 24.896 9.09114C24.896 8.04572 25.3051 7.04576 26.0323 6.27306C26.805 5.50036 27.7141 5.13673 28.8958 5.13673C30.0776 5.13673 30.9867 5.50036 31.7139 6.2276C32.3957 6.95485 32.7593 7.86391 32.7593 9.00023C32.7593 9.09114 32.7593 9.18205 32.7593 9.3184C32.7593 9.45476 32.7593 9.54567 32.7593 9.59112C32.7593 9.63657 32.7593 9.72748 32.7593 9.72748H26.8959C26.9868 10.182 27.2141 10.5911 27.5777 10.8638C27.9413 11.1365 28.3959 11.3183 28.8958 11.2729C29.3049 11.2729 29.6685 11.182 30.0322 11.0456C30.3958 10.8638 30.6685 10.5911 30.8958 10.2729L32.4866 11.2729ZM28.8958 6.9094C27.8959 6.9094 27.305 7.36393 26.9868 8.27299H30.6685C30.5776 7.86391 30.3503 7.50029 30.0322 7.27302C29.714 7.0003 29.3049 6.86395 28.8958 6.9094Z"
      fill="black"
    />
    <path
      d="M35.5322 12.9092H33.4868V5.364H35.5322V6.18215L35.4413 6.54577C35.4413 6.54577 35.5322 6.36396 35.5777 6.36396C35.8049 6.00034 36.1231 5.72762 36.5322 5.54581C36.8958 5.364 37.3503 5.22764 37.7594 5.27309V7.31847C37.0321 7.31847 36.4867 7.50029 36.1231 7.86391C35.7595 8.22753 35.5777 8.77297 35.5777 9.45476L35.5322 12.9092Z"
      fill="black"
    />
    <path
      d="M41.8046 5.22764C42.35 5.22764 42.8955 5.31855 43.3954 5.50036C43.8954 5.68217 44.35 6.00034 44.759 6.36396C45.1227 6.72759 45.4408 7.18212 45.6226 7.63665C45.8044 8.09118 45.9408 8.63661 45.9408 9.13659C45.9408 9.68203 45.8499 10.182 45.6226 10.6365C45.3954 11.0911 45.1227 11.5456 44.759 11.9092C43.9863 12.6365 42.9409 13.0455 41.85 13.0455C40.7592 13.0455 39.7138 12.6365 38.9411 11.9092C38.5774 11.5456 38.2593 11.0911 38.0775 10.6365C37.8502 10.182 37.7593 9.63657 37.7593 9.13659C37.7593 8.59116 37.8502 8.09118 38.0775 7.63665C38.3047 7.18212 38.5774 6.72759 38.9411 6.36396C39.3047 6.00034 39.7592 5.68217 40.2592 5.50036C40.7592 5.27309 41.3046 5.18219 41.8046 5.22764ZM43.3045 7.6821C42.9409 7.31848 42.3955 7.09121 41.85 7.09121C41.3046 7.09121 40.8046 7.31848 40.3955 7.6821C40.2137 7.86391 40.0774 8.13663 39.941 8.36389C39.8501 8.63661 39.8047 8.90933 39.8047 9.18205C39.8047 9.45476 39.8501 9.72748 39.941 10.0002C40.0319 10.2729 40.1683 10.5002 40.3955 10.682C40.5774 10.8638 40.8046 11.0002 41.0319 11.1365C41.2592 11.2274 41.5319 11.2729 41.8046 11.2729C42.0773 11.2729 42.35 11.2274 42.5773 11.1365C42.8046 11.0456 43.0773 10.8638 43.2591 10.682C43.6227 10.2729 43.85 9.72748 43.85 9.18205C43.85 8.59116 43.6682 8.09118 43.3045 7.6821Z"
      fill="black"
    />
    <path
      d="M50.6229 13.0455C50.1229 13.0455 49.6229 12.9546 49.1684 12.7728C48.7138 12.591 48.3048 12.3183 47.9411 11.9547L47.8048 11.7729L47.9411 12.1365V15.5H46.6685V5.50036L47.9866 5.364V6.36396L47.8957 6.68213L47.9866 6.50032C48.6684 5.77307 49.532 5.364 50.6683 5.364C51.1683 5.364 51.6683 5.4549 52.1228 5.63672C52.5773 5.81853 52.9864 6.09125 53.35 6.45487C54.0773 7.18212 54.4864 8.13663 54.4864 9.18204C54.4864 10.2275 54.0773 11.182 53.35 11.9092C53.0319 12.2728 52.5773 12.5456 52.1228 12.7274C51.6228 12.9546 51.1228 13.0455 50.6229 13.0455ZM50.5774 6.54577C49.8956 6.54577 49.2138 6.81849 48.7138 7.31847C48.2139 7.81846 47.9411 8.50025 47.9411 9.18204C47.9411 9.86384 48.2139 10.5456 48.7138 11.0456C49.2138 11.5456 49.8956 11.8183 50.5774 11.8183C50.8956 11.8183 51.2592 11.7729 51.5774 11.6365C51.8955 11.5001 52.1683 11.3183 52.441 11.0456C52.8955 10.5456 53.1682 9.86384 53.1682 9.18204C53.1682 8.50025 52.8955 7.81846 52.441 7.31847C52.2137 7.09121 51.8955 6.86395 51.5774 6.72759C51.2592 6.63668 50.8956 6.54577 50.5774 6.54577Z"
      fill="black"
    />
    <path
      d="M61.5769 12.9092V11.9092C60.8951 12.6819 59.9861 13.0456 58.8952 13.0456C58.3952 13.0456 57.8952 12.9546 57.4407 12.7728C56.9862 12.591 56.5771 12.3183 56.2135 11.9547C55.4862 11.2274 55.0771 10.2729 55.0771 9.22751C55.0771 8.18209 55.4862 7.22758 56.2135 6.50033C56.5316 6.13671 56.9862 5.86399 57.4407 5.68218C57.8952 5.50037 58.3952 5.40946 58.8952 5.40946C59.3952 5.40946 59.8952 5.50037 60.3497 5.68218C60.8042 5.86399 61.2587 6.13671 61.5769 6.50033V5.45492H62.8496V12.9092H61.5769ZM58.9861 6.54579C58.6679 6.54579 58.3043 6.59124 57.9861 6.7276C57.668 6.86396 57.3952 7.04577 57.1225 7.31849C56.668 7.81847 56.3953 8.50026 56.3953 9.18206C56.3953 9.86385 56.668 10.5456 57.1225 11.0456C57.3498 11.2729 57.668 11.5002 57.9861 11.6365C58.3043 11.7729 58.6679 11.8183 58.9861 11.8183C59.6679 11.8183 60.3497 11.5456 60.8497 11.0456C61.3497 10.5456 61.6224 9.86385 61.6224 9.18206C61.6224 8.50026 61.3497 7.81847 60.8497 7.31849C60.3497 6.86396 59.6679 6.54579 58.9861 6.54579Z"
      fill="black"
    />
    <path
      d="M70.531 5.45491L67.8947 12.7274C67.5311 13.7273 67.122 14.4091 66.5766 14.8637C66.0312 15.3182 65.3494 15.5 64.5312 15.5H64.213V14.3182H64.5312C65.4403 14.3182 66.1221 13.8182 66.5311 12.8183L63.5312 5.50036H64.9857L67.1675 11.1365L69.1674 5.45491H70.531Z"
      fill="black"
    />
    <path
      d="M4.0337 7.13656C4.48822 6.90929 12.0789 2.63672 12.2607 2.54581C12.3061 2.50036 12.397 2.4549 12.4879 2.4549C12.5788 2.40945 12.6697 2.4549 12.7152 2.4549C12.7607 2.50036 12.8061 2.59126 12.7607 2.68217C12.7152 2.72762 12.6697 2.81853 12.6243 2.86398C12.5334 2.90943 4.53367 7.90926 4.2155 8.13652C3.98824 8.27288 3.76097 8.45469 3.76097 8.72741C3.76097 8.86377 3.80643 9.00013 3.89734 9.09103C3.98824 9.18194 4.1246 9.22739 4.26096 9.22739C4.39732 9.22739 4.53367 9.22739 4.67003 9.22739C5.07911 9.22739 5.48819 9.36375 5.80636 9.63647C6.16998 9.95464 6.48815 10.3183 6.71542 10.7728C6.85177 11.0001 7.12449 11.4546 7.44267 11.4546H7.53357C7.80629 11.4546 8.079 11.2273 8.21536 10.9546C8.98806 9.95464 15.3969 1.36403 15.6696 0.954957C15.7605 0.818598 15.7605 0.500428 15.4878 0.500428C15.306 0.454975 3.17009 4.04576 2.62465 4.22757L1.98831 4.45483C1.94286 4.45483 1.8974 4.50029 1.8065 4.50029L1.62469 4.54574C1.53378 4.59119 1.39743 4.59119 1.30652 4.63664C1.07925 4.72755 0.806537 4.81846 0.624726 4.90936C0.579273 4.95481 0.488363 5.00027 0.44291 5.04572C0.397457 5.09117 0.397461 5.18208 0.397461 5.27299C0.397461 5.4548 0.488363 5.59116 0.670174 5.63661C0.897439 5.72751 1.1247 5.81842 1.35197 5.90933C2.03376 6.13659 2.5792 6.54567 3.03373 7.0911C3.12464 7.18201 3.17009 7.31837 3.3519 7.31837C3.44281 7.31837 3.57916 7.31837 3.67007 7.27291L4.0337 7.13656Z"
      fill="#24C3D2"
    />
  </svg>
);
export const AeropayLogoSecondary = ({
  height = 48,
  width = 'auto',
  ...props
}: LogoProps) => {
  return (
    <Icon
      height={height}
      width={width}
      {...props}
      icon={AeropaySecondary}
      color={undefined}
      rotate={undefined}
      size={undefined}
    />
  );
};
