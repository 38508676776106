import {
  ArrowLeftIcon,
  Button,
  Flex,
  Typography,
  emailRegex,
} from '@jane/shared/reefer';
import { Form } from '@jane/shared/reefer-hook-form';

interface ForgotPasswordProps {
  error: string | null;

  /** Function called when back arrow is clicked */
  onBackClick(): void;

  /** Function called when cancel button is clicked */
  onDoneClick(): void;

  /** Function called when submit button is clicked */
  onSubmit(data: EmailFormData): void;
}

export type EmailFormData = {
  email: string;
};

export const ForgotPassword = ({
  error,
  onBackClick,
  onDoneClick,
  onSubmit,
}: ForgotPasswordProps) => {
  return (
    <Form name="forgot-password" onSubmit={onSubmit}>
      <Flex width="100%" alignItems="left">
        <ArrowLeftIcon
          size="md"
          mb={24}
          altText="back arrow"
          onClick={onBackClick}
          data-testid="left-arrow"
        />
      </Flex>
      <Flex width="100%" flexDirection="column" alignItems="center">
        <Typography branded variant="header-bold" textAlign="center" mb={8}>
          Reset your password
        </Typography>
        <Typography textAlign="center">
          Enter your email address below and we’ll send you an email with
          instructions for creating a new password.
        </Typography>
        <Form.TextField
          type="email"
          autocomplete="off"
          label="Email"
          name="email"
          required
          errorMessage="Please enter a valid email address."
          errorOnBlur
          disableMobileInputStyling
          pattern={emailRegex}
          my={24}
        />
        {error && (
          <Typography color="error" mt={-12} mb={12}>
            {typeof error === 'string'
              ? error
              : 'There was an error trying to initiate the reset.'}
          </Typography>
        )}

        <Form.SubmitButton full variant="primary" label="Submit" mb={16} />
        <Button full variant="secondary" label="Cancel" onClick={onDoneClick} />
      </Flex>
    </Form>
  );
};
