import type { AlgoliaProduct } from '@jane/search/types';
import type { MenuProduct, PriceId } from '@jane/shared/models';

import { maybeParseFloat } from './pricing';

export function parsePriceAtPriceId(
  menuProduct: MenuProduct | AlgoliaProduct,
  priceId: PriceId,
  discounted = false
) {
  const pricePrefix = discounted ? 'discounted_price_' : 'price_';
  const price = menuProduct[`${pricePrefix}${priceId}`];
  return maybeParseFloat(price ?? 0) as number;
}

export function lowestPriceForMenuProduct(
  menuProduct: MenuProduct | AlgoliaProduct,
  availableWeights: PriceId[]
) {
  let lowestPriceId = availableWeights[0];
  let lowestMenuProductPrice = parsePriceAtPriceId(
    menuProduct,
    availableWeights[0]
  );

  availableWeights.forEach((priceId) => {
    const pricesAtWeight = [
      parsePriceAtPriceId(menuProduct, priceId),
      parsePriceAtPriceId(menuProduct, priceId, true),
    ].filter(Boolean);
    const lowestPriceAtWeight = Math.min(...pricesAtWeight);

    if (lowestPriceAtWeight < lowestMenuProductPrice) {
      lowestPriceId = priceId;
      lowestMenuProductPrice = lowestPriceAtWeight;
    }
  });

  return lowestPriceId;
}
