import type {
  AlgoliaProduct,
  JaneSearchState,
  SearchResponse,
} from '@jane/search/types';
import { useSearchTrackingContext } from '@jane/shared-ecomm/providers';

import type {
  SearchedDispensaries,
  SearchedMenuProducts,
  SearchedProducts,
  SharedSearchProperties,
  TrackedEvent,
} from '../eventNames';
import { useTrack } from '../useTrack';
import {
  getFilters,
  getPriceRange,
  getRating,
  getSort,
} from './trackSearchUtils';

const isValidUrl = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (err) {
    return false;
  }
};

interface TrackSearchProps {
  dispensarySearch?: boolean;
  event:
    | Omit<SearchedMenuProducts, keyof SharedSearchProperties>
    | Omit<SearchedProducts, keyof SharedSearchProperties>
    | Omit<SearchedDispensaries, keyof SharedSearchProperties>;
  // phasing out passing the entire searchResults object
  indexName?: string;
  menuUrl?: string;
  numHits?: number;
  preventTracking?: boolean;
  searchResults?: SearchResponse;
  searchState: JaneSearchState<AlgoliaProduct>;
  searching: boolean;
}

export const useTrackSearch = ({
  dispensarySearch,
  event,
  indexName,
  numHits,
  preventTracking = false,
  searchResults,
  searchState,
  searching,
}: TrackSearchProps) => {
  const { preventTracking: preventSearchTracking } = useSearchTrackingContext();

  let trackedEvent: TrackedEvent | null = null;

  let menuUrl: string | undefined = undefined;

  const hasResults = searchResults || (indexName && numHits);

  const doNotTrack = preventTracking || preventSearchTracking;

  const pageUrl =
    window.location !== window.parent.location
      ? document.referrer
      : document.location.href.replace(window.location.search, '');

  const validUrl = isValidUrl(pageUrl);

  if (validUrl && event.event === 'Searched Menu Products') {
    const url = new URL(pageUrl);
    menuUrl = url.pathname.includes('/menu')
      ? url.origin + url.pathname.split('/menu')[0] + '/menu'
      : url.href;
  }

  if (!searching && !doNotTrack && hasResults) {
    const filters = getFilters(searchState);

    trackedEvent = {
      ...event,
      menuUrl,
      indexName: searchResults?.index || indexName || '',
      filterCount: filters.length,
      filters,
      hits: searchResults?.nbHits || numHits || 0,
      query: searchState?.searchText || undefined,
      ...(!dispensarySearch && {
        aggregateRatingMin: getRating(searchState?.bucketFilters),
        priceRange: getPriceRange(searchState?.bucketFilters),
        sort: getSort(searchState?.currentSort),
      }),
    };
  }

  useTrack(trackedEvent);
};
