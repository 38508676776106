import type { Store } from '@jane/shared/models';
import type { StoreHours as StoreHoursType } from '@jane/shared/types';

import { formatCurrentTime, formatTime } from './timeUtils/formatTime';

type Schedule = StoreHoursType[] | null;

export const getTodaysScheduleHours = (
  timezone: Store['time_zone_identifier'],
  schedule?: Schedule,
  closeOnly?: boolean
): null | string => {
  if (!schedule || !timezone) return null;

  const today = formatCurrentTime('dddd', timezone).toLowerCase();

  const todaysHours = schedule.find(({ day }) => day.toLowerCase() === today);
  const open = todaysHours?.period.from;
  const close = todaysHours?.period.to;

  const startTime = open && formatTime(open, 'h:mm a', 'hh:mm');
  const endTime = close && formatTime(close, 'h:mm a', 'hh:mm');

  if (!startTime || !endTime) return null;
  if (endTime && closeOnly) return `${endTime}`;

  return `${startTime} - ${endTime}`;
};

export const getAllTodaysScheduleHours = (
  timezone: Store['time_zone_identifier'],
  schedule: {
    curbside: Schedule;
    delivery: Schedule;
    pickup: Schedule;
  }
) => ({
  pickupHours: getTodaysScheduleHours(timezone, schedule.pickup),
  curbsideHours: getTodaysScheduleHours(timezone, schedule.curbside),
  deliveryHours: getTodaysScheduleHours(timezone, schedule.delivery),
});
