import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { useCheckout } from '@jane/shared-ecomm/providers';
import { OptInDescription } from '@jane/shared/components';
import {
  Box,
  Button,
  Flex,
  Grid,
  Link,
  Typography,
  useDesktopMediaQuery,
} from '@jane/shared/reefer';
import { Form } from '@jane/shared/reefer-hook-form';

import { CheckoutAccordionItem } from '../CheckoutAccordion/CheckoutAccordionItem';
import { CheckoutStoreCard } from '../CheckoutStoreCard/CheckoutStoreCard';
import { StyledGridContainer } from '../checkout.styles';
import { useFulfillmentSchedule } from '../util/hooks/useFulfillmentSchedule';
import { useReservationModeLabels } from '../util/hooks/useReservationModeLabels';
import { AgreementLabel } from './AgreementLabel';
import { PickupSummary } from './PickupSummary';
import { useCheckoutOptionsForm } from './useCheckoutOptionsForm';

export const CheckoutPickupOptions = () => {
  const isDesktop = useDesktopMediaQuery({ size: 'md', width: 'min' });

  const { consentItems, consentsValid, handleContinue, isValid } =
    useCheckoutOptionsForm();
  const {
    appInfo: { appMode, bloomUserExperience },
    cart: { reservation_mode },
    store: { curbside_pickup_instructions },
  } = useCheckout();
  const { curbsideLabel, pickupLabel } = useReservationModeLabels();
  const {
    curbsideSlots,
    pickupSlots,
    isLoadingPickup,
    isAcceptingCurbside,
    isAcceptingPickup,
  } = useFulfillmentSchedule();

  const { control, trigger, getValues, setValue } = useFormContext();

  const formPickupMode = useWatch({
    name: 'pickupOptions.pickupMode',
    control,
  });

  const shouldValidate = reservation_mode === 'pickup';

  const fulfillmentSlots =
    formPickupMode === 'pickup' ? pickupSlots : curbsideSlots;

  const handleChangeMode = (mode: 'pickup' | 'curbside') => {
    const selectedTime = getValues('pickupOptions.fulfillmentTime');
    const fulfillmentSlots = mode === 'pickup' ? pickupSlots : curbsideSlots;

    // If a similar time slot exists, we'll keep it, otherwise, select the first slot.
    const formSelectedSlot =
      selectedTime &&
      fulfillmentSlots.find(
        (slot) => slot.from === parseInt(selectedTime.split('>')[0])
      );

    const newSlot = formSelectedSlot
      ? `${formSelectedSlot.from}>${formSelectedSlot.to}`
      : fulfillmentSlots.length
      ? `${fulfillmentSlots[0].from}>${fulfillmentSlots[0].to}`
      : '';

    setValue('pickupOptions.fulfillmentTime', newSlot);
  };

  const pickupMethods = [
    ...(isAcceptingPickup
      ? [
          {
            id: 'pickup',
            label: pickupLabel,
            value: 'pickup',
          },
        ]
      : []),
    ...(isAcceptingCurbside
      ? [
          {
            id: 'curbside',
            label: curbsideLabel,
            value: 'curbside',
          },
        ]
      : []),
  ];

  const slotOptions =
    fulfillmentSlots?.map((slot) => ({
      label: slot.display_name,
      value: `${slot.from}>${slot.to}`,
    })) || [];

  useEffect(() => {
    // When switching to pickup, we re-validate delivery fields
    // as they are no longer required
    trigger('deliveryOptions');
  }, []);

  return (
    <CheckoutAccordionItem
      id="options"
      header="Confirm your reservation details"
      summary={<PickupSummary />}
      mt={bloomUserExperience && !isDesktop ? 16 : 0}
    >
      <Box>
        <Typography variant="body-bold" mb={12}>
          Store
        </Typography>
        <CheckoutStoreCard />

        <Box my={24}>
          <StyledGridContainer spacing={24}>
            <Grid.Item xs={12} lg={6}>
              <Typography variant="body-bold" mb={12}>
                Pickup time
              </Typography>
              <Form.SelectField
                borderRadius="xs"
                disableMobileInputStyling
                label="Pickup time"
                name="pickupOptions.fulfillmentTime"
                loading={isLoadingPickup}
                options={slotOptions}
                placeholder={
                  slotOptions.length
                    ? 'Select a reservation slot'
                    : 'No reservation times available'
                }
                validate={(value) =>
                  !shouldValidate || !!value || 'Please select a pickup time'
                }
                shouldUnregister={false}
                labelHidden
              />
            </Grid.Item>

            <Grid.Item xs={12} lg={6}>
              <Typography variant="body-bold" mb={12}>
                Pickup method
              </Typography>

              <Form.RadioFieldGroup
                row={isDesktop}
                name="pickupOptions.pickupMode"
                options={pickupMethods}
                onChange={handleChangeMode}
                shouldUnregister={false}
                mb={-24}
              />
            </Grid.Item>
          </StyledGridContainer>
        </Box>

        {curbside_pickup_instructions && formPickupMode === 'curbside' && (
          <Box mb={24}>
            <Typography mb={12} variant="body-bold">
              Curbside instructions
            </Typography>
            <Typography>{curbside_pickup_instructions}</Typography>
          </Box>
        )}

        <Typography variant="body-bold" mb={12}>
          Pickup instructions
        </Typography>
        <Form.TextField
          my={24}
          borderRadius="xs"
          disableMobileInputStyling
          label="Pickup instructions"
          name="pickupOptions.message"
          placeholder="e.g. Note to staff, special instructions"
          shouldUnregister={false}
          maxLength={300}
          labelHidden
        />

        {!!consentItems.length && (
          <Box mb={24}>
            {consentItems.map((item) => (
              <Box key={item.id}>
                <Form.CheckboxField
                  mt={12}
                  label={
                    <AgreementLabel
                      required={item.required}
                      label={item.label}
                    />
                  }
                  name={`pickupOptions.${item.name}`}
                  validate={(value) =>
                    !shouldValidate ||
                    !!value ||
                    !item.required ||
                    'This is a required agreement.'
                  }
                  shouldUnregister={false}
                />
                {item.external_link && (
                  <Link mt={24} href={item.external_link}>
                    View Agreement
                  </Link>
                )}
              </Box>
            ))}

            {!consentsValid && (
              <Typography color="error" mt={12}>
                Please agree to the store's required terms.
              </Typography>
            )}

            {appMode === 'default' && (
              <Flex mt={12} flexDirection="row">
                <Form.CheckboxField
                  disableMobileInputStyling
                  name="pickupOptions.promotionsOptIn"
                  label="Promotional emails"
                  labelHidden
                  defaultChecked
                />
                <OptInDescription />
              </Flex>
            )}
          </Box>
        )}

        <Button
          disabled={!isValid}
          onClick={() => handleContinue()}
          full
          label="Continue"
        />
      </Box>
    </CheckoutAccordionItem>
  );
};
