import styled from '@emotion/styled';

import { Link, Typography } from '@jane/shared/reefer';

export const InlineLink = styled(Link)(({ theme }) => ({
  color: theme.colors.text.light,
  display: 'inline',
}));

export const InlineBlockTypography = styled(Typography)(({ theme }) => ({
  color: theme.colors.text.light,
  display: 'inline-block',
}));

export const BrochureTypography = styled(Typography)(({ theme }) => ({
  color: theme.colors.text.main,
  lineHeight: '18px',
}));
