import cloneDeep from 'lodash/cloneDeep';
import type { Reducer } from 'redux';

import { config } from '@jane/shared/config';
import type { DeepReadonly, User } from '@jane/shared/models';
import { Storage } from '@jane/shared/util';

import { APPLICATION_CLOSE_MODAL } from '../../common/redux/application';
import {
  hideFormValidations,
  showFormValidations,
} from '../../common/redux/form';
import { createStandardAction } from '../../redux-util/redux-util';
import { NotificationsService } from '../../services/notifications';
import { UsersSource } from '../../sources/users';
import type { CustomerThunkAction } from '../redux';
import { CUSTOMER_LOG_OUT } from './customer';
import type { CustomerAction } from './types';

export const SET_PREVIOUS_PATH = 'users/set-previous-path';

export const setPreviousPath =
  createStandardAction(SET_PREVIOUS_PATH)<string>();

export const UPDATE_USERS = 'users/update-users';
export const updateUsers = ({
  attributes,
  disableNotification = false,
  onSuccess,
}: {
  attributes: Record<string, string | boolean | undefined>;
  disableNotification?: boolean;
  onSuccess?: () => void;
}): CustomerThunkAction => {
  const updates = cloneDeep(attributes);

  if (updates['phone'] && typeof updates['phone'] === 'string') {
    updates['phone'] = updates['phone'].replace(/-/g, '');
  }

  return (dispatch) => {
    UsersSource.update({
      user: { ...(({ promotions_opt_out, ...rest }) => rest)(updates) },
      promotions_opt_out: updates['promotions_opt_out'] as boolean,
    }).then((result) => {
      const { user, validations } = result;

      dispatch(hideFormValidations());

      if (validations) {
        dispatch(showFormValidations(validations));
      } else {
        !disableNotification &&
          NotificationsService.success('Profile has been updated');
        Storage.set(config.storageKey, user);
        onSuccess && onSuccess();
        dispatch({ type: UPDATE_USERS, payload: user });
      }
    });
  };
};

export type UsersActions =
  | ReturnType<typeof setPreviousPath>
  | { payload: User; type: typeof UPDATE_USERS };

export type UsersState = DeepReadonly<{
  hasLoadedReservations: boolean;
  isCancellingReservation: boolean;
  isLoading: boolean;
  isLoadingReservations: boolean;
  previousPath: string;
  productsOrdered: number;
  reviewsLeft: number;
  shouldUpdate: boolean;
  user:
    | {
        birth_date: string;
        email: string;
        nickname: string;
        password: string;
        phone: string;
        promotions_opt_out: boolean;
      }
    | User;
}>;

const getInitialState = (): UsersState => ({
  user: {
    birth_date: '',
    nickname: '',
    email: '',
    phone: '',
    password: '',
    promotions_opt_out: false,
  },
  productsOrdered: 0,
  reviewsLeft: 0,
  shouldUpdate: true,
  hasLoadedReservations: false,
  isCancellingReservation: false,
  isLoadingReservations: false,
  isLoading: true,
  previousPath: '',
});

export const usersReducer: Reducer<UsersState, CustomerAction> = (
  state = getInitialState(),
  action
) => {
  switch (action.type) {
    case UPDATE_USERS:
      return { ...state, user: { ...state.user, ...action.payload } };

    case APPLICATION_CLOSE_MODAL:
      return { ...state, user: getInitialState().user };

    case CUSTOMER_LOG_OUT:
      return {
        ...state,
        user: getInitialState().user,
        hasLoadedReservations: false,
      };

    case SET_PREVIOUS_PATH:
      return { ...state, previousPath: action.payload };
  }

  return state;
};
