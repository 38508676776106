import type { BrandSpecialPrices, PriceId } from '@jane/shared/models';

import type { ProductWithBrandSpecialPrices } from '../shouldShowSpecial';
import { brandSpecialHasOneDiscount, getFirstBrandSpecial } from './util';

/*
This function returns a label for a brand discount
It can be passed either a menuProduct or a brandSpecials object, but not both.
If the menuProduct does not have a brand discount, then it will return null, and therefor should suffice as an equality check.
If you want to make sure a specific priceId exists within a brand special before getting its label,
use the getPriceIdBrandSpecial helper to return the whole special for that price id, if it has one.
If you want to extract a generic tag label for multiple priceIds, pass only the brand special.
It will return the discount amount if all the priceIds share a discount id,
otherwise it will return the fallback label.
*/

type BrandSpecialTagLabelKey = 'discount_label' | 'title';

type Props = {
  brandSpecials?: BrandSpecialPrices;
  fallbackLabel?: string;
  menuProduct?: ProductWithBrandSpecialPrices;
  priceId?: PriceId;
  priorityValueKey?: BrandSpecialTagLabelKey;
} & (
  | {
      brandSpecials: BrandSpecialPrices;
      menuProduct?: never;
    }
  | { brandSpecials?: never; menuProduct: ProductWithBrandSpecialPrices }
);

const fallbackKeys: {
  [Property in BrandSpecialTagLabelKey]: BrandSpecialTagLabelKey;
} = {
  discount_label: 'title',
  title: 'discount_label',
};

export const getJaneGoldTagLabel = ({
  fallbackLabel = 'Cash Back',
  brandSpecials,
  menuProduct,
  priorityValueKey = 'discount_label',
  priceId,
}: Props) => {
  if (!menuProduct && !brandSpecials) return null;

  let brandSpecialsPrices = brandSpecials;

  if (menuProduct) {
    if (!menuProduct.brand_special_prices || !menuProduct.has_brand_discount)
      return null;

    brandSpecialsPrices = menuProduct.brand_special_prices;
  }

  if (!priceId)
    return getLabelForMultiplePriceIds(
      brandSpecialsPrices as BrandSpecialPrices,
      fallbackLabel,
      priorityValueKey
    );

  const priceIdSpecial =
    (brandSpecialsPrices as BrandSpecialPrices)[priceId] || {};
  const fallbackKey = fallbackKeys[priorityValueKey];

  return (
    priceIdSpecial[priorityValueKey] ||
    priceIdSpecial[fallbackKey] ||
    fallbackLabel
  );
};

const getLabelForMultiplePriceIds = (
  brandSpecials: BrandSpecialPrices,
  fallbackLabel: string,
  priorityValueKey: BrandSpecialTagLabelKey
) => {
  const hasOneDiscountId = brandSpecialHasOneDiscount(brandSpecials);

  if (hasOneDiscountId) {
    const firstSpecial = getFirstBrandSpecial(brandSpecials) || {};
    const fallbackKey = fallbackKeys[priorityValueKey];

    return (
      firstSpecial[priorityValueKey] ||
      firstSpecial[fallbackKey] ||
      fallbackLabel
    );
  }

  return fallbackLabel;
};
