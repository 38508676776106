import { useCheckout } from '@jane/shared-ecomm/providers';
import { SpecialTag } from '@jane/shared/components';
import {
  useNeedsSignInForEligibility,
  useShouldShowGold,
} from '@jane/shared/hooks';
import type {
  LocalizedProductContent,
  PendingCartProduct,
} from '@jane/shared/models';
import {
  Box,
  Flex,
  Image,
  JaneGoldIcon,
  TagFilledIcon,
  TagIcon,
  Typography,
  useTabletMediaQuery,
} from '@jane/shared/reefer';
import {
  calculateDiscountPercentage,
  formatCurrency,
  getOriginalAndDiscountedTotalPrices,
  productPhotoMetadata,
  titleCase,
} from '@jane/shared/util';

import { localizeCartProduct } from '../util/localizeCartProduct';

export const CheckoutReviewProductCard = ({
  product,
}: {
  product: PendingCartProduct;
}) => {
  const isMobile = useTabletMediaQuery({ width: 'max' });
  const {
    appInfo: { bloomUserExperience },
    cart: { localized_products_content },
    store,
  } = useCheckout();
  const shouldShowGold = useShouldShowGold({
    product,
    ...(store.id ? { store: { id: store.id, state: store.state } } : {}),
  });

  const localizedProduct = localizeCartProduct(
    product,
    localized_products_content as LocalizedProductContent[]
  );

  const {
    name,
    brand,
    brand_subtype,
    category,
    brand_discount_label,
    brand_discount_title,
    count,
    price_id,
    special_title,
  } = localizedProduct;
  const brandDiscountText = brand_discount_title || brand_discount_label;

  const needsSignInForEligibility = useNeedsSignInForEligibility({
    cartProduct: product,
  });

  const { url } = productPhotoMetadata(product);

  const { originalPrice, discountedPrice } =
    getOriginalAndDiscountedTotalPrices({
      menuProduct: product,
      count,
      priceId: price_id,
    });

  const isDiscounted = Boolean(
    discountedPrice && originalPrice !== discountedPrice
  );

  const discountPercentage = calculateDiscountPercentage(
    originalPrice,
    discountedPrice
  );

  return (
    <Flex flexDirection={isMobile ? 'column' : 'row'}>
      {bloomUserExperience ? (
        <Flex width="96px" height="96px">
          <Flex position="absolute">
            <Image
              borderRadius="rounded"
              height="96px"
              width="96px"
              responsive
              sizes="thumbnail"
              altText={`${name}-image`}
              src={url}
            />
          </Flex>
          {discountPercentage && discountPercentage > 0 && (
            <Flex
              zIndex={1}
              position="relative"
              minWidth="96px"
              justifyContent="flex-end"
              p={8}
            >
              <SpecialTag label={`-${discountPercentage}%`} />
            </Flex>
          )}
        </Flex>
      ) : (
        <Image
          borderRadius="rounded"
          height="96px"
          width="96px"
          responsive
          sizes="thumbnail"
          altText={`${name}-image`}
          src={url}
        />
      )}

      <Box ml={isMobile ? 0 : 16} mt={isMobile ? 16 : 0} width="100%">
        <Flex>
          <Typography variant="body-bold">
            {count > 1 ? `${count} x ` : ''}
            {name}
          </Typography>
          {!bloomUserExperience ? (
            <Box ml="auto" mb={isDiscounted ? -24 : 0}>
              <Typography
                strikeThrough={!!isDiscounted}
                variant={isDiscounted ? 'body' : 'body-bold'}
              >
                {formatCurrency(originalPrice)}
              </Typography>
              {isDiscounted && (
                <Typography variant="body-bold" color="text-main">
                  {formatCurrency(discountedPrice)}
                </Typography>
              )}
            </Box>
          ) : (
            // for bloom
            <Box ml="auto" mb={discountedPrice ? -24 : 0}>
              {Boolean(discountedPrice) && (
                <Typography
                  variant="body-bold"
                  color={special_title ? 'info' : 'text-main'}
                >
                  {formatCurrency(discountedPrice)}
                </Typography>
              )}
              {formatCurrency(discountedPrice) !==
                formatCurrency(originalPrice) && (
                <Typography
                  strikeThrough={!!discountedPrice}
                  variant={discountedPrice ? 'body' : 'body-bold'}
                  color={discountedPrice ? 'text-light' : 'text-main'}
                >
                  {formatCurrency(originalPrice)}
                </Typography>
              )}
            </Box>
          )}
        </Flex>

        {!!brand && <Typography color="text-light">{brand}</Typography>}

        {(!!brand_subtype || !!category) && (
          <Typography color="text-light">
            {brand_subtype || ''}
            {brand_subtype && category ? ' - ' : ''}
            {titleCase(category || '')}
          </Typography>
        )}

        {!!special_title && (!bloomUserExperience || isDiscounted) && (
          <Flex alignItems="center">
            {bloomUserExperience && <TagFilledIcon size="sm" color="info" />}
            {!bloomUserExperience && <TagIcon size="sm" color="info" />}
            <Typography
              color={bloomUserExperience ? 'text-main' : 'info'}
              ml={4}
            >
              {special_title}
            </Typography>
          </Flex>
        )}

        {!!brandDiscountText && !!shouldShowGold && (
          <Flex alignItems="center" width="100%">
            <JaneGoldIcon altText="jane-gold-icon" size="sm" />
            <Flex ml={4} gap={4} alignItems="center">
              <Typography>{brandDiscountText}</Typography>
              {needsSignInForEligibility && (
                <Typography color="grays-mid">
                  (Sign in for eligibility)
                </Typography>
              )}
            </Flex>
          </Flex>
        )}
      </Box>
    </Flex>
  );
};
