import type { AppMode } from '@iheartjane/dm-sdk';
import { useMemo } from 'react';

import { useSmartRecommendedPDPRow } from '@jane/dm/internal';
import type { CardCarouselProps } from '@jane/shared/components';
import { CardCarousel } from '@jane/shared/components';
import { config } from '@jane/shared/config';
import type { Store } from '@jane/shared/models';
import { ZoneStorePdp } from '@jane/shared/models';
import { useMobileMediaQuery } from '@jane/shared/reefer';

import { MenuProductCarousel } from '../menuProductCarousel/menuProductCarousel';
import { ProductCardWithTracking } from '../productCardWithTracking/productCardWithTracking';
import type { ProductCardWithTrackingProps } from '../productCardWithTracking/productCardWithTracking.types';

type ProductDetailsSponsoredCarouselProps = Pick<CardCarouselProps, 'mode'> &
  Pick<
    ProductCardWithTrackingProps,
    'cartProducts' | 'cartIsOpen' | 'cartStoreId' | 'specials'
  > & {
    appMode: AppMode;
    attemptToAddToCart: (...args: unknown[]) => void;
    isProductInStock: boolean;
    onCloseCart: (...args: unknown[]) => void;
    onDeleteFromCart: (...args: unknown[]) => void;
    productId: number;
    store: Store;
  };

export const ProductDetailsSponsoredCarousel = (
  props: ProductDetailsSponsoredCarouselProps
) => {
  const {
    appMode,
    productId,
    store,
    cartProducts,
    cartIsOpen,
    cartStoreId,
    mode = 'ideal-width',
    attemptToAddToCart,
    onCloseCart,
    onDeleteFromCart,
    specials,
    isProductInStock,
  } = props;
  const isMobile = useMobileMediaQuery({});

  const { isLoading, instance } = useSmartRecommendedPDPRow({
    appMode,
    productId,
    searchAttributes: ['*'],
    storeId: Number(store.id),
  });

  const rowName = isProductInStock
    ? instance?.title || 'You may also like'
    : 'Other products you may like';

  /**
   * If the count of all the sponsored products is equal to the count
   * of all the products, then all the products are sponsored.
   */
  const isRowSponsored = useMemo(() => {
    if (!instance || !instance.products.length) {
      return false;
    }

    return (
      instance.products.filter((product) => product.isSponsored).length ===
      instance.products.length
    );
  }, [instance]);

  if (!isLoading && (!instance || instance.products.length === 0)) {
    return <></>;
  }

  return (
    <MenuProductCarousel
      isLoading={isLoading}
      name={rowName}
      mode={mode}
      listView={false}
      variant={isRowSponsored ? 'sponsored' : 'default'}
      mx={isMobile ? 24 : 64}
      mb={24}
    >
      {instance?.products.map((product, index) => (
        <CardCarousel.Card
          key={`row-ad-${product.objectId}`}
          width="100%"
          widthMobile="100%"
        >
          <ProductCardWithTracking
            algoliaIndexName={`menu-products-${config.algoliaEnv}`}
            appliedWeightFilter=""
            carouselView={true}
            columnPosition={index}
            menuRowName={rowName}
            productLocation={'PDP'}
            product={product}
            rowPosition={0}
            zone={ZoneStorePdp}
            listView={false}
            cartIsOpen={cartIsOpen}
            cartProducts={cartProducts}
            store={store}
            appMode={appMode}
            attemptToAddToCart={attemptToAddToCart}
            closeCart={onCloseCart}
            deleteCartItem={(item, priceId) => {
              onDeleteFromCart(item, priceId);
            }}
            cartStoreId={cartStoreId}
            isRowSponsored={isRowSponsored}
            specials={specials}
          />
        </CardCarousel.Card>
      ))}
    </MenuProductCarousel>
  );
};
