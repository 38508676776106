import * as t from 'io-ts';

import { tPendingCartProduct } from './cart';
import type { DeepReadonly } from './deepReadonly';
import { tPriceId, tProductWeight } from './menuProduct';
import { tProductKind, tProductLineage } from './product';
import { tPublicImage } from './publicImage';

export const tBrandCondition = t.string;

export const tProductLineageWithAll = t.union([
  tProductLineage,
  t.literal('all'),
]);

export const tKindCondition = t.intersection([
  t.interface({ kind: tProductKind }),
  t.partial({ lineage: tProductLineageWithAll }),
  t.partial({ brand_subtype: t.string }),
  t.partial({ root_subtype: t.string }),
  t.partial({ brand_subtype: t.string }),
]);

export const tWeightCondition = tProductWeight;

export const tAdditionalProductWeight = t.keyof({
  three_gram: null,
  four_gram: null,
  five_gram: null,
  eight_gram: null,
  ten_gram: null,
  two_ounce: null,
  two_and_half_ounce: null,
});

const tProductCondition = t.number;

export const tProductThresholdConditions = t.partial({
  maximum_price: t.union([t.number, t.undefined, t.null]),
  minimum_price: t.union([t.number, t.undefined, t.null]),
});

const tProductConditions = t.partial({
  brands: t.union([t.array(tBrandCondition), t.undefined]),
  excluded_product_ids: t.union([t.array(tProductCondition), t.undefined]),
  kinds: t.union([t.array(tKindCondition), t.undefined]),
  weights: t.union([t.array(tWeightCondition), t.undefined]),
  included_product_ids: t.union([
    t.array(tProductCondition),
    t.undefined,
    t.null,
  ]),
  product_threshold: t.union([tProductThresholdConditions, t.undefined]),
  lineage: t.union([t.undefined, t.string]),
});

const tDependentBundleConditions = t.intersection([
  tProductConditions,
  t.interface({
    max_number_of_discounted_products: t.union([t.number, t.null]),
  }),
]);

const tBundleProductConditions = t.partial({
  independent: t.intersection([
    tProductConditions,
    t.interface({
      threshold_number_of_items_in_cart: t.union([t.number, t.null]),
    }),
  ]),
  dependent: tDependentBundleConditions,
  settings: t.interface({
    allow_discounts_on_required_products: t.union([t.boolean, t.null]),
  }),
  max_applications_per_cart: t.union([t.number, t.null]),
});

const tSpendingThresholdConditions = t.partial({
  independent: t.intersection([
    tProductConditions,
    t.interface({
      threshold_number_of_items_in_cart: t.union([t.number, t.null]),
    }),
  ]),
  dependent: tDependentBundleConditions,
  settings: t.interface({
    allow_discounts_on_required_products: t.union([t.boolean, t.null]),
  }),
  max_applications_per_cart: t.union([t.number, t.null]),
});

// TODO: remove partial once Specials have been repaired
const tCartTotalConditions = t.intersection([
  t.partial({
    reservation_modes: t.union([
      t.partial({
        kiosk: t.union([t.boolean, t.undefined]),
        delivery: t.union([t.boolean, t.undefined]),
        pickup: t.union([t.boolean, t.undefined]),
      }),
      t.undefined,
    ]),
  }),
  t.interface({
    threshold: t.union([t.number, t.string]),
  }),
]);

export const tSpecialGroup = t.keyof({
  student: null,
  veteran: null,
  medical: null,
  senior: null,
  pediatric: null,
  ssi: null,
  snap: null,
  industry: null,
  medicare: null,
  medicaid: null,
  member: null,
  locals: null,
  wic: null,
  pace: null,
  pacenet: null,
  chip: null,
  'PA Gov': null,
});

const tSeniorGroupConditions = t.interface({
  type: t.literal('senior'),
  required_age: t.number,
});

const tPediatricGroupConditions = t.interface({
  type: t.literal('pediatric'),
  required_age: t.number,
});

const tQualifiedGroupConditions = t.union([
  tSeniorGroupConditions,
  tPediatricGroupConditions,
  t.interface({
    type: t.keyof({
      student: null,
      veteran: null,
      medical: null,
      ssi: null,
      snap: null,
      industry: null,
      medicare: null,
      medicaid: null,
      member: null,
      locals: null,
      chip: null,
      pace: null,
      pacenet: null,
      wic: null,
      'PA Gov': null,
    }),
  }),
]);

const tBulkWeightCondition = t.intersection([
  t.interface({
    price_id: tPriceId,
  }),
  tProductThresholdConditions,
]);

const tBulkPricingConditions = t.partial({
  brands: t.union([t.array(tBrandCondition), t.undefined]),
  kinds: t.union([t.array(tKindCondition), t.undefined]),
  excluded_product_ids: t.union([t.array(tProductCondition), t.undefined]),
  included_product_ids: t.union([
    t.array(tProductCondition),
    t.undefined,
    t.null,
  ]),
  lineage: t.union([t.undefined, t.string]),
  bulk_weights: t.union([t.array(tBulkWeightCondition), t.undefined]),
  target_weight: t.union([
    tPriceId,
    t.union([tAdditionalProductWeight, t.undefined]),
  ]),
  max_applications_per_cart: t.union([t.number, t.null]),
});

const tBrandDiscount = t.interface({
  id: t.number,
  brand_special_id: t.number,
  cart_id: t.number,
  cart_product_id: t.number,
  created_at: t.string,
  discount_amount: t.string,
  menu_product_id: t.number,
  status: t.string,
  updated_at: t.string,
});

const tSpecialType = t.keyof({
  product: null,
  cart_total: null,
  qualified_group: null,
  bundle: null,
  bulk_pricing: null,
  spending_threshold: null,
});

export const tSpecialRules = t.partial({
  includes: t.array(tProductConditions),
  excludes: t.array(tProductConditions),
});

export const tSpecialConditions = t.partial({
  product: tProductConditions,
  cart_total: tCartTotalConditions,
  qualified_group: tQualifiedGroupConditions,
  bundle: tBundleProductConditions,
  bulk_pricing: tBulkPricingConditions,
  spending_threshold: tSpendingThresholdConditions,
  created_at: t.union([t.string, t.null]),
  updated_at: t.union([t.string, t.null]),
});

const tSpecialStatus = t.union([
  t.literal('today'),
  t.literal('upcoming'),
  t.literal('disabled'),
  t.literal('expired'),
]);

// TODO: remove partial once Specials have been repaired
const tReservationModes = t.intersection([
  t.interface({
    kiosk: t.union([t.void, t.boolean]),
    pickup: t.union([t.void, t.boolean]),
    delivery: t.union([t.void, t.boolean]),
  }),
  t.partial({
    curbside: t.union([t.void, t.boolean]),
  }),
]);

export const tSchedule = t.intersection([
  t.interface({
    // TODO: Once the backend is implemented we should take out these
    // t.undefined form the t.union arrays
    enabled_monday_all_day: t.union([t.boolean, t.undefined]),
    enabled_tuesday_all_day: t.union([t.boolean, t.undefined]),
    enabled_wednesday_all_day: t.union([t.boolean, t.undefined]),
    enabled_thursday_all_day: t.union([t.boolean, t.undefined]),
    enabled_friday_all_day: t.union([t.boolean, t.undefined]),
    enabled_saturday_all_day: t.union([t.boolean, t.undefined]),
    enabled_sunday_all_day: t.union([t.boolean, t.undefined]),
    start_time_monday: t.union([t.string, t.null, t.undefined]),
    start_time_tuesday: t.union([t.string, t.null, t.undefined]),
    start_time_wednesday: t.union([t.string, t.null, t.undefined]),
    start_time_thursday: t.union([t.string, t.null, t.undefined]),
    start_time_friday: t.union([t.string, t.null, t.undefined]),
    start_time_saturday: t.union([t.string, t.null, t.undefined]),
    start_time_sunday: t.union([t.string, t.null, t.undefined]),
    end_time_monday: t.union([t.string, t.null, t.undefined]),
    end_time_tuesday: t.union([t.string, t.null, t.undefined]),
    end_time_wednesday: t.union([t.string, t.null, t.undefined]),
    end_time_thursday: t.union([t.string, t.null, t.undefined]),
    end_time_friday: t.union([t.string, t.null, t.undefined]),
    end_time_saturday: t.union([t.string, t.null, t.undefined]),
    end_time_sunday: t.union([t.string, t.null, t.undefined]),
    use_store_close_time_for_end: t.union([t.boolean, t.undefined]),
  }),
  t.partial({
    start_time: t.union([t.string, t.null, t.undefined]),
    end_time: t.union([t.string, t.null, t.undefined]),
    enabled_monday: t.union([t.boolean, t.null, t.undefined]),
    enabled_tuesday: t.union([t.boolean, t.null, t.undefined]),
    enabled_wednesday: t.union([t.boolean, t.null, t.undefined]),
    enabled_thursday: t.union([t.boolean, t.null, t.undefined]),
    enabled_friday: t.union([t.boolean, t.null, t.undefined]),
    enabled_saturday: t.union([t.boolean, t.null, t.undefined]),
    enabled_sunday: t.union([t.boolean, t.null, t.undefined]),
  }),
]);

export const tAbbreviatedStoreSpecial = t.interface({
  id: t.number,
  description: t.union([t.string, t.null, t.undefined]),
  title: t.string,
  store_id: t.union([t.number, t.string]),
  special_type: tSpecialType,
  end_date: t.union([t.string, t.null, t.undefined]),
  updated_at: t.union([t.string, t.null, t.undefined]),
  created_at: t.union([t.string, t.null, t.undefined]),
  photo: t.union([t.null, tPublicImage]),
});

export const tStoreSpecial = t.intersection([
  t.interface({
    id: t.number,
    description: t.union([t.string, t.null]),
    store_id: t.union([t.number, t.string]),
    terms: t.union([t.string, t.null]),
    title: t.string,
    discount_type: t.string,
    discount_dollar_amount: t.number,
    discount_percent: t.number,
    discount_target_price: t.number,
    enabled: t.union([t.boolean, t.null, t.undefined]),
    enabled_date_start: t.union([t.string, t.undefined, t.null]),
    enabled_date_end: t.union([t.string, t.undefined, t.null]),
    enabled_date_start_checked: t.union([t.boolean, t.undefined, t.null]),
    enabled_date_end_checked: t.union([t.boolean, t.undefined, t.null]),
    schedule: t.union([tSchedule, t.null, t.undefined]),
    start_date: t.union([t.string, t.null, t.undefined]),
    end_date: t.union([t.string, t.null, t.undefined]),
    start_time: t.union([t.string, t.null, t.undefined]),
    end_time: t.union([t.string, t.null, t.undefined]),
    conditions: tSpecialConditions,
    rules: t.union([t.undefined, t.null, tSpecialRules]),
    photo: t.union([t.null, tPublicImage]),
    promo_code: t.union([t.string, t.null]),
    multiple_use_promo_code: t.union([t.boolean, t.null]),
    reservation_modes: t.union([tReservationModes, t.null]),
    status: t.union([t.null, tSpecialStatus]),
    special_type: tSpecialType,
    pos_synced: t.union([t.boolean, t.undefined]),
    pos_source: t.union([t.string, t.null, t.undefined]),
    pos_special_id: t.union([t.string, t.null, t.undefined]),
    pos_special_link: t.union([t.string, t.null, t.undefined]),
  }),
  t.partial({
    custom_badge: t.union([t.string, t.null, t.undefined]),
    enabled_monday: t.union([t.boolean, t.null, t.undefined]),
    enabled_tuesday: t.union([t.boolean, t.null, t.undefined]),
    enabled_wednesday: t.union([t.boolean, t.null, t.undefined]),
    enabled_thursday: t.union([t.boolean, t.null, t.undefined]),
    enabled_friday: t.union([t.boolean, t.null, t.undefined]),
    enabled_saturday: t.union([t.boolean, t.null, t.undefined]),
    enabled_sunday: t.union([t.boolean, t.null, t.undefined]),
    created_at: t.union([t.string, t.null]),
    updated_at: t.union([t.string, t.null]),
    discount_amount: t.union([t.string, t.null]),
    use_store_close_time: t.union([t.boolean, t.null]),
    promo_code_max_number_of_uses: t.union([t.number, t.null]),
    stacking_setting: t.union([
      t.literal('yes'),
      t.literal('no'),
      t.literal('combinable'),
    ]),
    abbreviated_store_specials: t.array(
      t.union([
        t.null,
        t.interface({
          id: t.number,
          store_id: t.number,
          enabled: t.boolean,
          disabled_by_bulk_editing: t.union([t.boolean, t.null]),
        }),
      ])
    ),
  }),
]);

export const tStoreSpecials = t.interface({
  specials: t.array(tStoreSpecial),
});

export const tSpecialPage = t.interface({
  specials: t.array(tStoreSpecial),
  offset: t.number,
  total: t.number,
});

export const tAbbreviatedStoreSpecials = t.interface({
  specials: t.array(tAbbreviatedStoreSpecial),
});

export const tQualifyingStatus = t.intersection([
  t.interface({
    qualifying_status: t.union([t.null, t.string]),
    special: t.interface({
      id: t.union([t.string, t.number]),
      store_id: t.union([t.string, t.number]),
      description: t.union([t.null, t.string]),
      special_type: t.string,
      title: t.string,
      photo: t.any,
    }),
  }),
  t.partial({
    discount_total_amount: t.union([t.number, t.null]),
    product_price: t.union([t.number, t.null]),
    product_weight: t.union([t.number, t.null]),
    target_price: t.union([t.number, t.null]),
    qualifying_threshold: t.union([t.number, t.null]),
    target_weight: t.union([t.number, t.null]),
    qualified_products: t.array(tPendingCartProduct),
    discounted_products: t.array(tPendingCartProduct),
  }),
]);

export const tSubtype = t.interface({
  brand_subtypes: t.array(tBrandCondition),
  subtype: t.string,
});

export const tSubItem = t.interface({
  label: t.string,
  subItems: t.array(t.type({ label: t.string, value: t.string })),
  value: t.string,
});

export const tCategoryOption = t.interface({
  label: t.string,
  subItems: t.array(tSubItem),
  value: t.string,
});

export const tStoreCategory = t.interface({
  kind: t.string,
  subtypes: t.array(tSubtype),
});

export type StoreCategory = t.TypeOf<typeof tStoreCategory>;
export type CategoryOption = t.TypeOf<typeof tCategoryOption>;
export type Subtype = t.TypeOf<typeof tSubtype>;
export type SubItem = t.TypeOf<typeof tSubItem>;
export type QualifyingStatus = t.TypeOf<typeof tQualifyingStatus>;
export type StoreSpecial = t.TypeOf<typeof tStoreSpecial>;
export type AbbreviatedStoreSpecial = t.TypeOf<typeof tAbbreviatedStoreSpecial>;
export type SpecialSchedule = t.TypeOf<typeof tSchedule>;
export type KindCondition = t.TypeOf<typeof tKindCondition>;
export type BrandCondition = t.TypeOf<typeof tBrandCondition>;
export type WeightCondition = t.TypeOf<typeof tWeightCondition>;
export type ProductCondition = t.TypeOf<typeof tProductCondition>;
export type BrandDiscount = t.TypeOf<typeof tBrandDiscount>;

export type StoreSpecialCondition =
  | KindCondition
  | BrandCondition
  | ProductCondition
  | WeightCondition
  | BulkWeightCondition;
export type SpecialConditions = DeepReadonly<
  t.TypeOf<typeof tSpecialConditions>
>;
export type SpecialType = t.TypeOf<typeof tSpecialType>;
export type SpecialStatus = t.TypeOf<typeof tSpecialStatus>;
export type SpecialGroupType = t.TypeOf<typeof tSpecialGroup>;
export type ProductConditions = t.TypeOf<typeof tProductConditions>;
export type ProductRules = t.TypeOf<typeof tSpecialRules>;
export type CartTotalConditions = t.TypeOf<typeof tCartTotalConditions>;
export type QualifiedGroupConditions = t.TypeOf<
  typeof tQualifiedGroupConditions
>;
export type SeniorGroupConditions = t.TypeOf<typeof tSeniorGroupConditions>;
export type ProductThresholdConditions = t.TypeOf<
  typeof tProductThresholdConditions
>;
export type BundleProductConditions = t.TypeOf<typeof tBundleProductConditions>;
export type SpendingThresholdConditions = t.TypeOf<
  typeof tSpendingThresholdConditions
>;
export type DependentBundleConditions = t.TypeOf<
  typeof tDependentBundleConditions
>;
export type BulkWeightCondition = t.TypeOf<typeof tBulkWeightCondition>;
export type AdditionalProductWeight = t.TypeOf<typeof tAdditionalProductWeight>;
export type BulkPricingConditions = t.TypeOf<typeof tBulkPricingConditions>;
export type ReservationModes = t.TypeOf<typeof tReservationModes>;
