import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useSearchContext } from '@jane/search/providers';
import { getLocationQueryWithoutSearchState } from '@jane/search/util';
import { useMenu } from '@jane/shared-ecomm/providers';
import { trackMenuTabTap } from '@jane/shared-ecomm/tracking';
import { DefaultCategoryIcon, Flex, Typography } from '@jane/shared/reefer';
import type { MenuCategoryPath } from '@jane/shared/types';
import { currencyCodeFromCountryCode, sentenceCase } from '@jane/shared/util';

import { useGetPage } from '../../hooks/useGetPage';
import {
  MenuTabIconContainer,
  StyledInnerItem,
  StyledListItem,
  StyledMenuTabButton,
} from './MenuTabButton.styles';
import { getTabTrackingSource } from './MenuTabs.util';
import type { MenuCategory } from './menuCategoryTabs';

interface MenuTabButtonProps {
  isFirstTab: boolean;
  item: MenuCategory;
  name: MenuCategoryPath;
  width: number;
}

export const MenuTabButton = ({
  isFirstTab,
  item,
  name,
  width,
}: MenuTabButtonProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const page = useGetPage();
  const { category: categoryPath } = useParams<{
    category: MenuCategoryPath;
  }>();

  const { resetSearchState } = useSearchContext();

  const {
    appInfo: { appMode, isEmbed },
    paths,
    store,
  } = useMenu();
  const { Icon, color, iconColor } = item;

  const activeCategory = categoryPath === name || page === name;
  const isSelected =
    page === 'menu'
      ? isFirstTab
      : !categoryPath && page !== 'specials'
      ? name === 'featured'
      : activeCategory;

  const customLabels = store.custom_product_type_labels;
  const nameToRender =
    name === 'specials'
      ? store.specials_row_configs?.custom_display_name || 'Specials'
      : (customLabels && customLabels[name]) ||
        sentenceCase(name === 'all' ? 'all products' : name);

  const isOhioFlower = name === 'flower' && store.state === 'Ohio';
  const IconToRender = isOhioFlower ? DefaultCategoryIcon : Icon;

  const handleClick = () => {
    resetSearchState();
    const { city, state, name: storeName, id: storeId } = store;
    const source = getTabTrackingSource(location.pathname, categoryPath);

    trackMenuTabTap({
      appMode,
      productLocation: name,
      source,
      storeCity: city as string,
      storeState: state as string,
      storeId: String(storeId),
      storeName: storeName as string,
      storeCurrency: currencyCodeFromCountryCode(store.country_code || ''),
    });
    // Maintain any non-search query params when changing category pages
    const query = getLocationQueryWithoutSearchState(location.search);
    navigate(paths.menuCategory(name, query));
  };

  return (
    <StyledListItem isSelected={isSelected} aria-label={name}>
      <StyledInnerItem>
        <StyledMenuTabButton
          name={name}
          color={isEmbed ? 'primary-light' : color}
          onClick={handleClick}
          width={width}
        >
          <MenuTabIconContainer>
            <Flex
              background={
                isSelected ? (isEmbed ? 'primary-light' : color) : 'transparent'
              }
              height={40}
              width={40}
              alignItems="center"
              justifyContent="center"
            >
              <IconToRender
                className="menu-category--icon"
                color={
                  isEmbed
                    ? 'primary'
                    : iconColor
                    ? iconColor
                    : isOhioFlower
                    ? 'purple'
                    : undefined
                }
                style={{
                  willChange: 'transform',
                  transition: !isSelected ? 'transform 150ms' : '',
                }}
              />
            </Flex>
          </MenuTabIconContainer>
          <Typography
            variant={isSelected ? 'body-bold' : 'body'}
            color={isSelected ? 'grays-black' : 'grays-mid'}
            whiteSpace="nowrap"
            truncateAt="100%"
          >
            {nameToRender}
          </Typography>
        </StyledMenuTabButton>
      </StyledInnerItem>
    </StyledListItem>
  );
};
