import styled from '@emotion/styled';

import { Flex } from '@jane/shared/reefer';
import { Form } from '@jane/shared/reefer-hook-form';

export const SubmittableTextContainer = styled(Flex)({
  overflow: 'hidden',
});

export const StyledTextField = styled(Form.TextField)(({ theme }) => ({
  width: '100%',
  color: theme.colors.primary.main,
  backgroundColor: theme.colors.grays.white,
  WebkitAppearance: 'none',
  '&::-ms-clear': {
    display: 'none',
  },
  '&::-webkit-search-cancel-button': {
    display: 'none',
  },
  '&:-webkit-autofill': {
    boxShadow: '0 0 0 100px white inset',
  },
  '::placeholder': {
    color: theme.colors.grays.light,
  },
}));
