import type { SmartSortProduct } from '@iheartjane/dm-sdk';
import { useCallback, useEffect } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';

import type { AlgoliaProduct } from '@jane/search/types';
import { buildDiscountableProductsFilter } from '@jane/search/util';
import { MenuBreadcrumbs } from '@jane/shared-ecomm/components';
import { useGetSpecialQualifyingStatus } from '@jane/shared-ecomm/data-access';
import { useMenu, useSpecials } from '@jane/shared-ecomm/providers';
import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import type { PendingCartProduct, QualifyingStatus } from '@jane/shared/models';
import { Box, Flex, useMobileMediaQuery } from '@jane/shared/reefer';
import type { Breadcrumb, MenuProduct, StoreSpecial } from '@jane/shared/types';
import { insertIfArr } from '@jane/shared/util';

import { MenuFiltersAndProductsGrid } from '../../MenuComponents/MenuFiltersAndProductsGrid/MenuFiltersAndProductsGrid';
import { MenuTitle } from '../../MenuComponents/MenuTitle/MenuTitle';
import { useComposeMenuFilters } from '../../hooks/useComposeMenuFilters';
import {
  filterProductsByWeight,
  getApplicablePriceIds,
  getSpecialType,
  shouldShowWeight,
} from './MenuSpecialDetail.util';
import { MenuSpecialDetailBundleProgress } from './MenuSpecialDetailBundleProgress/MenuSpecialDetailBundleProgress';
import { MenuSpecialDetailHeader } from './MenuSpecialDetailHeader/MenuSpecialDetailHeader';
import { MenuSpecialDetailProgressBar } from './MenuSpecialDetailProgressBar/MenuSpecialDetailProgressBar';

export const MenuSpecialDetail = () => {
  const specialsWeight = useFlag(FLAGS.specialWeights);

  const { state } = useLocation();
  const isMobile = useMobileMediaQuery({});
  const { special_id: specialId } = useParams<{
    special_id: string;
  }>();

  const {
    appInfo: { bundlePossibilitiesState },
    cart,
    dispatches: { openCart },
    paths,
    store,
  } = useMenu();

  const { specials = [], loadingSpecials } = useSpecials();

  const currentSpecial = specials.find(
    (special) => String(special.id) === specialId
  );

  const { data: qualifyingStatusData, refetch: fetchStatus } =
    useGetSpecialQualifyingStatus({
      cartUuid: cart.uuid || '0',
      special: currentSpecial,
      storeId: store.id,
    });

  const qualifyingStatus = qualifyingStatusData?.qualifying_status;

  const bundlePossibilities = bundlePossibilitiesState?.bundlePossibilities;
  const filterDiscountableProducts =
    bundlePossibilitiesState?.filterDiscountableProducts ?? false;
  const bundleFilter = buildDiscountableProductsFilter(
    filterDiscountableProducts,
    Array.from(bundlePossibilities?.discountable_product_ids ?? [])
  );

  const storeId = store?.id;

  const { special_type } = currentSpecial || {};
  const isBulk = special_type === 'bulk_pricing';
  const isBundle = special_type === 'bundle';
  const isCartTotal = special_type === 'cart_total';
  const isProduct = special_type === 'product';
  const pageTitle = currentSpecial?.title || '';

  const defaultStatusData = {
    qualifying_status: 'not_qualified',
    special: {
      id: specialId,
      description: currentSpecial?.description,
      title: currentSpecial?.title,
      special_type: currentSpecial?.special_type,
      photo: currentSpecial?.photo,
      store_id: storeId,
    },
  };

  useEffect(() => {
    document
      .getElementById('progress-container-scroll-position')
      ?.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });

    if (qualifyingStatus === 'applied') {
      openCart();
    }
  }, [qualifyingStatus]);

  useEffect(() => {
    fetchStatus();
  }, [cart.uuid, cart.products]);

  const specialFilter = getSpecialType(
    currentSpecial?.special_type,
    qualifyingStatus
  );

  const applicablePriceIds = getApplicablePriceIds(
    currentSpecial,
    qualifyingStatus
  );

  const staticFilters = useComposeMenuFilters([
    `applicable_${specialFilter}:${currentSpecial?.id}`,
    bundleFilter,
  ]);

  const fromFeatured = state?.breadcrumbs?.some(
    (crumb: Breadcrumb) => crumb.title === 'Featured'
  );

  const breadcrumbs = [
    { url: '/', title: 'Home' },
    { url: paths.menu(), title: store.name },
    ...insertIfArr(!fromFeatured, {
      url: paths.menuCategory('specials'),
      title: store.specials_row_configs?.custom_display_name || 'Specials',
    }),
  ];

  const nextBreadcrumbs = [
    {
      url: paths.menuCategory('specials'),
      title: store.specials_row_configs?.custom_display_name || 'Specials',
    },
    {
      title: pageTitle,
      url: paths.menuSpecial({
        id: currentSpecial?.id || 0,
        title: pageTitle,
      }),
    },
  ];

  const additionalProductFilter = useCallback(
    (products: SmartSortProduct<AlgoliaProduct>[]) => {
      return filterProductsByWeight({
        cartProducts: cart.products as PendingCartProduct[],
        currentSpecial,
        products,
        qualifyingStatus: qualifyingStatusData,
      });
    },
    [cart.products, currentSpecial, qualifyingStatusData]
  );

  const weightFilter = useCallback(
    (weight: string, product: MenuProduct | AlgoliaProduct) =>
      shouldShowWeight({
        currentSpecial,
        cartProducts: cart.products as PendingCartProduct[],
        qualifyingStatus: qualifyingStatusData,
        showOnlyWeights: applicablePriceIds ?? [],
      })(weight, product),
    [currentSpecial, cart.products, qualifyingStatusData, applicablePriceIds]
  );

  const maxWeightOption =
    typeof qualifyingStatusData?.target_weight === 'number' &&
    typeof qualifyingStatusData?.product_weight === 'number'
      ? qualifyingStatusData.target_weight - qualifyingStatusData.product_weight
      : null;

  const shouldUseFilters = specialsWeight && (isBulk || isBundle || isProduct);

  if (!currentSpecial && !loadingSpecials) {
    return <Navigate to={paths.menu()} replace />;
  }

  return (
    <Box>
      <Flex
        flexDirection="column"
        px={isMobile ? 0 : 64}
        pb={isMobile ? 24 : 48}
        gap={isMobile ? 0 : 24}
      >
        {!isMobile && (
          <MenuBreadcrumbs
            breadcrumbs={breadcrumbs}
            title={pageTitle}
            pb={24}
          />
        )}

        {currentSpecial && <MenuSpecialDetailHeader special={currentSpecial} />}

        {(isBulk || isCartTotal) && (
          <MenuSpecialDetailProgressBar
            status={
              (qualifyingStatusData || defaultStatusData) as QualifyingStatus
            }
            special={currentSpecial as StoreSpecial}
          />
        )}

        {isBundle && (
          <MenuSpecialDetailBundleProgress
            status={
              (qualifyingStatusData || defaultStatusData) as QualifyingStatus
            }
            special={currentSpecial as StoreSpecial}
          />
        )}
      </Flex>

      {currentSpecial && (
        <Box>
          <MenuTitle px={isMobile ? 24 : 64} />
          <MenuFiltersAndProductsGrid
            {...(shouldUseFilters && {
              additionalProductFilter,
              maxWeightOption,
              weightFilter,
            })}
            breadcrumbs={nextBreadcrumbs}
            menuCategory="specials"
            pageTitle={pageTitle}
            showOnlyWeights={applicablePriceIds}
            staticFilters={staticFilters}
            withoutDrawerOnAddToCart
          />
        </Box>
      )}
    </Box>
  );
};
