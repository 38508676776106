import styled from '@emotion/styled';
import { useState } from 'react';
import type { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

import { UserPreferencesModal } from '@jane/shared-ecomm/components';
import { useUserPreferences } from '@jane/shared-ecomm/providers';
import { useSafeState } from '@jane/shared/hooks';
import {
  Box,
  Button,
  DismissIcon,
  Flex,
  JaneLogo,
  Modal,
  Typography,
  useTabletMediaQuery,
} from '@jane/shared/reefer';
import { SessionStorage } from '@jane/shared/util';

import { MESSAGE_ID } from './userLocationModal';

const DISMISSED_KEY = 'dismissedLocationModal';

const TransformedBox = styled(Box)({
  transform: 'translate(-50%, 0%)',
});

const PATHS_BLACKLIST = ['forgot-password', 'reset-password'];

const UserLocation = ({ children }: { children?: ReactNode }) => {
  const { userLocation } = useUserPreferences();
  const { pathname } = useLocation();
  const [showModal, setShowModal] = useSafeState(
    SessionStorage.get(DISMISSED_KEY) === 'true'
      ? false
      : !userLocation.coordinates
  );

  const isMobile = useTabletMediaQuery({ width: 'max' });

  const [showMobileModal, setShowMobileModal] = useState(isMobile);

  const dismissModal = () => {
    setShowModal(false);
    SessionStorage.set(DISMISSED_KEY, 'true');
  };

  return (
    <>
      {children}
      {showModal &&
        !PATHS_BLACKLIST.includes(pathname.split('/')[1]) &&
        !userLocation.hasResetLocation &&
        !userLocation.coordinates && (
          <>
            {!showMobileModal && (
              <UserPreferencesModal
                onCloseModal={dismissModal}
                showModal={showModal}
              />
            )}

            {showMobileModal && (
              <Modal
                onRequestClose={dismissModal}
                open={true}
                overflow="visible"
                variant="dialogue"
              >
                <Modal.Content>
                  <Flex
                    aria-describedby={MESSAGE_ID}
                    flexDirection="column"
                    justifyContent="center"
                  >
                    <TransformedBox
                      position="absolute"
                      top={13}
                      mb={16}
                      left="50%"
                    >
                      <JaneLogo size="sm" />
                    </TransformedBox>

                    <Button.Icon
                      aria-label="close"
                      icon={<DismissIcon />}
                      ml="auto"
                      mr={-16}
                      mt={-16}
                      onClick={dismissModal}
                      data-testid="close-button"
                    />

                    <Typography
                      branded
                      mt={24}
                      mb={16}
                      textAlign="center"
                      variant="header-bold"
                      id="location-message"
                    >
                      Find products at stores near you.
                    </Typography>
                    <Button
                      branded
                      full
                      label="Enter your location"
                      onClick={() => setShowMobileModal(false)}
                    />
                  </Flex>
                </Modal.Content>
              </Modal>
            )}
          </>
        )}
    </>
  );
};

export default UserLocation;
