import { useEffect, useRef } from 'react';

import { useScrollRestoration } from '@jane/shared/providers';

import { useDoneResizing } from '../useDoneResizing';

export const useRestoreScrollPosition = () => {
  const doneResizing = useDoneResizing();

  const hasRestoredScroll = useRef<boolean>(false);

  const { restoreScrollPosition, isIframe } = useScrollRestoration();

  const restoreScroll =
    (isIframe || doneResizing) && !hasRestoredScroll.current;

  useEffect(() => {
    // This prevents the browser from trying to do its own scroll restoration
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }

    return () => {
      if ('scrollRestoration' in window.history) {
        window.history.scrollRestoration = 'auto';
      }
    };
  }, []);

  useEffect(() => {
    if (restoreScroll) {
      hasRestoredScroll.current = true;
      restoreScrollPosition(window.location.href);
    }
  }, [restoreScroll]);
};
