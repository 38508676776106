import Modal from '../modal';
import LogoutForm from './form';

interface Props {
  onLogout(): void;
}

const LogoutModal = ({ onLogout }: Props) => {
  return (
    <Modal className="modal--small" contentLabel="Sign out" fixedBody>
      {(closeModal) => (
        <div className="modal-content">
          <LogoutForm
            embeddedMode={false}
            onCancel={closeModal}
            onLogout={onLogout}
          />
        </div>
      )}
    </Modal>
  );
};

export default LogoutModal;
