import { Outlet } from 'react-router-dom';

import { SearchProvider } from '@jane/search/providers';
import {
  EmptyMenuProductGrid,
  PageLayout,
} from '@jane/shared-ecomm/components';
import {
  SpecialsProvider,
  useMenu,
  useSpecials,
} from '@jane/shared-ecomm/providers';
import { getMenuTabs } from '@jane/shared-ecomm/util';
import { useMobileMediaQuery } from '@jane/shared/reefer';

import { MenuBanners } from './MenuComponents/MenuBanners/MenuBanners';
import { MenuHeader } from './MenuComponents/MenuHeader/MenuHeader';
import { MenuResetLocationLink } from './MenuComponents/MenuResetLocationLink/MenuResetLocationLink';
import { MenuSeo } from './MenuComponents/MenuSeo/MenuSeo';
import { MenuSpecialBundleEnticement } from './MenuComponents/MenuSpecialBundleEnticement/MenuSpecialBundleEnticement';

const MenuInner = () => {
  const isMobile = useMobileMediaQuery({});
  const { facets, store } = useMenu();
  const { specialIds } = useSpecials();

  const specialsPage =
    (store.specials_row_configs?.enabled ||
      store.custom_rows?.includes('sale')) &&
    Boolean(specialIds?.length);

  const menuTabs = getMenuTabs({
    facets,
    menuTabs: store.menu_tabs,
    specialsPage,
  });

  return (
    <>
      <MenuSeo />
      <MenuSpecialBundleEnticement />
      <MenuHeader tabs={menuTabs} />
      <PageLayout id="content" py={isMobile ? 24 : 48}>
        <MenuResetLocationLink />
        <MenuBanners tabs={menuTabs} />
        <EmptyMenuProductGrid />
        <Outlet />
      </PageLayout>
    </>
  );
};

export const Menu = () => {
  const { searchStateWithInitialQuery, onSearchStateChange } = useMenu();

  return (
    <SpecialsProvider fetchSpecialsRow>
      <SearchProvider
        indexPrefix="menu-products-"
        initialSearchState={searchStateWithInitialQuery}
        onSearchStateChange={onSearchStateChange}
      >
        <MenuInner />
      </SearchProvider>
    </SpecialsProvider>
  );
};
