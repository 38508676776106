import { truncateLabel } from '@jane/shared-ecomm/util';
import type { MarginProperties } from '@jane/shared/reefer';
import { Box, Flex } from '@jane/shared/reefer';

import {
  StyledButton,
  StyledImage,
  StyledTypography,
} from './imageCommsBanner.styles';

export interface ImageCommsBannerProps extends MarginProperties {
  buttonFillColor: string;
  buttonLink?: string;
  buttonText?: string;
  buttonTextColor: string;
  fallbackSrc?: string;
  headerTextColor: string;
  imageAltText?: string | null;
  imageOpacity?: number | null;
  imageUrl?: string;
  message?: string | null;
  sizes?: string;
  srcSet?: string;
  target?: string;
  title?: string | null;
}

export function ImageCommsBanner({
  buttonFillColor,
  buttonLink,
  buttonText,
  buttonTextColor,
  fallbackSrc,
  headerTextColor,
  imageAltText,
  imageOpacity,
  imageUrl,
  message,
  sizes,
  srcSet,
  target = '_blank',
  title,
  ...spacingProps
}: ImageCommsBannerProps) {
  const bannerHeight = '240px';
  const buttonLabel = buttonText
    ? `${truncateLabel(buttonText, 40)}`
    : undefined;
  return (
    <Box
      position="relative"
      height={bannerHeight}
      width="100%"
      borderRadius="lg"
      overflow="hidden"
      {...spacingProps}
      data-testid="menu-comms-image-banner"
    >
      <Flex
        position="absolute"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        height="100%"
        width="100%"
        zIndex="var(--layers-visible)"
        p={24}
      >
        {title && (
          <StyledTypography
            truncateAt="100%"
            branded
            as="h1"
            variant="header-bold"
            textColor={headerTextColor}
            textAlign="center"
          >
            {title}
          </StyledTypography>
        )}
        {message && (
          <StyledTypography
            maxLines={2}
            branded
            textColor={headerTextColor}
            textAlign="center"
          >
            {message}
          </StyledTypography>
        )}
        {buttonLink && (
          <StyledButton
            mt={16}
            branded
            backgroundColor={buttonFillColor}
            color={buttonTextColor}
            label={buttonLabel}
            href={buttonLink}
            target={target}
          />
        )}
      </Flex>
      {!imageUrl ? (
        <Box
          width="100%"
          height={bannerHeight}
          background="primary-dark"
          data-testid="comms-banner-fallback"
        />
      ) : (
        <StyledImage
          height={bannerHeight}
          altText={imageAltText || ''}
          src={imageUrl}
          responsive={!srcSet}
          fallbackSrc={fallbackSrc}
          srcSet={srcSet}
          sizes={sizes}
          opacity={imageOpacity ? imageOpacity / 100 : 1}
          objectFit="cover"
          objectPosition="center"
          loading="eager"
          shouldHaveBackground={!!message || !!title}
        />
      )}
    </Box>
  );
}
