import type {
  AppMode,
  FetchCartTopperRowProps,
  FetchMenuRowProps,
  FetchRecommendedPDPRowProps,
  FetchRecommendedRowProps,
  FetchRecommendedSortProps,
  JaneDM,
  SmartSort,
  SmartSortProduct,
} from '@iheartjane/dm-sdk';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import cloneDeep from 'lodash/cloneDeep';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import type { AlgoliaProduct } from '@jane/search/types';
import { FLAGS, useFlag } from '@jane/shared/feature-flags';

import type { DmSdkSource } from './useDmSdk';
import { useDmSdk } from './useDmSdk';
import { useGetJaneDMIdentifiers } from './useGetJaneDMIdentifiers';

export interface UseSmartSortResponse<
  ProductShape extends object = AlgoliaProduct
> {
  fetchNextPage: () => Promise<void>;
  hasNextPage: boolean;
  instance: SmartSort<ProductShape> | undefined;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  numHits: number;
  /**
   * @deprecated Use `instance.products` instead
   */
  products: SmartSortProduct<ProductShape>[];
  searchResultFacets: Record<string, Record<string, number>>;
}

export type UseSmartSortProps<T = unknown> = T & {
  appMode: AppMode;
  dependencies: (string | number | undefined | number[])[];
  distinctId?: string;
  enabled?: boolean;
  jdid?: string;
  source?: DmSdkSource;
};

export const useSmartMagicRow = <ProductShape extends object = AlgoliaProduct>(
  props: Omit<UseSmartSortProps<FetchRecommendedRowProps>, 'dependencies'>
): UseSmartSortResponse<ProductShape> => {
  const {
    searchAttributes,
    appMode,
    jdid,
    distinctId,
    storeId,
    searchFilter,
    searchOptionalFilters,
    source,
  } = props;

  const dependencies = [storeId, searchFilter, searchOptionalFilters];

  const fetchPlacement = useCallback(
    async (sdk: JaneDM) =>
      await sdk.fetchRecommendedRow<ProductShape>({
        searchAttributes,
        searchFilter,
        searchOptionalFilters,
        storeId,
      }),
    dependencies
  );

  return useSmartSort(
    { appMode, dependencies, distinctId, jdid, source },
    fetchPlacement
  );
};

export const useSmartCartTopperRow = <
  ProductShape extends object = AlgoliaProduct
>(
  props: Omit<UseSmartSortProps<FetchCartTopperRowProps>, 'dependencies'>
): UseSmartSortResponse<ProductShape> => {
  const {
    appMode,
    distinctId,
    jdid,
    storeId,
    cartProductIds,
    searchAttributes,
    source,
  } = props;

  const dependencies = [storeId, cartProductIds];

  const fetchPlacement = useCallback(
    async (sdk: JaneDM) =>
      await sdk.fetchCartTopperRow<ProductShape>({
        cartProductIds,
        searchAttributes,
        storeId,
      }),
    dependencies
  );

  const enabled = useMemo(() => cartProductIds.length !== 0, dependencies);

  return useSmartSort(
    { appMode, dependencies, distinctId, enabled, jdid, source },
    fetchPlacement
  );
};

export const useRecommendedSort = <
  ProductShape extends object = AlgoliaProduct
>(
  props: Omit<UseSmartSortProps<FetchRecommendedSortProps>, 'dependencies'>
): UseSmartSortResponse<ProductShape> => {
  const {
    appMode,
    enabled,
    jdid,
    disableAds,
    distinctId,
    maxProducts,
    numColumns,
    pageSize,
    searchAttributes,
    searchFacets = [
      'activities',
      'aggregate_rating',
      'applicable_special_ids',
      'available_weights',
      'brand_subtype',
      'brand',
      'bucket_price',
      'category',
      'feelings',
      'has_brand_discount',
      'kind',
      'percent_cbd',
      'percent_thc',
      'root_types',
      'store_id',
    ],
    searchFilter,
    searchOptionalFilters,
    searchQuery = '',
    searchSort,
    storeId,
    source,
  } = props;

  const dependencies = [
    maxProducts,
    storeId,
    searchFilter,
    searchQuery,
    searchSort,
  ];

  const fetchPlacement = useCallback(
    async (sdk: JaneDM) =>
      await sdk.fetchRecommendedSort<ProductShape>({
        disableAds,
        maxProducts,
        numColumns,
        pageSize,
        searchAttributes,
        searchFacets,
        searchFilter,
        searchOptionalFilters,
        searchQuery,
        searchSort,
        storeId,
      }),
    dependencies
  );

  return useSmartSort(
    { appMode, dependencies, distinctId, enabled, jdid, source },
    fetchPlacement
  );
};

export const useSmartMenuRow = <ProductShape extends object = AlgoliaProduct>(
  props: Omit<UseSmartSortProps<FetchMenuRowProps>, 'dependencies'>
): UseSmartSortResponse<ProductShape> => {
  const {
    appMode,
    enabled,
    jdid,
    disableAds,
    distinctId,
    numColumns,
    searchAttributes,
    searchFilter,
    searchOptionalFilters,
    searchQuery = '',
    searchSort,
    storeId,
    source,
  } = props;

  const dependencies = [storeId, searchSort, searchFilter];

  const fetchPlacement = useCallback(
    async (sdk: JaneDM) =>
      await sdk.fetchMenuRow<ProductShape>({
        disableAds,
        numColumns,
        searchAttributes,
        searchFilter,
        searchOptionalFilters,
        searchQuery,
        searchSort,
        storeId,
      }),
    dependencies
  );

  return useSmartSort(
    { appMode, dependencies, distinctId, enabled, jdid, source },
    fetchPlacement
  );
};

export const useSmartRecommendedPDPRow = <
  ProductShape extends object = AlgoliaProduct
>(
  props: Omit<
    UseSmartSortProps<FetchRecommendedPDPRowProps>,
    'dependencies' | 'productId' | 'posProductId'
  > & { productId: number }
): UseSmartSortResponse<ProductShape> => {
  const {
    appMode,
    jdid,
    distinctId,
    storeId,
    source,
    productId,
    searchAttributes,
  } = props;

  const dependencies = [productId];

  const fetchPlacement = useCallback(
    async (sdk: JaneDM) =>
      await sdk.fetchRecommendedPDPRow<ProductShape>({
        productId,
        searchAttributes,
        storeId,
      }),
    dependencies
  );

  return useSmartSort(
    { appMode, dependencies, distinctId, jdid, source },
    fetchPlacement
  );
};

const useSmartSortOld = <ProductShape extends object = AlgoliaProduct>(
  props: UseSmartSortProps,
  fetchPlacement: (sdk: JaneDM) => Promise<SmartSort<ProductShape>>
): UseSmartSortResponse<ProductShape> => {
  const smartSortCaching = useFlag(FLAGS.smartSortUpdates);
  const { appMode, distinctId, jdid, source, enabled = true } = props;

  const sdk = useDmSdk({
    appMode,
    identifier: useGetJaneDMIdentifiers({
      jdid,
      mixpanelDistinctId: distinctId,
    }),
    source,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [smartSortInstance, setSmartSortInstance] = useState<
    SmartSort<ProductShape> | undefined
  >(undefined);

  const fetchPlacements = useCallback(async () => {
    setIsLoading(true);
    setIsError(false);
    setIsSuccess(false);
    setSmartSortInstance(undefined);

    try {
      const response =
        enabled && !smartSortCaching ? await fetchPlacement(sdk) : undefined;
      setSmartSortInstance(response);
      setIsSuccess(true);
    } catch {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, [fetchPlacement, enabled, sdk]);

  const fetchNextPage = useCallback(async () => {
    if (smartSortInstance && smartSortInstance.hasNextPage()) {
      setIsLoading(true);
      setIsError(false);
      setIsSuccess(false);
      try {
        await smartSortInstance.nextPage();
        setIsSuccess(true);
      } catch {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    }
  }, [smartSortInstance]);

  useEffect(() => {
    fetchPlacements();
  }, [fetchPlacements]);

  const smartSortResponse = useMemo(
    () => ({
      fetchNextPage,
      hasNextPage: smartSortInstance?.hasNextPage() ?? false,
      instance: smartSortInstance,
      isError,
      isLoading,
      isSuccess,
      numHits: smartSortInstance?.totalProducts ?? 0,
      products: smartSortInstance?.products ?? [],
      searchResultFacets: smartSortInstance?.searchFacets ?? {},
    }),
    [smartSortInstance, isError, isLoading, isSuccess, fetchNextPage]
  );

  return smartSortResponse;
};

const useSmartSortNew = <ProductShape extends object = AlgoliaProduct>(
  props: UseSmartSortProps,
  fetchPlacement: (sdk: JaneDM) => Promise<SmartSort<ProductShape>>
): UseSmartSortResponse<ProductShape> => {
  const smartSortCaching = useFlag(FLAGS.smartSortUpdates);
  const queryClient = useQueryClient();
  const instanceRef = useRef<SmartSort>();
  const {
    appMode,
    dependencies,
    enabled = true,
    distinctId,
    jdid,
    source,
  } = props;

  const identifier = useGetJaneDMIdentifiers({
    jdid,
    mixpanelDistinctId: distinctId,
  });

  const sdk = useDmSdk({ appMode, identifier, source });

  const queryKey = ['smart-sort', jdid, ...dependencies];

  const smartSortResult = useQuery({
    enabled: enabled && smartSortCaching,
    queryFn: async () => await fetchPlacement(sdk),
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey,
    staleTime: 5 * 60 * 1000,
  });

  useEffect(() => {
    if (!smartSortResult?.isStale && smartSortResult?.data) {
      instanceRef.current = smartSortResult.data;
    }
  }, [smartSortResult.data]);

  const fetchNextPage = async () => {
    await instanceRef.current?.nextPage();

    queryClient.setQueryData(queryKey, cloneDeep(instanceRef.current));
  };

  return {
    fetchNextPage,
    hasNextPage: smartSortResult.data?.hasNextPage() ?? false,
    instance: smartSortResult.data,
    isError: smartSortResult.isError,
    isLoading: smartSortResult.isLoading,
    isSuccess: smartSortResult.isSuccess,
    numHits: smartSortResult.data?.totalProducts ?? 0,
    products: smartSortResult.data?.products ?? [],
    searchResultFacets: smartSortResult.data?.searchFacets ?? {},
  };
};

const useSmartSort = <ProductShape extends object = AlgoliaProduct>(
  props: UseSmartSortProps,
  fetchPlacement: (sdk: JaneDM) => Promise<SmartSort<ProductShape>>
) => {
  const smartSortCaching = useFlag(FLAGS.smartSortUpdates);

  const newSmartSort = useSmartSortNew<ProductShape>(props, fetchPlacement);
  const oldSmartSort = useSmartSortOld<ProductShape>(props, fetchPlacement);

  return smartSortCaching ? newSmartSort : oldSmartSort;
};
