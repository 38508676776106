import { useState } from 'react';

import { PageLayout } from '@jane/shared-ecomm/components';
import { useMenu } from '@jane/shared-ecomm/providers';
import { useMobileMediaQuery } from '@jane/shared/reefer';
import type { MenuCategoryPath } from '@jane/shared/types';

import { useGetPage } from '../../hooks/useGetPage';
import { MenuTabs } from '../MenuTabs/MenuTabs';
import { MenuAboutModal } from './MenuAboutModal';
import { StyledBox } from './MenuHeader.styles';
import { MenuHeaderMax } from './MenuHeaderMax';
import { MenuHeaderMin } from './MenuHeaderMin';

export const MenuHeader = ({ tabs }: { tabs: MenuCategoryPath[] }) => {
  const page = useGetPage();
  const [openAboutModal, setOpenAboutModal] = useState(false);

  const {
    appInfo: { appMode, isEmbed },
  } = useMenu();
  const isMobile = useMobileMediaQuery({});

  const showMinHeader = [
    'brands',
    'brandDetail',
    'featuredCategory',
    'janeGold',
    'specialDetail',
  ].includes(page);

  const handleOpenModal = () => setOpenAboutModal(true);

  const renderHeaderComponent = () => {
    return showMinHeader ? (
      <MenuHeaderMin handleOpenModal={handleOpenModal} />
    ) : (
      <MenuHeaderMax handleOpenModal={handleOpenModal} />
    );
  };

  return (
    <StyledBox
      as="header"
      background="grays-ultralight"
      border="grays-light"
      borderWidth="1px"
      isMarketplace={appMode === 'default'}
    >
      {!isEmbed && (
        <PageLayout px={isMobile ? 24 : 64}>
          {renderHeaderComponent()}
        </PageLayout>
      )}

      {!showMinHeader && <MenuTabs tabs={tabs} />}
      <MenuAboutModal
        open={openAboutModal}
        onRequestClose={() => setOpenAboutModal(false)}
      />
    </StyledBox>
  );
};
