import { Helmet } from 'react-helmet-async';

type StoreScript = {
  content: string;
  dateImported: string;
  type: string;
};

function getStoreScripts(storeId: number | string) {
  const storeScriptsConfig: Record<
    string,
    StoreScript[]
    // eslint-disable-next-line @typescript-eslint/no-var-requires
  > = require('shared/config/store-scripts.json');
  const storeScripts = storeScriptsConfig[storeId];
  return storeScripts;
}

type StoreScriptsProps = {
  storeId: number | string;
};

export const StoreScripts = ({ storeId }: StoreScriptsProps) => {
  const storeScripts = getStoreScripts(storeId);

  const scriptType = 'text/javascript';

  if (!storeScripts) return null;

  const srcScripts = storeScripts
    .filter((s) => s.type === 'src')
    .map(({ type, content }) => ({ type: scriptType, src: content }));

  const contentScripts = storeScripts
    .filter((s) => s.type !== 'src')
    .map(({ type, content }) => ({ type: scriptType, innerHTML: content }));

  return <Helmet script={[...srcScripts, ...contentScripts]} />;
};
