import styled from '@emotion/styled';
import omit from 'lodash/omit';
import { useEffect, useState } from 'react';
import { InView } from 'react-intersection-observer';
import { useLocation, useNavigate } from 'react-router-dom';

import { useGetJaneDMIdentifiers } from '@jane/dm/internal';
import type { AlgoliaProduct } from '@jane/search/types';
import { buildClickTrackingEvent } from '@jane/shared-ecomm/components';
import { useDeepCompareMemo } from '@jane/shared-ecomm/hooks';
import { useUserPreferences } from '@jane/shared-ecomm/providers';
import {
  EventNames,
  track,
  trackProductListingTap,
} from '@jane/shared-ecomm/tracking';
import { LegacyMenuProductCard } from '@jane/shared/components';
import type { LegacyMenuProductCardProps } from '@jane/shared/components';
import { useUserSegmentIds } from '@jane/shared/data-access';
import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import type { MenuProduct, StoreSpecial, Zone } from '@jane/shared/models';
import { ZoneCart } from '@jane/shared/models';
import { useEcommApp } from '@jane/shared/providers';
import { Box } from '@jane/shared/reefer';
import {
  deserializeCannabinoids,
  findSpecialForProduct,
} from '@jane/shared/util';

import { useCustomerDispatch } from '../../../customer/dispatch';
import {
  attemptToAddToCart,
  closeCart,
  deleteCartItem,
} from '../../../customer/redux/cart';
import { setProductDetailBreadcrumbs } from '../../../customer/redux/search';
import { useCustomerSelector } from '../../../customer/selectors';
import { paths } from '../../../lib/routes';
import { get } from '../../../redux-util/selectors';
import { BundleSpecialEnticementContent } from '../../bundleSpecialEnticementContent';
import { EnticementBubble } from '../../enticementBubble';

const DEFAULT_PRODUCT_CARD_WIDTH = 200;

const InViewFlex = styled(InView)({
  display: 'flex',
  flexDirection: 'column',
});

export type HitProductCardProps = Omit<
  LegacyMenuProductCardProps,
  | 'appMode'
  | 'brand'
  | 'cartIsOpen'
  | 'cartProducts'
  | 'cartStoreId'
  | 'currentSpecial'
  | 'itemWidth'
  | 'onClick'
  | 'product'
  | 'routePartnerHostedConfig'
  | 'userLocation'
> & {
  algoliaIndexName: string;
  bucketName?: string;
  carouselView?: boolean;
  columnPosition?: number;
  hit: AlgoliaProduct;
  itemWidth?: number;
  numColumns?: number;
  productLocation?: string;
  rowPosition?: number;
  specials?: StoreSpecial[];
  zone: Zone;
};

const HitProductCard = ({
  algoliaIndexName,
  bucketName,
  columnPosition,
  hit,
  productLocation,
  rowPosition,
  specials,
  zone,
  numColumns,
  productInstance,
  ...props
}: HitProductCardProps) => {
  const navigate = useNavigate();
  const dispatch = useCustomerDispatch();
  const { userLocation, userPreferences = {} } = useUserPreferences();
  const { storeFulfillmentType, storeSearchRadius, storeType } =
    userPreferences;
  const { pathname } = useLocation();
  const { appMode } = useEcommApp();
  const { jdid } = useGetJaneDMIdentifiers();

  const {
    brand,
    cart,
    store: cartStore,
  } = useCustomerSelector(
    ({ brand: { brand }, cart: { cart }, customer: { id } }) => ({
      brand,
      cart,
      store: cart.store,
      userId: id,
    })
  );
  const cartIsOpen = cart.is_open;
  const cartProducts = cart.products;
  const cartStoreId = cartStore?.id;

  const { touchedProductId, bundlePossibilities } = useCustomerSelector(
    get('bundlePossibilities')
  );

  const { store, carouselView, listView, itemWidth } = props;

  const {
    city: storeCity,
    state: storeState,
    name: storeName,
    id: storeId,
  } = store;

  const [bundleSpecial, setBundleSpecial] = useState<StoreSpecial | undefined>(
    undefined
  );

  const menuProduct = useDeepCompareMemo<any>(() => {
    const mp = {
      ...deserializeCannabinoids(hit, 'menuProduct'),
      id: hit['product_id'],
    };
    return omit(mp, 'product_id');
  }, [hit]);

  useEffect(() => {
    const matchesTouchedProduct = touchedProductId === menuProduct.id;
    const bundleSpecial =
      specials && specials.length > 0 && matchesTouchedProduct
        ? specials.find((s) => s.id === bundlePossibilities.special_id)
        : undefined;

    setBundleSpecial(bundleSpecial);
  }, [touchedProductId, bundlePossibilities]);

  const janeGoldSegmentation = useFlag(FLAGS.janeGoldUserSegmentation);

  const userSegments = useUserSegmentIds();

  const clickTrackingEvent = buildClickTrackingEvent({
    indexName: algoliaIndexName,
    store,
    menuProduct,
    carouselView,
    rowPosition,
    columnPosition,
    listView,
    menuRowName: bucketName,
    userSegments: janeGoldSegmentation ? userSegments : undefined,
  });

  const enticementBubbleWidth = itemWidth || DEFAULT_PRODUCT_CARD_WIDTH;

  const hideEnticements = zone === ZoneCart && store.in_stacking_beta;

  return (
    <InViewFlex
      data-testid="hit-product-card"
      delay={1000}
      onChange={(isInView) => {
        if (isInView) {
          productInstance.impress();
        }
      }}
      threshold={0.5}
      triggerOnce
    >
      <LegacyMenuProductCard
        appMode={appMode}
        brand={brand}
        cartIsOpen={cartIsOpen}
        cartProducts={cartProducts}
        cartStoreId={cartStoreId}
        currentSpecial={findSpecialForProduct(menuProduct, specials, appMode)}
        product={menuProduct}
        productInstance={productInstance}
        onAddToCart={(addData) => {
          dispatch(
            attemptToAddToCart({
              ...addData,
              menuProduct: addData.menuProduct as MenuProduct,
              navigate,
              source: pathname,
              userPreferences: {
                cityState: userLocation.cityState,
                storeFulfillmentType: userPreferences.storeFulfillmentType,
                storeSearchRadius: userPreferences.storeSearchRadius,
                storeType: userPreferences.storeType,
                zipcode: userLocation.zipcode,
              },
              userSegments: janeGoldSegmentation ? userSegments : undefined,
            })
          );
          productInstance.click({ isAddToCart: true });
        }}
        onDeleteFromCart={(deleteData) => {
          dispatch(
            deleteCartItem(deleteData.itemId, deleteData.selectedWeight)
          );
        }}
        onCloseCart={() => dispatch(closeCart())}
        onSetBreadcrumbs={(searchState) =>
          dispatch(setProductDetailBreadcrumbs(searchState))
        }
        onClick={() => {
          productInstance.click();
          track(clickTrackingEvent);
          if (productInstance.isSponsored) {
            track({ event: EventNames.ClickedAd, janeDeviceId: jdid });
          }
          trackProductListingTap({
            cityState: userLocation?.cityState,
            product: menuProduct,
            productLocation,
            source: pathname,
            storeId: String(storeId),
            storeName,
            storeCity,
            storeState,
            storeFulfillmentType,
            storeSearchRadius,
            storeType,
            userSegments: janeGoldSegmentation ? userSegments : undefined,
            zipcode: userLocation?.zipcode,
          });
        }}
        {...props}
        routeAppMode={paths.getAppMode()}
        routePartnerHostedConfig={paths.getPartnerHostedConfig()}
        store={store}
        userLocation={userLocation}
      />
      {!hideEnticements && bundleSpecial && !listView && (
        <Box
          px={8}
          maxWidth={enticementBubbleWidth}
          minWidth={enticementBubbleWidth}
        >
          <EnticementBubble onClose={() => setBundleSpecial(undefined)}>
            <BundleSpecialEnticementContent
              appMode={appMode}
              bundlePossibilities={bundlePossibilities}
              special={bundleSpecial}
              store={store}
            />
          </EnticementBubble>
        </Box>
      )}
    </InViewFlex>
  );
};

export default HitProductCard;
