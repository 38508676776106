import type { SVGProps } from 'react';

import { Icon } from '../../icon/icon';
import type { LogoProps } from '../logo.types';

const Aeropay = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 96 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23.0938 63.2016V62.7528L23.3829 62.3937L23.4274 62.2366C23.3162 62.3713 23.1828 62.4835 23.0493 62.5957C22.3822 63.0894 21.5372 63.3363 20.5142 63.3363C19.9138 63.3363 19.3356 63.2241 18.7797 62.9772C18.2238 62.7303 17.7345 62.4162 17.312 61.9898C16.8895 61.5634 16.5559 61.0696 16.3336 60.5086C16.1112 59.9475 16 59.364 16 58.7581C16 58.1522 16.1112 57.5462 16.3336 56.9852C16.5559 56.4241 16.9117 55.908 17.3343 55.4816C17.7345 55.0552 18.2238 54.6961 18.7797 54.4717C19.3356 54.2473 19.9138 54.1126 20.492 54.1351C21.5372 54.1351 22.4044 54.4044 23.0716 54.9205C23.1828 55.0103 23.3162 55.1225 23.4051 55.2123L23.3607 55.0776L23.0716 54.7186V54.2024H25.4732V63.1792H23.0938V63.2016ZM20.6921 56.3344C20.3808 56.3344 20.0695 56.3793 19.7804 56.5139C19.4913 56.6261 19.2245 56.8281 19.0243 57.0525C18.5796 57.5238 18.3572 58.1297 18.3572 58.7581C18.3572 59.3865 18.6018 60.0149 19.0243 60.4637C19.2467 60.6881 19.4913 60.8677 19.7804 61.0023C20.0695 61.1145 20.3808 61.1818 20.6921 61.1818C21.0035 61.1818 21.337 61.137 21.6261 61.0023C21.9152 60.8901 22.2043 60.7106 22.4267 60.4637C22.6491 60.2393 22.827 59.97 22.9382 59.6782C23.0493 59.3865 23.1161 59.0723 23.1161 58.7581C23.1161 58.4439 23.0493 58.1297 22.9382 57.838C22.827 57.5462 22.6491 57.2769 22.4044 57.0525C22.1821 56.8281 21.9152 56.6486 21.6261 56.5139C21.337 56.4017 21.0257 56.3344 20.6921 56.3344Z"
      fill="black"
    />
    <path
      d="M35.2356 61.2941C34.3016 62.7079 32.9006 63.4261 31.0104 63.4261C29.6094 63.4261 28.4753 62.9772 27.6081 62.102C26.763 61.1819 26.3183 59.9924 26.3183 58.7581C26.3183 57.5238 26.7853 56.3119 27.6303 55.4143C28.5198 54.5166 29.6317 54.0677 31.0104 54.0677C32.3892 54.0677 33.4788 54.4941 34.3016 55.3469C35.1244 56.1997 35.5247 57.2994 35.5247 58.6235V58.9826C35.5247 59.1172 35.5024 59.2294 35.5024 59.3192V59.4763H28.6977C28.8089 60.0149 29.098 60.5086 29.5205 60.8452C29.9653 61.1819 30.499 61.3614 31.0549 61.339C31.5219 61.3614 31.9666 61.2492 32.3892 61.0472C32.8117 60.8452 33.1452 60.531 33.4121 60.1495L35.2356 61.2941ZM31.0104 56.0426C29.8541 56.0426 29.1202 56.5813 28.7867 57.6809H33.1008C32.9896 57.1872 32.7227 56.7608 32.3447 56.469C31.9666 56.1773 31.4774 56.0426 31.0104 56.0426Z"
      fill="black"
    />
    <path
      d="M38.7936 63.2017H36.3697V54.2248H38.7714V55.1899L38.6379 55.6163C38.6379 55.6163 38.7714 55.4143 38.7936 55.4143C39.0827 54.9879 39.4607 54.6512 39.9055 54.4268C40.3502 54.2024 40.8617 54.0678 41.3509 54.0902V56.5364C40.4837 56.5364 39.861 56.7608 39.4163 57.2096C38.9937 57.6585 38.7714 58.2869 38.7714 59.0948L38.7936 63.2017Z"
      fill="black"
    />
    <path
      d="M46.2432 54.0453C46.8659 54.0228 47.5108 54.1351 48.089 54.3819C48.6671 54.6063 49.2008 54.9654 49.6678 55.4142C50.1126 55.8406 50.4461 56.3793 50.6908 56.9403C50.9354 57.5014 51.0466 58.1297 51.0466 58.7357C51.0466 59.3416 50.9354 59.97 50.6908 60.531C50.4461 61.0921 50.1126 61.6307 49.6678 62.0571C48.7338 62.9323 47.5108 63.4036 46.2655 63.4036C45.0202 63.4036 43.7748 62.9323 42.8409 62.0571C42.4183 61.6307 42.0625 61.0921 41.8402 60.531C41.5956 59.97 41.4844 59.3416 41.4844 58.7357C41.4844 58.1297 41.5956 57.5014 41.8402 56.9403C42.0848 56.3793 42.4183 55.8406 42.8631 55.4142C43.3079 54.9654 43.8416 54.6063 44.4197 54.3819C44.9979 54.1351 45.6206 54.0228 46.2432 54.0453ZM47.9555 56.9627C47.5108 56.5139 46.8881 56.2446 46.2432 56.2446C45.5983 56.2446 44.9979 56.4915 44.5309 56.9627C44.3085 57.1872 44.1306 57.4789 44.0195 57.7707C43.9083 58.0848 43.8416 58.399 43.8638 58.7357C43.8416 59.0723 43.9083 59.3865 44.0195 59.7007C44.1306 60.0149 44.3085 60.2842 44.5309 60.531C44.7533 60.7554 44.9979 60.935 45.287 61.0696C45.5761 61.1818 45.8874 61.2492 46.1988 61.2492C46.5323 61.2492 46.8436 61.2043 47.1327 61.0696C47.4218 60.935 47.7109 60.7554 47.9333 60.531C48.378 60.0597 48.6449 59.4089 48.6449 58.7581C48.6449 58.0848 48.4003 57.4565 47.9555 56.9627Z"
      fill="black"
    />
    <path
      d="M56.606 63.3587C56.0278 63.3812 55.4496 63.2689 54.8937 63.0445C54.36 62.8201 53.8707 62.4835 53.4482 62.0795L53.2926 61.8551L53.4482 62.2815V66.2537H51.9138V54.337L53.4482 54.1799V55.3469L53.3148 55.7284L53.4482 55.5265C54.2265 54.6512 55.2717 54.2024 56.5837 54.2024C57.1619 54.2024 57.7623 54.3146 58.296 54.539C58.8297 54.7634 59.319 55.1001 59.7415 55.5265C60.5865 56.3792 61.0535 57.5462 61.0535 58.7581C61.0535 59.97 60.5865 61.137 59.7415 61.9898C59.3412 62.4161 58.852 62.7528 58.296 62.9772C57.7846 63.2465 57.2064 63.3587 56.606 63.3587ZM56.517 55.6611C55.6942 55.6611 54.8937 55.9977 54.3155 56.5812C53.7373 57.1647 53.4038 57.9726 53.4038 58.803C53.4038 59.6333 53.7373 60.4413 54.3155 61.0247C54.8937 61.6082 55.6942 61.9449 56.517 61.9449C56.9173 61.9449 57.3176 61.8775 57.6956 61.7204C58.0737 61.5634 58.4072 61.3389 58.6963 61.0247C59.2523 60.4188 59.5636 59.6333 59.5636 58.803C59.5636 57.9726 59.2523 57.1872 58.6963 56.5812C58.4072 56.2895 58.0737 56.0426 57.6956 55.8855C57.3398 55.7284 56.9395 55.6611 56.517 55.6611Z"
      fill="black"
    />
    <path
      d="M69.4816 63.2016V62.0122C68.681 62.9099 67.6359 63.3363 66.3238 63.3363C65.7457 63.3363 65.1452 63.224 64.6115 62.9996C64.0778 62.7752 63.5886 62.4386 63.1661 62.0122C62.3211 61.1594 61.8541 59.9924 61.8541 58.7805C61.8541 57.5687 62.3211 56.4017 63.1661 55.5489C63.5664 55.1225 64.0556 54.7859 64.6115 54.5614C65.1452 54.337 65.7457 54.2248 66.3238 54.2248C66.902 54.2024 67.5024 54.3146 68.0361 54.539C68.5698 54.7634 69.0813 55.1 69.4816 55.5264V54.3595H70.9937V63.224L69.4816 63.2016ZM66.4128 55.6611C66.0125 55.6611 65.6122 55.7284 65.2342 55.8855C64.8561 56.0426 64.5226 56.267 64.2335 56.5812C63.6776 57.1871 63.3662 57.9726 63.3662 58.803C63.3662 59.6333 63.6776 60.4188 64.2335 61.0247C64.5226 61.3165 64.8561 61.5633 65.2342 61.7204C65.6122 61.8775 66.0125 61.9449 66.4128 61.9449C67.2356 61.9449 68.0361 61.6082 68.6143 61.0247C69.1925 60.4412 69.5261 59.6333 69.5261 58.803C69.5261 57.9726 69.1925 57.1647 68.6143 56.5812C68.0361 55.9977 67.2356 55.6611 66.4128 55.6611Z"
      fill="black"
    />
    <path
      d="M80 54.337L76.909 62.9548C76.4864 64.1218 75.975 64.9521 75.3746 65.4683C74.7519 65.9844 73.9514 66.2313 72.9729 66.2313H72.5726V64.8399H72.9729C74.0403 64.8399 74.8409 64.234 75.3523 63.0445L71.8388 54.337H73.5288L76.1084 61.0472L78.4434 54.337H80Z"
      fill="black"
    />
    <path
      d="M41.64 40.3781C42.3961 39.9517 55.1605 32.7029 55.4941 32.5234C55.6053 32.4336 55.7387 32.3663 55.8721 32.3439C56.0278 32.2765 56.139 32.3214 56.2502 32.3663C56.3391 32.4561 56.4058 32.5683 56.3391 32.7254C56.2724 32.8376 56.1835 32.9274 56.0945 32.9947C55.9388 33.1069 42.5295 41.5451 41.9514 41.9266C41.5733 42.1735 41.2175 42.4428 41.1953 42.9365C41.173 43.1834 41.2842 43.4078 41.4399 43.5649C41.5956 43.722 41.8624 43.7668 42.0625 43.7668C42.2627 43.7668 42.5295 43.7444 42.7519 43.7444C43.4413 43.7444 44.1084 43.9913 44.6644 44.4401C45.287 44.9787 45.8207 45.652 46.2432 46.3701C46.4878 46.7965 46.9326 47.5371 47.4885 47.5371H47.5108L47.6887 47.5595C48.1779 47.582 48.5782 47.1331 48.8895 46.7516C50.1793 45.0685 60.9868 30.5036 61.4316 29.7855C61.5872 29.5386 61.5872 29.0449 61.098 29C60.7644 28.9327 40.3947 35.0369 39.5052 35.3062L38.4378 35.6653C38.3489 35.6877 38.2599 35.7326 38.171 35.7775L37.9041 35.8673C37.7262 35.9346 37.5261 35.9795 37.3704 36.0468C36.9479 36.2039 36.5698 36.3385 36.1696 36.5181C36.0584 36.5854 35.9694 36.6752 35.9027 36.7201C35.8138 36.8323 35.7915 36.9669 35.7915 37.1016C35.7915 37.3709 35.9472 37.6177 36.2363 37.7299C36.6143 37.887 37.0146 38.0666 37.4371 38.1788C38.549 38.6052 39.5275 39.256 40.2391 40.1986C40.3947 40.3781 40.4837 40.5576 40.7505 40.5801C40.9284 40.6025 41.1286 40.5801 41.3287 40.5128L41.64 40.3781Z"
      fill="#48C2C5"
    />
  </svg>
);
export const AeropayLogo = ({
  height = 48,
  width = 'auto',
  ...props
}: LogoProps) => {
  return (
    <Icon
      height={height}
      width={width}
      {...props}
      icon={Aeropay}
      color={undefined}
      rotate={undefined}
      size={undefined}
    />
  );
};
