import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjsDuration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import type {
  DayJs,
  DayJsDurationUnitType,
  DayJsOptionType,
  Time,
  TimeUnit,
  Timezone,
} from '@jane/shared/types';

import { parseApiTimezones } from '../timezones/parseApiTimezones';

dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(dayjsDuration);
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(relativeTime);
dayjs.extend(timezone);
dayjs.extend(utc);

export function duration(time: number, unit: DayJsDurationUnitType) {
  return dayjs.duration(time, unit);
}

export const timeFromNow = (
  time: Time,
  inputFormat?: DayJsOptionType, // undefined uses ISO8601, without fraction seconds e.g. '2020-04-02T08:02:17-05:00'
  suffix = true, // adds 'ago'
  timezone?: Timezone
): string => {
  if (timezone) {
    return dayjs(time, inputFormat)
      .tz(parseApiTimezones(timezone))
      .fromNow(!suffix);
  }

  return dayjs(time, inputFormat).fromNow(!suffix);
};

export const timeIsBetween = (
  time: DayJs | Time,
  startTime: Time,
  endTime: Time,
  unit: TimeUnit
): boolean =>
  time instanceof dayjs
    ? (time as DayJs).isBetween(startTime, endTime, unit)
    : dayjs(time).isBetween(startTime, endTime, unit);

export const timeIsSameOrBefore = (
  time: DayJs,
  timeToCheck: DayJs,
  unit?: TimeUnit
): boolean => time.isSameOrBefore(timeToCheck, unit);

export const timeIsSameOrAfter = (
  time: DayJs,
  timeToCheck: DayJs,
  unit?: TimeUnit
): boolean => time.isSameOrAfter(timeToCheck, unit);
