import { AddressAutocomplete } from '@jane/shared-ecomm/components';
import type { CountryOptions } from '@jane/shared-ecomm/components';
import { useGetStore } from '@jane/shared/data-access';
import { Flex, TextField, useMobileMediaQuery } from '@jane/shared/reefer';

import { useCustomerDispatch } from '../../../../customer/dispatch';
import { updateDeliveryAddress } from '../../../../customer/redux/cart';
import { useCustomerSelector } from '../../../../customer/selectors';
import { DeliveryAddressWrapper } from './cartDrawerAddress.styles';

export const ADDRESS_FIELD_NAME = 'deliveryAddress';

interface CartDrawerAddressProps {
  addressErrorMessage: string;
  bloomUserExperience?: boolean;
  handleSetAddress: (address: string) => void;
  headless?: boolean;
  headlessWide?: boolean;
  initialDeliveryAddress: string;
  resetAddressValidation: () => void;
}

export const CartDrawerAddress = ({
  bloomUserExperience = false,
  initialDeliveryAddress,
  resetAddressValidation,
  addressErrorMessage,
  handleSetAddress,
  headless,
  headlessWide,
}: CartDrawerAddressProps) => {
  const isMobile =
    useMobileMediaQuery({}) && (!headless || !!bloomUserExperience);
  const dispatch = useCustomerDispatch();
  const { cart, deliveryAddress } = useCustomerSelector(
    ({ cart: { cart, deliveryAddress } }) => ({
      cart,
      deliveryAddress,
    })
  );
  const { data: store } = useGetStore(cart?.store.id);

  const countries = store?.country_code ? [store.country_code] : undefined;

  const handleSelection = (value: string) => {
    handleSetAddress(value);
  };

  const handleApplyStreet2 = (street2: string) => {
    if (deliveryAddress?.street2 !== street2) {
      dispatch(
        updateDeliveryAddress({
          deliveryAddress: { ...deliveryAddress, street2 },
        })
      );
    }
  };

  const handleClearErrors = () => {
    if (addressErrorMessage) {
      resetAddressValidation();
    }
  };

  return (
    <DeliveryAddressWrapper headless={headless} headlessWide={headlessWide}>
      <Flex
        mt={!isMobile ? 8 : bloomUserExperience ? -16 : 0}
        mb={!isMobile ? 12 : bloomUserExperience ? 8 : 0}
        width="100%"
        flexDirection={isMobile ? 'column' : 'row'}
        gap={isMobile && bloomUserExperience ? 16 : undefined}
      >
        <Flex width="100%">
          <AddressAutocomplete
            clearIcon
            disableMobileInputStyling={headless}
            countries={countries as CountryOptions[]}
            defaultValue={initialDeliveryAddress}
            errorMessage={addressErrorMessage}
            label="Delivery Address"
            name="delivery-address"
            onChange={handleClearErrors}
            onSelection={handleSelection}
          />
        </Flex>
        <Flex
          flexDirection="column"
          ml={isMobile ? 0 : 24}
          {...(isMobile && { width: '100%' })}
        >
          <TextField
            disableMobileInputStyling={headless}
            defaultValue={deliveryAddress?.street2 || ''}
            name="additional_address"
            onBlur={(value) => handleApplyStreet2(value)}
            label="Apt, unit, etc."
          />
        </Flex>
      </Flex>
    </DeliveryAddressWrapper>
  );
};
