import * as t from 'io-ts';

import { tPhoto } from './photo';

export const tBrandSpecialUserSegmentIds = t.union([
  t.array(t.number),
  t.number,
  t.null,
]);

export type BrandSpecialUserSegmentIds = t.TypeOf<
  typeof tBrandSpecialUserSegmentIds
>;

const tBrandSpecialRules = t.type({
  excludes: t.array(
    t.partial({
      kinds: t.array(t.string),
      lineages: t.array(t.string),
      product_ids: t.union([t.array(t.number), t.null]),
      states: t.union([t.array(t.string), t.null]),
      store_ids: t.union([t.array(t.string), t.null]),
      user_segment_ids: tBrandSpecialUserSegmentIds,
      weights: t.array(t.string),
    })
  ),
  includes: t.array(
    t.partial({
      kinds: t.array(t.string),
      lineages: t.array(t.string),
      product_ids: t.union([t.array(t.number), t.null]),
      states: t.union([t.array(t.string), t.null]),
      store_ids: t.union([t.array(t.string), t.null]),
      user_segment_ids: tBrandSpecialUserSegmentIds,
      weights: t.array(t.string),
    })
  ),
});

export const tBrandSpecial = t.type({
  active: t.boolean,
  created_at: t.string,
  description: t.string,
  discount_dollar_amount: t.number,
  discount_label: t.string,
  discount_percent: t.number,
  discount_target_price: t.number,
  discount_type: t.string,
  enabled: t.boolean,
  end_time: t.union([t.string, t.null]),
  excluded_app_modes: t.union([t.array(t.string), t.null]),
  id: t.number,
  photo: t.union([tPhoto, t.null]),
  product_brand_id: t.number,
  rules: tBrandSpecialRules,
  special_type: t.string,
  start_time: t.union([t.string, t.null]),
  terms: t.union([t.string, t.null]),
  title: t.string,
  updated_at: t.string,
});

export type BrandSpecial = t.TypeOf<typeof tBrandSpecial>;
